import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { SubmitHandler, useForm } from "react-hook-form";
import { IoMdArrowBack } from "react-icons/io";
import { personalInformationSchemeType } from "../../utilities/SchemaTypes";
import { personalInformationSchema } from "../../utilities/ValidationSchemas";
import ButtonComponent from "../CommonComponents/ButtonComponent";
import PhoneNumberInput from "../FormComponents/PhoneNumberInput";
import ErrorMsgDisplayComponent from "../FormComponents/ErrorMsgDisplayComponent";
import { savePersonalInfo } from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";

export const PersonalInformation = () => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<personalInformationSchemeType>({
    resolver: zodResolver(personalInformationSchema),
  });

  const navigate = useNavigate();

  /**
   * Method - reset aggregator api keys to default values
   */
  const handleSubmitPersonalInfo: SubmitHandler<
    personalInformationSchemeType
  > = async (data: personalInformationSchemeType) => {
    try {
      const response = await savePersonalInfo({ ...data, userId: "testid" });

      // const response = await sendOTPFunc(data);
      // console.log("test",response)
    } catch (error) {
      const err = error as Error;
      console.log("ERROR", err);
      setError("root", { message: err.message });
    }
  };

  /**
   * Method - navigate back to home page
   */
  const handleNavigateBack = () => {
    navigate(ROUTES.HOMEPAGE_ROUTE);
  };

  return (
    <Container className="dashboard-section-container aggregators-section-container">
      <Row>
        <Col>
          <IoMdArrowBack
            className="aggregation-form-back-button"
            size={20}
            onClick={handleNavigateBack}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={12} md={9}>
          <h2 className="edit-profile-title">Personal Information</h2>
          <p className="edit-profile-description">
            Update your personal information. Ensure your name and mobile number
            are accurate.
          </p>
        </Col>
      </Row>

      <Form onSubmit={handleSubmit(handleSubmitPersonalInfo)}>
        <Row className="aggregator-form-section-container">
          <Col sm={12} md={9}>
            <h2 className="field-container-title">Your Account Information</h2>
            <p className="field-container-description">
              Update your personal information. Ensure your name and mobile
              number are accurate.
            </p>
          </Col>

          <Col className="form-field-container col-12">
            <div className="form-group-container">
              <Form.Group controlId="signupForm.firstName">
                <Form.Label className="edit-api-label">First Name </Form.Label>
                <Form.Control {...register("firstName")} type="text" />
                {errors.firstName && errors.firstName.message && (
                  <ErrorMsgDisplayComponent
                    message={errors.firstName.message}
                  />
                )}
              </Form.Group>
            </div>
            <div className="form-group-container">
              <Form.Group>
                <Form.Label className="edit-api-label">Last Name </Form.Label>
                <Form.Control {...register("lastName")} type="text" />
                {errors.lastName && errors.lastName.message && (
                  <ErrorMsgDisplayComponent message={errors.lastName.message} />
                )}
              </Form.Group>
            </div>
          </Col>

          <Col sm={12} className="form-field-container">
            <div className="form-group-container">
              <Form.Group>
                <Form.Label className="edit-api-label">Your Email </Form.Label>
                <Form.Control {...register("email")} type="text" />
                {errors.email && errors.email.message && (
                  <ErrorMsgDisplayComponent message={errors.email.message} />
                )}
              </Form.Group>
            </div>
            <div className="form-group-container">
              <div className="d-flex align-items-center gap-1">
                <Form.Group>
                  <Form.Label className="edit-api-label">
                    Phone Number
                  </Form.Label>
                  <PhoneNumberInput
                    name="mobileNumber"
                    register={register}
                    errors={errors.mobileNumber}
                    setValue={setValue}
                    placeholder=""
                  />
                </Form.Group>
              </div>
            </div>
          </Col>

          <Col sm={6}>
            <div className="form-group-container multi-factor-authentication-text">
              Multi-Factor Authentication
            </div>

            <div className="form-group-container d-flex gap-2 mt-3 deactivate-activate-text">
              Deactivate{" "}
              <Form.Switch
                type="switch"
                // className="aggregator-enable-switch"
                // checked={false}
                {...register("multiFactorAuthenticationActivated")}
              />{" "}
              Activate
            </div>

            <div className="mt-2 multi-factor-authentication-description-text">
              Multi-Factor Authentication (MFA) adds an extra layer of security
              to your account. This helps protect your account even if your
              password is compromised.
            </div>
          </Col>

          <Col sm={12} className="d-flex justify-content-end mt-2">
            <ButtonComponent
              type="submit"
              text="Edit User Details"
              variant="primary"
              isDisabled={isSubmitting}
            />
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
