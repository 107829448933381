import React, { useEffect, useState } from 'react'
import { IService } from '../../utilities/ModelTypes'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import DOMPurify from 'dompurify';

interface ServicePageComponentProps {
    service: IService;
    showModal: (value: boolean) => void
}

export default function ServicePageComponent({ service, showModal }: ServicePageComponentProps) {
    return (
        <Card className='service-page-card'>
            <Card.Body>
                <div className='icon-row'>
                    <div className='service-icon-circle'><img src={process.env.REACT_APP_DOMAIN_NAME + '/images/Services/Service-' + service.displayName + '.svg'} alt={service.displayName} className='service-icon' /></div>
                    <Card.Title className='service-title'>{service.displayName}</Card.Title>
                </div>
                <Card.Subtitle className='sevice-subtitle'> {service.shortDescription}</Card.Subtitle>
                <Card.Subtitle className='sevice-subtitle'> Benefits:</Card.Subtitle>
                {service.benefits && (
                    <ul>
                        {service.benefits.map((benefit, index) => (
                            <li key={index} className='sevice-subtitle'>{benefit}</li>
                        ))}
                    </ul>
                )}
                <Button className="service-page-btn" onClick={() => showModal(true)}>
                    {service.buttonText}{" "}
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <g clipPath="url(#clip0_121_1766)">
                                <path d="M6.92545 2.43359L6.12051 3.23854L9.30602 6.42976H2.3584V7.57152H9.30602L6.12051 10.7628L6.92545 11.5677L11.4925 7.00064L6.92545 2.43359Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_121_1766">
                                    <rect width="13.7011" height="13.7011" fill="white" transform="translate(0.074707 0.149414)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </Button>
            </Card.Body>
        </Card >
    )
}
