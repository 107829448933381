import { Card, Col, Placeholder } from "react-bootstrap";

export interface SalesDataCardInterface {
  image: string;
  title: string;
  subDescription?: string | null;
  subDescriptionValue?: string | null;
  salesDescription: string;
  salesValue: number;
  classNames?: string;
}

export default function SalesDataCard({
  image,
  title,
  subDescription,
  subDescriptionValue,
  salesDescription,
  salesValue,
  classNames = "",
}: SalesDataCardInterface) {
  return (
    <Col className={`sales-data-card mt-3 ${classNames}`} sm={12}>
      {/* card icon  */}
      <img src={image} alt="" className="sales-data-card-icon" />

      {/* sales title  */}
      <h3 className="sales-data-card-title">{title}</h3>

      {/* sales sub description  */}
      {subDescription && (
        <div className="sales-card-des-container">
          <div className="sales-card-sales-label">{subDescription}:</div>
          <div className="sales-card-sub-description-value">
            {subDescriptionValue}
          </div>
        </div>
      )}

      {/* sales description  */}
      <div className="sales-card-des-container">
        <div className="sales-card-sales-label">{salesDescription}:</div>
        <div className="sales-card-sales-value">{salesValue}</div>
      </div>
    </Col>
  );
}
