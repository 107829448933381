import React, { ChangeEvent, useEffect, useState } from "react";
import BackButton from "../../components/CommonComponents/BackButton";
import ReactCountryFlag from "react-country-flag";
import { INetworkProvider, INetworkProviders, IProduct } from "../../utilities/ModelTypes";
import {
  getNetworkProvider,
  getNetworkProviders,
  getPackages,
} from "../../services/AggregatorService";
import { Container, Form } from "react-bootstrap";
import DividerText from "../../components/CommonComponents/DividerText";
import NetworkProviderComponent from "../../components/ServiceComponents/NetworkProviderComponent";
import { packageAmountType } from "../../utilities/SchemaTypes";
import { useForm } from "react-hook-form";
import { packageAmountSchema } from "../../utilities/ValidationSchemas";
import { zodResolver } from "@hookform/resolvers/zod";
import ErrorMsgDisplayComponent from "../../components/FormComponents/ErrorMsgDisplayComponent";
import ButtonComponent from "../../components/CommonComponents/ButtonComponent";
import Arrow from "../../assets/arrow_forward.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ServiceTypes } from "../../utilities/Enums";
import { formatPrice } from "../../utilities/Formatters";
import { NetworkProviderDetails } from "../../components/ServiceComponents/NetworkProviderDetails";
import { updatePackage } from "../../redux/packageOrder/packageOrderSlice";
import { countries } from "../../utilities/CountriesList";
import { ROUTES } from "../../constants/Routes";
import { useNavigate } from "react-router-dom";
import ServiceIdentificationModal from "../../components/ServiceComponents/ServiceIdentificationModal";
import ProductComponent from "../../components/ServiceComponents/ProductComponent";
import { PaginationComponent } from "../../components/Common/PaginationComponent";

export default function ServicePage() {
  const {
    register,
    handleSubmit,
    setError: setFormError,
    clearErrors,
    formState: { errors },
  } = useForm<packageAmountType>({
    resolver: zodResolver(packageAmountSchema)
  });

  /**
   * States and use effect to get the providers
   */
  const [networkProviders, setNetworkProviders] = useState<INetworkProvider[] | null>(null);
  const [error, setError] = useState<string | null>("");
  const [loading, setLoading] = useState<boolean>(false);

  const packageOrder = useSelector((state: RootState) => state.packageOrder);

  const getProviders = async () => {
    try {
      setLoading(true);

      if (packageOrder.country === null || packageOrder.service === null) return;

      const response = await getNetworkProviders(packageOrder.country, packageOrder.service, 1, 100);

      if (!response) return;

      setNetworkProviders(response);
    } catch {
      setError("Unable to obtain enterprise data");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (networkProviders == null) getProviders();
  }, [networkProviders, packageOrder]);


  /**
   * State, method and use effect to get the products based on the selected network provider
   */
  const dispatch = useDispatch();
  const [networkProvider, setNetworkProvider] = useState<INetworkProvider | null>(null);
  const [fixedProducts, setFixedProducts] = useState<IProduct[] | null>(null);
  const [rangedProducts, setRangedProducts] = useState<IProduct[] | null>(null);
  const [productCurrentPage, setProductCurrentPage] = useState<number>(1);
  const [productsCount, setProductsCount] = useState<number>(0);

  const getFixedProducts = async (data: INetworkProvider, pageNumber?: number) => {
    try {
      if (packageOrder.country === null) return;

      const currentPage = pageNumber ? pageNumber : productCurrentPage;

      //get Fixed products
      const fixedProducts = await getPackages(packageOrder.country, data.id, false, currentPage, 12);

      if (!fixedProducts) return;

      setFixedProducts(fixedProducts.data);
    } catch (error) {
      setError("Unable to obtain enterprise data");
    } finally {
      setLoading(false);
    }
  }


  const getProducts = async (data: INetworkProvider, pageNumber?: number) => {
    try {
      //get the network provider details
      const providerDetails = await getNetworkProvider(data.id);

      if (!providerDetails) return;

      setNetworkProvider(providerDetails);

      dispatch(updatePackage({ networkProvider: providerDetails }));

      if (packageOrder.country === null) return;

      //get range products
      const rangeProducts = await getPackages(packageOrder.country, data.id, true, 1, 50);

      if (!rangeProducts) return;

      setRangedProducts(rangeProducts.data);

      const productsCount = rangeProducts.productCounts.totalProducts - rangeProducts.productCounts.totalProductsNumberWithRange;

      setProductsCount(productsCount);

      getFixedProducts(data, pageNumber)

    } catch {
      setError("Unable to obtain enterprise data");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if ((!fixedProducts || !rangedProducts) && packageOrder.networkProvider) {
      getProducts(packageOrder.networkProvider);
    }
  }, [packageOrder.networkProvider])


  /**
   * Pagination component changes
   */
  //handling the pagination click
  const handlePageChange = (page: number) => {
    setProductCurrentPage(page);
    if (packageOrder.networkProvider) getFixedProducts(packageOrder.networkProvider, page);
  }

  /**
   * Identification model submit method
   */
  const handleModalSubmit = async () => {
    //clearing all the prevailing fields
    setNetworkProviders(null);
    setRangedProducts(null);
    setFixedProducts(null);
    setNetworkProvider(null);

    if (packageOrder.networkProvider) getProducts(packageOrder.networkProvider);
  }

  /**
   * Handling the custom value entering and product selection
   */
  interface EnteredPackageProps {
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    product: IProduct;
  }

  const enteredPackage = async ({ e, product }: EnteredPackageProps) => {
    const currentValue = e.currentTarget.value;

    if (currentValue) {
      if (Number(currentValue) < Number(formatPrice(product.rangeMin))) {
        setFormError("amount", { message: `Amount must be at least ${formatPrice(product.rangeMin)} ${product.packageCurrency}` });
        dispatch(updatePackage({ product: null, value: 0 }))
        return;
      }
      if (Number(currentValue) > Number(formatPrice(product.rangeMax))) {
        setFormError("amount", { message: `Amount must not exceed ${formatPrice(product.rangeMax)} ${product.packageCurrency}` });
        dispatch(updatePackage({ product: null, value: 0 }))
        return;
      }
      clearErrors("amount")
      dispatch(updatePackage({ product: product, value: Number(e.currentTarget.value) }))
    } else {
      dispatch(updatePackage({ product: null, value: 0 }))
    }
  }

  const selectedPackage = async (data: IProduct) => {
    dispatch(updatePackage({ product: data, value: data.packageValue }))
  }

  /**
   * Order confirmation
   */
  const navigate = useNavigate();

  const confirmOrder = () => {
    navigate(ROUTES.ORDER_CONFIRMATION_ROUTE)
  };

  //service component mobile and country popup state
  const [showIdentificationModal, setShowIdentificationModal] = useState<boolean>(false);

  return (
    <div className="page-service">
      <Container>
        <BackButton />
        <div className="section-title">
          <h2 className="title-2">Choose Your Network Provider & Package </h2>
          <p>
            Explore all available network providers and packages in your
            country. Select the one that best suits your needs and enjoy
            seamless connectivity and services.
          </p>
        </div>
        <div className="c-number">
          <div>
            {packageOrder.country !== null && <ReactCountryFlag style={{ width: "2em" }} countryCode={packageOrder.country} svg />}
            {packageOrder.mobileNumber && <span className="number">{packageOrder.mobileNumber}</span>}
            {!packageOrder.mobileNumber && packageOrder.country && <span className="number">{countries[packageOrder.country?.toUpperCase()]}</span>}
          </div>
          <div className="ico-edit" onClick={() => setShowIdentificationModal(true)}>
            <img src={process.env.REACT_APP_DOMAIN_NAME + '/images/Icons/PencilIcon.svg'} />
          </div>
          {packageOrder.service && showIdentificationModal && <ServiceIdentificationModal show={showIdentificationModal} handleClose={() => setShowIdentificationModal(false)} service={packageOrder.service} submitModal={() => handleModalSubmit()} />}

        </div>

        {/* --------------------------------------- Available network providers --------------------------------------- */}
        <div className="card section-card-container">
          <div className="txt-network">Available Network Provider</div>
          <div className="service-row">
            {networkProviders &&
              networkProviders.map(
                (provider: INetworkProvider, index: number) => (
                  <div className="card-services" key={index}>
                    <NetworkProviderComponent
                      networkProvider={provider}
                      getProducts={() => getProducts(provider)}
                    />
                  </div>
                )
              )}
          </div>
        </div>

        {/* --------------------------------------- Available packages --------------------------------------- */}
        {(fixedProducts || rangedProducts) &&
          <div className="card section-card-container">
            <div className="wrap-filter">
              <div className="txt-network"> Available Packages</div>
              <div className="filter">
                <span>Filter</span>
                <img src={process.env.REACT_APP_DOMAIN_NAME + '/images/Icons/FilterIcon.svg'} />
              </div>{" "}
            </div>
            {rangedProducts &&
              <>
                {rangedProducts.map((networkPackage: IProduct, index: number) => (
                  <>
                    {/* --------------------------------------- Custom amount --------------------------------------- */}
                    {(networkPackage.type === ServiceTypes.TYPE_2 || networkPackage.type === ServiceTypes.TYPE_4 || networkPackage.type === ServiceTypes.TYPE_5) &&
                      <>
                        <div className="wrap-cust-amount" key={index}>
                          <div className="txt-cust-amount">Custom Amount</div>
                          <Form onSubmit={handleSubmit(confirmOrder)}>
                            <Form.Group className="form-group" controlId="packageForm.amount">
                              <Form.Label className="frm-lbl">
                                Enter an amount between {formatPrice(networkPackage.rangeMin)} {networkPackage.packageCurrency} and {formatPrice(networkPackage.rangeMax)} {networkPackage.packageCurrency}{" "}
                              </Form.Label>
                              <Form.Control {...register("amount", { valueAsNumber: true })} type="number" onChange={(e) => enteredPackage({ e, product: networkPackage })} />
                              {errors.amount && errors.amount.message && (
                                <ErrorMsgDisplayComponent message={errors.amount.message} />
                              )}
                            </Form.Group>
                          </Form>
                        </div>
                        <DividerText text={"OR"} />
                      </>}
                  </>))
                }
              </>
            }

            {/* --------------------------------------- Package cards --------------------------------------- */}
            {fixedProducts &&
              <div className="service-row">
                {fixedProducts.map((product: IProduct, index: number) => (
                  <>
                    {(product.type === ServiceTypes.TYPE_1 || product.type === ServiceTypes.TYPE_3) &&
                      <div className="card-services" key={index}>
                        <ProductComponent product={product} selectedPackage={() => selectedPackage(product)} />
                      </div>
                    }
                  </>
                  //  
                ))}
              </div>
            }
            {productsCount && <PaginationComponent totalItems={productsCount} itemsPerPage={12} onPageChange={handlePageChange} />}
          </div>
        }

        {/* --------------------------------------- Network provider details --------------------------------------- */}
        {networkProvider &&
          <div className="card section-card-container">
            <NetworkProviderDetails networkProvider={networkProvider} />
          </div>
        }
      </Container>


      {/* --------------------------------------- Sticky Amount bar --------------------------------------- */}
      {(packageOrder.value && packageOrder.value != 0) &&
        <Container fluid className="p-0 selected-network-operator">
          <Container>
            <div className="selected-network-operator-top">
              <div className="selected-network-operator-labels">
                <div className="lbl">
                  Selected Network Operator : {packageOrder.networkProvider?.networkProviderName}{" "}
                  <span>
                    <img width={50} src={packageOrder.networkProvider?.logoURL} alt="network provider" />
                  </span>{" "}
                </div>
                <div className="lbl">Amount : {packageOrder.value} USD </div>
              </div>

              <div>
                <ButtonComponent
                  text="next"
                  onClick={confirmOrder}
                  variant="primary"
                  isDisabled={false}
                  src={Arrow}
                  customStyling="next"
                />
              </div>
            </div>
          </Container>
          <div className="disclaimer">
            <Container>
              <div className="text-disclaimer">
                {" "}
                Disclaimer: Refunds are not available for incorrect provider
                selection.{" "}
              </div>
            </Container>
          </div>
        </Container>
      }
    </div>
  );
}
