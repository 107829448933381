import React from "react";
import { Button, Modal } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";

interface ModalComponentInterface {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  className: string;
  disableCloseButton?: boolean;
}

export default function ModalComponent({
  open,
  onClose,
  title,
  children,
  className,
  disableCloseButton = false,
}: ModalComponentInterface) {
  return (
    <Modal
      show={open}
      onHide={onClose}
      centered
      className={className}
      size="lg"
      // backdrop={false}
    >
      <div className="modal-header">
        <IoIosClose
          className={`modal-close-button ${
            disableCloseButton ? "disabled" : ""
          }`}
          onClick={disableCloseButton ? undefined : onClose}
        />
      </div>

      <Modal.Body>
        <Modal.Title className="modal-title">{title}</Modal.Title>
        {children}
      </Modal.Body>
    </Modal>
  );
}
