import React, { useState } from "react";
import { IService } from "../../utilities/ModelTypes";
import { Button, Card } from "react-bootstrap";

interface ServiceComponentProps {
  service: IService;
  showModal:(value:boolean)=>void
}

export default function HomeServiceComponent({ service,showModal }: ServiceComponentProps) {
  return (
    <Card className="service-card gradient-card">
      <Card.Body>
        <div className="crd-left">
          <Card.Title className="service-title">
            {service.displayName}
          </Card.Title>
          <Card.Subtitle className="service-subtitle">
            {" "}
            {service.shortDescription}
          </Card.Subtitle>
          <Button className="service-btn" onClick={() => showModal(true)}>
            {service.buttonText}{" "}
            <span>
              <svg width="10" height="8" viewBox="0 0 10 8" fill="none">
                <path
                  d="M5.52983 0.375L4.44043 1.4644L6.97654 4L4.44043 6.53559L5.52983 7.62499L9.15431 4L5.52983 0.375Z"
                  fill="white"
                />
                <path
                  d="M1.93608 7.62499L5.56056 4L1.93608 0.375L0.84668 1.4644L3.38279 4L0.84668 6.53559L1.93608 7.62499Z"
                  fill="white"
                />
              </svg>
            </span>
          </Button>
        </div>
        <div className="crd-right">
          <img
            src={
              process.env.REACT_APP_DOMAIN_NAME +
              "/images/Services/Service-" +
              service.displayName +
              ".svg"
            }
            alt={service.displayName}
          />
        </div>
      </Card.Body>
    </Card>
  );
}
