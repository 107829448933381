import React from "react";
// import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <div className="btn-back pt-4" onClick={() => navigate(-1)}>
      {" "}
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="18" fill="#E4E4E7" />
        <g clipPath="url(#clip0_91_4883)">
          <path
            d="M24.6667 17.1663H14.525L19.1834 12.508L18 11.333L11.3334 17.9997L18 24.6663L19.175 23.4913L14.525 18.833H24.6667V17.1663Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_91_4883">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(8 8)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
