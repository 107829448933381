import React, { useEffect, useState } from 'react'
import { INetworkProvider, IProduct } from '../../utilities/ModelTypes'
import { Card } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface INetworkProvideProps {
    networkProvider: INetworkProvider;
    getProducts: (provider:INetworkProvider) => void
}
export default function NetworkProviderComponent({ networkProvider,getProducts }: INetworkProvideProps) {
    const packageOrder = useSelector((state: RootState) => state.packageOrder);
    const [selected, setSelected] = useState<string>("");

    useEffect(() => {
        if(packageOrder.networkProvider?.id == networkProvider.id){
            setSelected("selected")
        } else {
            setSelected("")
        }
    },[packageOrder]);

    return (
        <Card className={`available-n-image ${selected}`} onClick={() => getProducts(networkProvider)}>
            <Card.Body>
                <img src={networkProvider.logoURL} alt=''/>
                <p>{networkProvider.networkProviderName}</p>
            </Card.Body>
        </Card>
    )
}
