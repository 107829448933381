import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { IResourceCreator } from "../../../types/types";
import CurrencyProfitCard, {
  CurrencyProfitCardInterface,
} from "./CurrencyProfitCard";
import { EmptyState } from "./EmptyState";

export interface TotalIncomeInterface {
  incomeByCurrenciesResource: IResourceCreator;
  setIsIncomeByCurrencyEmpty: any;
}

export default function TotalIncomeByCurrency({
  incomeByCurrenciesResource,
  setIsIncomeByCurrencyEmpty,
}: TotalIncomeInterface) {
  const incomeByCurrency: any =
    incomeByCurrenciesResource?.read()?.data.incomeByCurrencies;

  const incomeDataExists = incomeByCurrency.length > 0;

  // if income by currency data is not exists then update parent component income by currency data empty state
  if (!incomeDataExists) {
    setIsIncomeByCurrencyEmpty(true);
  }

  return (
    <>
      <h3 className="total-income-title">Total Income by Currency</h3>

      {/* render if only total income data exists */}
      {incomeDataExists && (
        <>
          <div className="total-income-description">
            View the total income generated in various currencies over the
            selected period.
          </div>

          {/* profits by currency cards  */}
          <Container className="mt-3">
            <Row className="justify-content-center">
              {incomeByCurrency?.map((id: CurrencyProfitCardInterface) => (
                <CurrencyProfitCard
                  currencyType={id.currencyType}
                  profitAmount={id.profitAmount}
                  key={id.currencyType}
                />
              ))}
            </Row>
          </Container>
        </>
      )}
    </>
  );
}
