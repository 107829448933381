import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { deleteAdmin, getAllAdmins } from "../../../services/DashboardService";
import { DisplayNewAdminInterface } from "../../../types/types";
import { logError } from "../../../utilities/logError";
import ButtonComponent from "../../CommonComponents/ButtonComponent";
import AddAdminModal from "./AddAdminModal";
import { AddAdminUsers } from "./AddAdminUsers";
import { PaginationComponent } from "../../Common/PaginationComponent";

export default function ManageUsers() {
  const [admins, setAdmins] = useState<any>([]);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * useEffect - fetch admin data after component mounted and when currentPage got changed
   */
  useEffect(() => {
    fetchAdminData();
  }, [currentPage]);

  /**
   * Method - trigger admin data fetching api call
   */
  const fetchAdminData = async () => {
    try {
      const response: any = await getAllAdmins(currentPage);
      setTotalNumberOfRecords(response?.data?.count);
      setAdmins(response?.data?.results);
    } catch (error: any) {
      logError(error.message, "fetching admin users data");
    }
  };

  const numberOfPages = Array.from(
    { length: Math.ceil(totalNumberOfRecords / 5) },
    (_, index) => index + 1
  );

  

  /**
   * Method - show and close hide new admin modal
   */
  const handleShowAdminModal = () => {
    setShowAddNewModal((showAddNewModal: boolean) => !showAddNewModal);
  };

  /**
   * Method - delete admin user
   */
  const handleDeleteAdminUser = async (adminId: string) => {
    try {
      setLoading(true);
      const response = await deleteAdmin(adminId);
      console.log(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* add new admin modal  */}
      <AddAdminModal open={showAddNewModal} onClose={handleShowAdminModal} />

      {/*   new admin modal  */}
      {admins.length === 0 && (
        <AddAdminUsers handleShowAdminModal={handleShowAdminModal} />
      )}

      {admins?.length > 0 && (
        <Container className="dashboard-section-container admin-user-section-container">
          {/* header section  */}
          <Row>
            <Col sm={12} md={8} lg={8}>
              <div className="dashboard-section-title">View Admin Users</div>
              <div className="mt-2 dashboard-section-description">
                Here, you can view and manage all the admin users currently
                registered in the system. The table below provides an overview
                of each admin user, including their name, email address, and
                account status.
              </div>
            </Col>

            <Col
              sm={12}
              md={4}
              lg={4}
              className="d-flex align-items-center justify-content-end add-admin-user-btn-container"
            >
              <ButtonComponent
                text="Add New Admin User"
                onClick={handleShowAdminModal}
                variant="primary"
                customStyling="add-admin-user-btn align-horizontal-center"
              />
            </Col>
          </Row>

          <Row className="admin-table-head-tr">
            <Col sm={12} md={4} lg={4} className="admin-table-head">
              Name
            </Col>
            <Col
              sm={12}
              md={4}
              lg={4}
              className=" admin-table-head admin-table-head-email"
            >
              Email Address
            </Col>
            <Col></Col>
          </Row>

          {admins?.map((admin: DisplayNewAdminInterface, index: number) => {
            // calculating tr background color based in the index
            const trClassName = `admin-table-body-tr ${
              index % 2 === 1 ? "odd-tr-background" : "even-tr-background"
            }`;

            return (
              <Row className={trClassName}>
                <Col sm={6} md={4} lg={4} className="admin-table-td">
                  <div>
                    <div className="admin-table-td-title">Name</div>
                    <div> {admin?.name}</div>
                  </div>
                </Col>
                <Col
                  sm={6}
                  md={4}
                  lg={4}
                  className="admin-table-td admin-table-head-email "
                >
                  <div>
                    <div className="admin-table-td-title">Email</div>
                    <div> {admin?.email}</div>
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={4}
                  className="admin-table-td d-flex flex-row justify-content-end align-items-center "
                >
                  <ButtonComponent
                    text="Remove Admin"
                    onClick={() => handleDeleteAdminUser(admin.id)}
                    variant="secondary"
                    customStyling="remove-admin-button align-vertical-center align-horizontal-center"
                  />
                </Col>
              </Row>
            );
          })}

          {/* pagination component  */}

          <Row className="admin-table-body-tr">
            <Col
              sm={12}
              className="d-flex justify-content-center gap-2 admin-table-pagination-container"
            >
              {/* <PaginationComponent
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              /> */}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
