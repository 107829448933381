import React, { useState } from "react";
import { IPromotionPackage } from "../../utilities/ModelTypes";
import { Card } from "react-bootstrap";
import ButtonComponent from "../CommonComponents/ButtonComponent";

interface PackageComponentProps {
  promotionPackage: IPromotionPackage;
}

export default function PackageComponent({
  promotionPackage,
}: PackageComponentProps) {
  const [isSelected, setIsSelected] = useState(false);

  const toggleSelection = () => {
    setIsSelected(!isSelected);
  };

  const purchaseProduct = () => {
    console.log("Product purchased:", promotionPackage.packageId);
  };

  return (
    <Card
      className={`package-card ${isSelected ? "selected-package" : ""}`}
      onClick={toggleSelection}
    >
      <Card.Body className="card-details">
        <Card.Text className="package-id">
          Package ID: {promotionPackage.packageId}
        </Card.Text>
        <Card.Text className="package-description">
          {promotionPackage.packageDescription}
        </Card.Text>
        <Card.Text className="package-amount">
          Amount: {promotionPackage.packageAmount}
        </Card.Text>
        <div className="group-pill">
          <Card.Text className="txt-pill package-validity">Valid for 30 days</Card.Text>
          <Card.Text className="txt-pill package-network">4G/5G</Card.Text>
        </div>
        <ButtonComponent
          text={"Select"}
          onClick={purchaseProduct}
          variant={"primary"}
          isDisabled={false}
          customStyling="w-100 btn-card-promo"
        />
      </Card.Body>
    </Card>
  );
}
