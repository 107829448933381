import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INetworkProvider, IProduct, IService } from "../../utilities/ModelTypes";

interface PackageOrderState {
    country: string | null;
    mobileNumber?: string | null;
    service: string | null;
    networkProvider: INetworkProvider | null;
    product: IProduct | null;
    value: number | null;
};

const initialState: PackageOrderState = {
    country: null,
    mobileNumber: null,
    service: null,
    networkProvider: null,
    product: null,
    value: null
};

const packageOrderSlice = createSlice({
    name: "packageOrder",
    initialState,
    reducers: {
        addPackage: (state, action: PayloadAction<PackageOrderState>) => {
            Object.assign(state, action.payload);
        },
        resetPackage: (state) => {
            return initialState;
        },
        updatePackage(state, action: PayloadAction<{ networkProvider?: INetworkProvider | null; product?: IProduct | null; value?: number }>) {
            if (action.payload.networkProvider !== undefined) state.networkProvider = action.payload.networkProvider;
            if (action.payload.product !== undefined) state.product = action.payload.product;
            if (action.payload.value !== undefined) state.value = action.payload.value;
        },
    }
})
 
export const { addPackage, resetPackage, updatePackage } = packageOrderSlice.actions;


export default packageOrderSlice.reducer;