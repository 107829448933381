import { IPersonalInfo, ISecurityInfo } from "../types/types";

/**
 * Method - trigger api call to save personal info data
 * @returns dashboard data payload
 */
export const savePersonalInfo = async (personalInfo: IPersonalInfo) => {
  try {
  } catch (error) {}
};

/**
 * Method - trigger api call to save security info data
 * @returns dashboard data payload
 */
export const saveSecurityInfo = async (personalInfo: ISecurityInfo) => {
  try {
  } catch (error) {}
};
