import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ButtonComponent from "../../CommonComponents/ButtonComponent";

interface AddAdminUsersInterface {
  handleShowAdminModal: () => void;
}

export const AddAdminUsers = ({
  handleShowAdminModal,
}: AddAdminUsersInterface) => {
  return (
    <Container className="dashboard-section-container add-user-section-container">
      <Row>
        <Col className="add-admin-user-title">Add Admin Users</Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col className="add-user-description" sm={10}>
          Currently, there are no admin users registered in the system. Once you
          add an admin user, their details will appear here, allowing you to
          manage their account information easily. Click the 'Add Admin User'
          button to get started.
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-center justify-content-center">
          <ButtonComponent
            text="Add New Admin User"
            onClick={handleShowAdminModal}
            variant="primary"
            customStyling="add-admin-user-btn"
          />
        </Col>
      </Row>
    </Container>
  );
};
