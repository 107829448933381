import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ROUTES } from './constants/Routes';
//Layouts
import AdminLayout from "./layout/AdminLayout";
import CustomerLayout from "./layout/CustomerLayout";

//Service pages
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicePages/ServicesPage';
import ServicePage from './pages/ServicePages/ServicePage';
import OrderConfirmationPage from './pages/OrderConfirmationPage';
import PromotionsPage from "./pages/PromotionPages/PromotionsPage";
import PackagesPage from "./pages/PromotionPages/PackagesPage";

//Error page
import NotFoundPage from "./pages/NotFoundPage";

//Content pages
import AboutUsPage from "./pages/ContentPages/AboutUsPage";
import PrivacyPolicyPage from "./pages/ContentPages/PrivacyPolicyPage";
import TermsConditionPage from "./pages/ContentPages/TermsConditionPage";
import ContactUsPage from "./pages/ContentPages/ContactUsPage";

//Login and sign up flow
import LoginPage from "./pages/LoginPages/LoginPage";
import SignUpPage from "./pages/LoginPages/SignUpPage";
import EmailVerificationPage from "./pages/LoginPages/EmailVerificationPage";
import ResetPasswordPage from './pages/LoginPages/ResetPasswordPage';
import AccountVerifiedPage from './pages/LoginPages/AccountVerifiedPage';
import OTPVerificationPage from './pages/LoginPages/OTPVerificationPage';

//Admin pages
import ManagementPage from "./pages/AdminPages/ManagementPage";
import CustomerInfoLayout from "./layout/CustomerInfoLayout";
import InformationManagement from "./pages/UserPages/InformationManagement";
import NonVerifiedPage from "./pages/LoginPages/NonVerifiedPage";
import ResetPasswordRequestPage from "./pages/LoginPages/ResetPasswordSentPage";
import ResetPasswordConfirmation from "./pages/LoginPages/ResetPasswordConfirmation";

const router = createBrowserRouter([
    {
        element: <CustomerLayout />,
        children: [
            {
                path: `${ROUTES.HOMEPAGE_ROUTE}`,
                element: <HomePage />,
                errorElement: <NotFoundPage />,
            },
            {
                path: `${ROUTES.SERVICES_PAGE_ROUTE}`,
                element: <ServicesPage />,
            },
            {
                path: `${ROUTES.SERVICE_PAGE_ROUTE}`,
                element: <ServicePage />
            },
            {
                path: `${ROUTES.PROMOTIONS_PAGE}`,
                element: <PromotionsPage />,
            },
            {
                path: `${ROUTES.PRMOMOTION_PACKAGES_PAGE}`,
                element: <PackagesPage />,
            },
            {
                path: `${ROUTES.ORDER_CONFIRMATION_ROUTE}`,
                element: <OrderConfirmationPage />
            },

            //Content pages
            {
                path: `${ROUTES.ABOUTUS_PAGE_ROUTE}`,
                element: <AboutUsPage />,
            },
            {
                path: `${ROUTES.PRIVACY_POLICY_ROUTE}`,
                element: <PrivacyPolicyPage />,
            },
            {
                path: `${ROUTES.TERMS_CONDITION_ROUTE}`,
                element: <TermsConditionPage />,
            },
            {
                path: `${ROUTES.CONTACT_US_PAGE}`,
                element: <ContactUsPage />,
            },
        ],
    },
    {
        path: `${ROUTES.CUSTOMER_INFORMATION}`,
        element: <CustomerInfoLayout />,
        children: [
            {
                path: `${ROUTES.CUSTOMER_INFORMATION}/:informationType`,
                element: <InformationManagement />,
            }
        ]
    },
    //Login pages
    {
        path: `${ROUTES.ADMIN_LOGIN_PAGE_ROUTE}`,
        element: <LoginPage />
    },
    {
        path: `${ROUTES.LOGIN_PAGE_ROUTE}`,
        element: <LoginPage />
    },
    {
        path: `${ROUTES.SIGNUP_PAGE_ROUTE}`,
        element: <SignUpPage />
    },
    {
        path: `${ROUTES.EMAIL_VERIFICATION_PAGE_ROUTE}`,
        element: <EmailVerificationPage />
    },
    {
        path: `${ROUTES.RESET_PASSWORD_PAGE_ROUTE}`,
        element: <ResetPasswordPage />
    },
    {
        path: `${ROUTES.RESET_PASSWORD_REQUEST_PAGE_ROUTE}`,
        element: <ResetPasswordRequestPage />
    },
    {
        path: `${ROUTES.RESET_PASSWORD_CONFIRMATION_ROUTE}`,
        element: <ResetPasswordConfirmation />
    },
    {
        path: `${ROUTES.ACOUNT_VERIFIED_PAGE_ROUTE}`,
        element: <AccountVerifiedPage />
    },
    {
        path: `${ROUTES.OTP_VERIFICATION_PAGE}`,
        element: <OTPVerificationPage />
    },
    {
        path: `${ROUTES.NON_VERIFIED_ACCOUNT_PAGE}`,
        element: <NonVerifiedPage />
    },

    //Admin pages
    {
        path: `${ROUTES.ADMIN_PAGE_ROUTE}`,
        element: <AdminLayout />,
        children: [
            {
                path: `${ROUTES.ADMIN_PAGE_ROUTE}/:managementType`,
                element: <ManagementPage />,
            },
        ],
    },

    //Super Admin pages
    {
        path: `${ROUTES.SUPERADMIN_PAGE_ROUTE}`,
        element: <AdminLayout />,
        children: [
            {
                path: `${ROUTES.SUPERADMIN_PAGE_ROUTE}/:managementType`,
                element: <ManagementPage />,
            },
        ],
    },
    {
        path: "*",
        element: <NotFoundPage />,
    },
]);

export default function RouteComponent() {
    return <RouterProvider router={router} />;
}
