import React from 'react'
import { Container } from 'react-bootstrap'
import InfoDisplayComponent from '../../components/UserLogin/InfoDisplayComponent'

export default function ResetPasswordRequestPage() {
    return (
        <Container fluid className="signup-container">
            <InfoDisplayComponent title={'Password Change'} description={`We've sent an email to the address you provided to reset your password. Please check your inbox to complete the process.`} />
        </Container>
    )
}
