import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import GoogleButton from "../../components/CommonComponents/GoogleButton";
import DividerText from "../../components/CommonComponents/DividerText";
import { SubmitHandler, useForm } from "react-hook-form";
import { loginFormType } from "../../utilities/SchemaTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { loginSchema } from "../../utilities/ValidationSchemas";
import ErrorMsgDisplayComponent from "../../components/FormComponents/ErrorMsgDisplayComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ROUTES } from "../../constants/Routes";
import BackButton from "../../components/CommonComponents/BackButton";
import Logo from "../../assets/logo.png";
import ForgotPasswordModal from "../../components/UserLogin/ForgotPasswordModal";
import { ActionTypes, RoleTypes } from "../../utilities/Enums";
import ToastComponent from "../../components/CommonComponents/ToastComponent";
import { MultiFactorError, MultiFactorResolver, RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../firebase";
import OTPInput from "../../components/FormComponents/OTPInput";
import TimerDisplayComponent from "../../components/FormComponents/TimerDisplayComponent";
import { handle2FALogin, verify2FALogin } from "../../services/AuthService";
import { FirebaseError } from "firebase/app";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IUserRole } from "../../utilities/ModelTypes";

export default function LoginPage() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<loginFormType>({ resolver: zodResolver(loginSchema) });

  /**
  * Setting the invisible recaptcha verifier
  */
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();

  useEffect(() => {
    const recaptchaVerifier: RecaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-login-container", { "size": "invisible", });

    setRecaptchaVerifier(recaptchaVerifier);

    return () => {
      recaptchaVerifier.clear();
    }
  }, [auth]);

  const routeLocation = useLocation();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    try {
      // checking if the user is a admin or a super admin by url param
      const isAdmin = routeLocation.pathname.includes("/admin");

      (isAdmin) ? setIsAdmin(true) : setIsAdmin(false);

    } catch (error) {
      console.log("error", error)
    } finally {
      setIsLoading(false);
    }
  }, []);


  /**
   * Handle 2FA user
   */
  const navigate = useNavigate();
  const { userNamePWLogin, user } = useContext(AuthContext);
  const [isMFA, setIsMFA] = useState<boolean>(false);
  const [firebaseError, setFirebaseError] = useState<MultiFactorError | null>(null);
  const [resolver, setResolver] = useState<MultiFactorResolver | null>(null);
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [otpNumber, setOtpNumber] = useState<string | null>(null);

  //Function triggered when the form is submitted
  const onSubmit: SubmitHandler<loginFormType> = async (data: loginFormType) => {
    try {
      const response = await userNamePWLogin(data);

      if (!response) setError("root", { message: "Unable to login user" });

      if (response.status === "isVerify") {
        navigate(ROUTES.NON_VERIFIED_ACCOUNT_PAGE)
      } else {
        //Checking the navigation
        if (response.user && response.user.userRoles) {
          response.user.userRoles.map((role: IUserRole) => {
            if (role.roleName === RoleTypes.PLATFORMADMIN || role.roleName === RoleTypes.ADMIN) {
              navigate(`${ROUTES.ADMIN_PAGE_ROUTE}${ROUTES.DASHBOARD_ROUTE}`);
            }
          })
        } else {
          navigate(ROUTES.HOMEPAGE_ROUTE);
        }
      }

    } catch (error: any) {
      if (error instanceof FirebaseError) {
        if (error.code == "auth/multi-factor-auth-required" && recaptchaVerifier) {
          setIsMFA(true);
          setFirebaseError(error as MultiFactorError);

          const { resolver, verificationId } = await handle2FALogin(error as MultiFactorError, recaptchaVerifier);
          setResolver(resolver);
          setVerificationId(verificationId);
        } else {
          const err = error as Error;
          setError("root", { message: err.message });
        }
      } else {
        const err = error as Error;
        setError("root", { message: err.message });
      }
    }
  };

  const verifyOTPNumber = async () => {
    try {
      if (!resolver || !verificationId || !otpNumber) {
        setError("root", { message: "Required parameters are missing" })
        return;
      }

      const response = await verify2FALogin(resolver, verificationId, otpNumber)

      if (!response) {
        setError("root", { message: "Verification failed" })
        return;
      }

      localStorage.setItem('Token', response.user.accessToken);

      navigate(ROUTES.HOMEPAGE_ROUTE);
    } catch (error) {
      const err = error as Error;
      setError("root", { message: err.message })
    }
  }

  const resendVerification = async () => {
    try {
      if (!firebaseError || !recaptchaVerifier) {
        setError("root", { message: "Required parameters are missing" })
        return;
      }

      const { resolver, verificationId } = await handle2FALogin(firebaseError as MultiFactorError, recaptchaVerifier);
      setResolver(resolver);
      setVerificationId(verificationId);
    } catch (error) {
      const err = error as Error;
      setError("root", { message: err.message })
    }
  }

  /**
  * Others states
  */
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isTimerComplete, setIsTimerComplete] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleCloseModal = () => setShowModal(false);

  return (
    <Container fluid className="signup-container">
      <div className="page-signup">
        <div className="form-signup">
          {!isMFA ?
            <>
              <Row>
                <BackButton />
              </Row>
              <h2 className="title-2"> Log in</h2>
              {!isLoading && !isAdmin &&
                <>
                  <p>
                    Log into your GreenLeaf account using your G-suite account Single
                    Sign On
                  </p>
                  <GoogleButton actionType={ActionTypes.LOGIN} text={"Login with Google"} isDisabled={false} />
                  <DividerText text="OR" />
                </>
              }
              <p>Enter your Email and password to login to the system</p>
              <Form className="signup" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group
                  className="form-group"
                  controlId="LoginForm.emailAddress"
                >
                  <Form.Label>Your email </Form.Label>
                  <Form.Control
                    {...register("email")}
                    type="text"
                    placeholder="name@example.come"
                  />
                  {errors.email && errors.email.message && (
                    <ErrorMsgDisplayComponent message={errors.email.message} />
                  )}
                </Form.Group>
                <Form.Group className="form-group form-group-pword" controlId="LoginForm.password">
                  <Form.Label>Password </Form.Label>
                  <span className="wrap-pword-eye">
                    <Form.Control {...register("password")} type={showPassword ? "text" : "password"} />
                    <span className="pword-eye" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </span>
                  {errors.password && errors.password.message && (
                    <ErrorMsgDisplayComponent message={errors.password.message} />
                  )}
                </Form.Group>
                <p className="forgot-pw" onClick={() => setShowModal(true)}>Forgot password?</p>
                <Button type="submit" disabled={isSubmitting} className="w-100">
                  {" "}
                  Submit{" "}
                </Button>
                <p>
                  Don’t have an account?{" "}
                  <a href={ROUTES.SIGNUP_PAGE_ROUTE}>Sign up now</a>{" "}
                </p>
              </Form>
            </>
            :
            <>
              <Row>
                <BackButton />
              </Row>
              <h2 className="title-2">Verify your account</h2>
              <p>
                Almost done! We sent you a verification code to your mobile number. Please give it a visit and enter the verification code!
              </p>
              <p>Verification code</p>

              <OTPInput OTPlength={6} setOTPNumber={setOtpNumber} />
              <div className='timer-component'>
                {!isTimerComplete && <TimerDisplayComponent timeLimit={2} isTimerComplete={setIsTimerComplete} timerText='Resend OTP' />}

                {isTimerComplete && <p className='resend-otp-btn' onClick={() => resendVerification()}>Resend OTP</p>}
              </div>
              <Button className="w-100" onClick={() => verifyOTPNumber()}>
                Submit
              </Button>
            </>
          }
          {errors.root && errors.root.message &&
            <ToastComponent message={errors.root.message} show={showToast} setShow={setShowToast} toastType={"error"} />
          }
        </div>
        <div className="signup-banner login-banner">
          <div id="recaptcha-login-container"></div>
          <span>
            <img width="150" className="logo" src={Logo} alt="" />
          </span>
          <img
            src={
              process.env.REACT_APP_DOMAIN_NAME + "/images/Signup-banner.jpg"
            }
            alt="signup_banner"
          />
        </div>
        {showModal && <ForgotPasswordModal show={showModal} handleClose={() => handleCloseModal()} />}
      </div>
    </Container>
  );
}
