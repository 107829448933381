import React, { useEffect, useState } from "react";
import HeroBanner from "../components/Layout/HeroBanner";
import HomeServiceComponent from "../components/ServiceComponents/HomeServiceComponent";
import { PAYMENT_PROVIDERS } from "../utilities/PaymentProviders";
import { IPaymentProvider, IService } from "../utilities/ModelTypes";
import { getServices } from "../services/AggregatorService";
import Loader from "../components/CommonComponents/Loader";
import ErrorComponent from "../components/CommonComponents/ErrorComponent";
import { Container, Row } from "react-bootstrap";
import HomeServiceLoader from "../components/Loaders/HomeServiceLoader";
import ServiceIdentificationModal from "../components/ServiceComponents/ServiceIdentificationModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { resetPackage } from "../redux/packageOrder/packageOrderSlice";

export default function HomePage() {
  /**
   * States and methods to obtain th services
   */
  const [services, setServices] = useState<IService[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  //Get the aggregator services
  useEffect(() => {
    const getAggregatorServices = async () => {
      try {
        const results = await getServices();

        if (!results) return;

        setServices(results);
      } catch (error) {
        setError("Unable to load the services");
      } finally {
        setLoading(false);
      }
    };
    if (services == null) getAggregatorServices();
  });


  /**
   * Handling the service onClick method
   */
  const [showIdentificationModal, setShowIdentificationModal] = useState<boolean>(false);
  const [currentService, setCurrentService] = useState<IService | null>(null);

  const selectService = (service: IService) => {
    setCurrentService(service);
    setShowIdentificationModal(true);
  }

  /**
  * Clearing the service from the store if it prevails. 
  */
  const packageOrder = useSelector((state: RootState) => state.packageOrder);
  const dispatch = useDispatch();

  useEffect(() => {
    if (packageOrder && packageOrder.country !== null) {
      dispatch(resetPackage());
    }
  }, [packageOrder])


  return (
    <div>
      <HeroBanner
        image={
          process.env.REACT_APP_DOMAIN_NAME + "/images/Home-hero-banner.png"
        }
        title={"Seamless Mobile Top-Ups Worldwide. Fast, Easy, and Secure!"}
        description={
          "Instantly recharge your mobile phone or send top-ups worldwide. Fast, secure, and convenient service at your fingertips."
        }
      />
      <Container className="card section-card-container">
        <div className="section-container section-container-home">
          <>
            <div className="section-title">
              <h2 className="title-2">Choose Your Service</h2>
              <h4 className="title-4">
                Choosing your service type helps us customize your experience,
                whether it’s a quick top-up or a full recharge. Get exactly what
                you need, effortlessly and on the go!
              </h4>
            </div>
          </>
          {loading &&
            <Container className="service-row">
              <div className="card-services">
                <HomeServiceLoader />
              </div>
            </Container>
          }
          {error && <ErrorComponent errorMessage={error} />}
          <div className="service-row">
            {services &&
              services.map((service: IService, index: number) => (
                <div className="card-services" key={index}>
                  <HomeServiceComponent service={service} showModal={() => selectService(service)} />
                </div>
              ))}
            {currentService && showIdentificationModal && <ServiceIdentificationModal show={showIdentificationModal} handleClose={() => setShowIdentificationModal(false)} service={currentService.serviceName} />}
          </div>
        </div>
      </Container >
      <div>
        <Container className="card section-card-container">
          <Row>
            <h2 className="title-2 text-center">
              we collaborate with payment partners
            </h2>
          </Row>
          <Row className="payment-provider-row">
            {PAYMENT_PROVIDERS.map(
              (provider: IPaymentProvider, index: number) => (
                <img
                  key={index}
                  src={provider.url}
                  alt={`Payment Provider - ${provider.name}`}
                  className="payment-icons"
                />
              )
            )}
          </Row>
        </Container>
      </div>
    </div >
  );
}

