import { Button, Col } from "react-bootstrap";
import ButtonComponent from "../../CommonComponents/ButtonComponent";

interface IManageContentCard {
  sectionTitle: string;
  onClickEdit: () => any;
}

export const ManageContentCard = ({
  sectionTitle,
  onClickEdit,
}: IManageContentCard) => {
  return (
    <>
      <Col className="d-flex align-items-center section-title">{sectionTitle}</Col>
      <Col className="d-flex justify-content-end">

      <ButtonComponent
              text="Edit Section"
              onClick={onClickEdit}
              variant="secondary"
              customStyling="edit-section-button align-horizontal-center align-vertical-center"
            />
      </Col>
    </>
  );
};
