import React from "react";
import { Link , useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";
import { Button, Col, Container, Row } from "react-bootstrap";
import facebookIcon from "../../images/CustomerDashbaord/facebook.svg";
import instagramIcon from "../../images/CustomerDashbaord/instagram.svg";
import linkedinIcon from "../../images/CustomerDashbaord/linkedin.svg";

export default function Footer() {
  const navigate = useNavigate();
  
  return (
    <div className="footer">
      <Container>
        <Row>
          <h3 className="footer-title">We're Here to Help!</h3>
        </Row>
        <Row>
          <p className="footer-description">
            {" "}
            Need help or have questions? Our support team is available via
            email, or phone to assist you.
          </p>
        </Row>

        <Row className="footer-mid-row">
          <Col md={6} xs={12}>
            <Button className="btn btn-white" onClick={()=> navigate(ROUTES.CONTACT_US_PAGE)}>Contact Us</Button>
          </Col>
          <Col md={6} xs={12} className="footer-right">
            <div className="social-links">
              <img
                src={facebookIcon}
                alt="facebook-icon"
                className="footer-icon"
              />
              <img
                src={linkedinIcon}
                alt="linkedin-icon"
                className="footer-icon"
              />
              <img
                src={instagramIcon}
                alt="instagram-icon"
                className="footer-icon"
              />
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="bottom-row">
          <Col
            lg={6}
            md={12}
            className="order-lg-1 order-md-2 order-sm-2 order-2"
          >
            <p className="copyright">
              Copyright © 2024 GreenLeaf. All rights reserved.
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            className="order-lg-2 order-md-1 order-sm-1 order-1"
          >
            <div className="footer-links">
              <Link to={ROUTES.ADMIN_LOGIN_PAGE_ROUTE}> Login as Admin </Link>
              <Link to={ROUTES.ABOUTUS_PAGE_ROUTE}>About us</Link>
              <Link to={ROUTES.PRIVACY_POLICY_ROUTE}>Privacy Policy</Link>
              <Link to={ROUTES.TERMS_CONDITION_ROUTE}>Terms and Condition</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
