import React, { useEffect, useState } from 'react'

interface TimerProps {
    timeLimit: number;
    isTimerComplete: (isComplete: boolean) => void;
    timerText : string;
}

export default function TimerDisplayComponent({ timeLimit, isTimerComplete , timerText}: TimerProps) {
    const initialTime = timeLimit * 60;
    const [timeRemaining, setTimeRemaining] = useState<number>(initialTime);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime === 0) {
                    clearInterval(timerInterval);
                    isTimerComplete(true);
                    return 0;
                } else {
                    return prevTime - 1;
                }
            });
        }, 1000)

        return () => clearInterval(timerInterval);
    }, [])

    // const seconds = timeRemaining;

    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = timeRemaining % 60;

    return (
        <div><p className="timer-text">{timerText} in {minutes}:{seconds}</p></div>
    )
}
