import React from 'react';
import './styles/App.scss';
import RouteComponent from './RouteComponent';
import { AuthContextProvider } from './context/AuthContext';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthContextProvider>
          <RouteComponent />
        </AuthContextProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
