import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../constants/Routes';
import { getUserDetailsFirebaseFunc, signUpUser } from '../../services/AuthService';
import { IUser } from '../../utilities/ModelTypes';

export default function EmailVerificationPage() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const uid = searchParams.get("uid");
        const isMobileVerificaiton = searchParams.get("verify-mobile");
        
        console.log("Test",isMobileVerificaiton)

        if (!uid) setError("Invalid verification link");

        const signUp = async () => {
            try {
                const firbaseUser = await getUserDetailsFirebaseFunc()

                if (!firbaseUser || !firbaseUser.displayName) {
                    setError("User not signed up in firebase");
                    return;
                }

                localStorage.setItem('Token', firbaseUser.accessToken);

                const user: IUser = {
                    firstName: firbaseUser.displayName.split(" ")[0],
                    lastName: firbaseUser.displayName.split(" ")[1],
                    mobileNumber: firbaseUser.phoneNumber,
                    email: firbaseUser.email,
                    isMFAEnabled: isMobileVerificaiton === "true" ? true : false,
                }

                const response = await signUpUser(user)

                if (!response) {
                    setError("Unable to sign up user");
                    return;
                }

                if (isMobileVerificaiton === "true") {
                    navigate(ROUTES.OTP_VERIFICATION_PAGE)
                } else {
                    navigate(ROUTES.ACOUNT_VERIFIED_PAGE_ROUTE);
                }
            } catch (Error) {
                console.log("errpr",Error)
            }
        }

        signUp();
    }, [setError, navigate, searchParams])

    return (
        <div>
            {error}
        </div>
    )
}

