import visaLogo from "../images/PaymentProviders/visa-logo.svg";
import masterLogo from "../images/PaymentProviders/Mastercard.svg";
import gpayLogo from "../images/PaymentProviders/GooglePay.svg";
import AppleLogo from "../images/PaymentProviders/ApplePay.svg";
import PaypalLogo from "../images/PaymentProviders/Paypal.svg";
import StripeLogo from "../images/PaymentProviders/Stripe.svg";
import { IPaymentProvider } from "./ModelTypes";

export const PAYMENT_PROVIDERS: IPaymentProvider[] = [
  // { name: "Google", url: gpayLogo },
  // { name: "Apple", url: AppleLogo },
  // { name: "Stripe", url: StripeLogo },
  { name: "Master", url: masterLogo },
  { name: "Paypal", url: PaypalLogo },
  { name: "Visa", url: visaLogo },
];
