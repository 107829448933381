import { format, subMonths } from "date-fns";

export const formatDate = (date: Date | string, pattern: string) =>
  format(date, pattern);

export const getPreviousMonth = (todayDate: Date): Date => {
  const today = new Date(todayDate); // Get today's date
  const lastMonth = subMonths(today, 1); // Subtract one month from today
  return lastMonth;
};
