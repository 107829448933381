import React, { useEffect, useState } from 'react'
import HeroBanner from '../../components/Layout/HeroBanner'
import { Col, Container, Row } from 'react-bootstrap'
import { IEnterpriseDetails } from '../../utilities/ModelTypes'
import { getEnterpriseDetails } from '../../services/EnterpriseService';

export default function ContactUsPage() {
    const [enterpriseData, setEnterpriseData] = useState<IEnterpriseDetails | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await getEnterpriseDetails();

                if (!response) return;

                setEnterpriseData(response);
            } catch {
                setError("Unable to obtain enterprise data");
            } finally {
                setLoading(false)
            }
        }

        if (enterpriseData == null) getData();
    })


    return (
        <div>
            <HeroBanner image={process.env.REACT_APP_DOMAIN_NAME + '/images/Promotions-hero-banner.jpeg '} title={'Get in Touch with Us!'} description={'We’re here to help! Whether you have a question, need assistance, or just want to share your feedback, our team is ready to assist you. Reach out to us through any of the methods below, and we’ll get back to you as soon as possible.'} />

            <Container>
                {!loading && enterpriseData !== null &&
                    <Row>
                        <div>
                            <h4>Email Us</h4>
                            <p>Send us an email with your queries, and our support team will respond within 24 hours.</p>
                            <p>{enterpriseData.emaillAdd}</p>
                        </div>
                        <div>
                            <h3>Call Us</h3>
                            <p>Speak directly with our support team for immediate assistance. Our phone lines are open from 9 AM to 6 PM, Monday to Friday.</p>
                            <p>{enterpriseData.mobileNumber}</p>
                        </div>
                        <div>
                            <h3>Visit Us</h3>
                            <p>Stop by our office during business hours. We’d love to meet you in person!</p>
                            <p>{enterpriseData.address}</p>
                        </div>
                    </Row>
                }
            </Container>

        </div>
    )
}
