import React from 'react'
import { Container } from 'react-bootstrap'
import InfoDisplayComponent from '../../components/UserLogin/InfoDisplayComponent'
import { ROUTES } from '../../constants/Routes'

export default function ResetPasswordConfirmation() {
    return (
        <Container fluid className="signup-container">
            <InfoDisplayComponent title={'Password Change'} description={`A new password has been set for your account. Please proceed to login.`} navText='Proceed to Login' navigateUser={ROUTES.LOGIN_PAGE_ROUTE} />
        </Container>
    )
}
