import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Navbar, } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/Routes'
import { AuthContext } from '../../context/AuthContext'
import ButtonComponent from '../CommonComponents/ButtonComponent'
import UserProfileDropdown from './UserProfileDropdown'

export default function LoginHeader() {
  const { user, getCurrentUser, isLoading, isAnonymous } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        await getCurrentUser();
      } catch (error) {
        const err = error as Error;
        setError(err.message)
      }
    }

    if (user === null && !isAnonymous) fetchUser()
  }, [user, isAnonymous]);


  //Sign up user method
  const signUpUser = () => {
    navigate(ROUTES.SIGNUP_PAGE_ROUTE)
  }


  return (
    <>
      {!isLoading &&
        <>
          {user &&
            <>
              <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <UserProfileDropdown user={user} />
              </Navbar.Collapse>
            </>
          }
          {!user &&
            <>
              <Navbar.Text>
                <Link className='btn btn-white btn-login' to={ROUTES.LOGIN_PAGE_ROUTE}> Login </Link>
              </Navbar.Text>
              <Navbar.Text>
                <ButtonComponent text='Sign Up' onClick={() => signUpUser()} variant={'primary'} isDisabled={false} />
              </Navbar.Text>
            </>
          }
        </>
      }
    </>
  )
}

