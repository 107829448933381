import { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import {
  getAllNetworkProviders,
  searchNetworkProvider,
} from "../../../services/DashboardService";
import { INetworkProvider } from "../../../types/types";
import { logError } from "../../../utilities/logError";
import { PaginationComponent } from "../../Common/PaginationComponent";
import ButtonComponent from "../../CommonComponents/ButtonComponent";
import AddAdminModal from "../AdminUserManagement/AddAdminModal";
import { HeaderWithCountrySelector } from "../DashboardComponents/HeaderWithCountrySelector";
import { EditNetworkProvider } from "./EditNetworkProvider";
import { AddNetworkProvider } from "./AddNetworkProvider";

export default function ManageNetworkProviders() {
  const [networkProviders, setNetworkProviders] = useState<any>([]);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [networkProviderSearched, setNetworkProviderSearched] = useState("");
  const [loading, setLoading] = useState(false);

  /**
   * useEffect - fetch admin data after component mounted and when currentPage got changed
   */
  useEffect(() => {
    fetchNetworkProviders();
  }, [currentPage]);

  /**
   * Method - trigger admin data fetching api call
   */
  const fetchNetworkProviders = async () => {
    try {
      const response: any = await getAllNetworkProviders(currentPage);
      setTotalNumberOfRecords(21);
      setNetworkProviders(response?.data);
    } catch (error: any) {
      logError(error.message, "fetching admin users data");
    }
  };

  const numberOfPages = Array.from(
    { length: Math.ceil(totalNumberOfRecords / 5) + 1 },
    (_, index) => index + 1
  );

  /**
   * Method - show and close hide new admin modal
   */
  const handleShowAdminModal = () => {
    setShowAddNewModal((showAddNewModal: boolean) => !showAddNewModal);
  };

  /**
   * Method - handle country change functionality
   */
  const handleCountryChange = (country: string) => {};

  /**
   * Method - handle back edit provider form back button
   */
  const handleBackToProviders = () => setSelectedProvider("");


   /**
   * Method - handle network providers search
   */
  const handleSearchProvider = async (e:React.MouseEvent) => {
    e.preventDefault()
    try {
      const response: any = await searchNetworkProvider(
        networkProviderSearched
      );
    } catch (error: any) {
      logError(error?.message, "search network provider");
    }
  };

  if (selectedProvider) {
    return (
      <EditNetworkProvider
        providerId={selectedProvider}
        handleBackToProviders={handleBackToProviders}
      />
    );
  }

  return (
    <>
      {/* add new admin modal  */}
      <AddAdminModal open={showAddNewModal} onClose={handleShowAdminModal} />

      {networkProviders?.length === 0 && (
        <AddNetworkProvider
          handleAddNewNetworkProvider={() =>
            setSelectedProvider("new_network_provider")
          }
        />
      )}

      {networkProviders?.length > 0 && (
        <Container className="dashboard-section-container admin-user-section-container">
          {/* header section  */}
          <Row>
            <Col sm={12} md={8} lg={8}>
              <div className="dashboard-section-title">
                Network Provider Details
              </div>
              <div className="mt-2 dashboard-section-description">
                A centralized hub for managing all network operator details and
                codes used by service providers.
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col className="d-flex align-items-center" sm={12} md={6} lg={6}>
              <Form className="network-provider-search-bar">
                <Form.Group as={Col}>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setNetworkProviderSearched(e.target.value)
                      }
                    />
                  </InputGroup>

                  <ButtonComponent
                    text={""}
                    variant={"secondary"}
                    onClick={handleSearchProvider}
                  >
                    <img
                      src="/icons/dashboard/search-icon.svg"
                      alt="edit-icon"
                      className="api-value-edit-icon"
                    />
                  </ButtonComponent>
                </Form.Group>
              </Form>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="d-flex align-items-center justify-content-end"
            >
              <HeaderWithCountrySelector
                headerTitle=""
                containerStyles="full-width"
                onCountryChange={handleCountryChange}
              />
            </Col>
          </Row>

          <Row className="admin-table-head-tr">
            <Col sm={12} md={3} lg={3} className="admin-table-head">
              Network Operator
            </Col>
            <Col
              sm={12}
              md={3}
              lg={3}
              className=" admin-table-head admin-table-head-email"
            >
              Description
            </Col>
            <Col sm={12} md={3} lg={3} className="admin-table-head">
              Redeemable Method
            </Col>
            <Col sm={12} md={3} lg={3} className="admin-table-head">
              T&C
            </Col>
            <Col></Col>
          </Row>

          {networkProviders?.map(
            (provider: INetworkProvider, index: number) => {
              // calculating tr background color based in the index
              const trClassName = `admin-table-body-tr ${
                index % 2 === 1 ? "odd-tr-background" : "even-tr-background"
              }`;

              return (
                <Row className={trClassName}>
                  <Col sm={6} md={3} lg={3} className="admin-table-td">
                    <div>
                      <div className="admin-table-td-title">Name</div>
                      <div> {provider?.name}</div>
                    </div>
                  </Col>
                  <Col sm={6} md={3} lg={3} className="admin-table-td ">
                    <div>
                      <div className="admin-table-td-title">Description</div>
                      <div> {provider?.description}</div>
                    </div>
                  </Col>
                  <Col sm={6} md={3} lg={3} className="admin-table-td ">
                    <div>
                      <div className="admin-table-td-title">
                        Redeemable Method
                      </div>
                      <div> {provider?.radeemableMethod}</div>
                    </div>
                  </Col>
                  <Col sm={6} md={3} lg={1} className="admin-table-td ">
                    <div>
                      <div className="admin-table-td-title">T&C</div>
                      <div> T&C</div>
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={12}
                    lg={2}
                    className="admin-table-td d-flex flex-row justify-content-end align-items-center "
                  >
                    <ButtonComponent
                      text="View & Edit"
                      onClick={() => setSelectedProvider(provider.id)}
                      variant="primary"
                      customStyling="view-and-edit-button"
                    />
                  </Col>
                </Row>
              );
            }
          )}

          {/* pagination component  */}

          <Row className="admin-table-body-tr">
            <Col
              sm={12}
              className="d-flex justify-content-center gap-2 admin-table-pagination-container"
            >
              {/* <PaginationComponent
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              /> */}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
