import React, { useEffect, useState } from 'react'
import OTPInput from '../../components/FormComponents/OTPInput'
import { Button, Container, Form } from 'react-bootstrap';
import Logo from "../../assets/logo.png";
import TimerDisplayComponent from '../../components/FormComponents/TimerDisplayComponent';
import { SubmitHandler, useForm } from 'react-hook-form';
import { mobileVerificationType } from '../../utilities/SchemaTypes';
import { zodResolver } from '@hookform/resolvers/zod';
import { mobileVerificationSchema } from '../../utilities/ValidationSchemas';
import { getUserDetailsFirebaseFunc, sendOTP, updateUser, verifyOTP } from '../../services/AuthService';
import PhoneNumberInput from '../../components/FormComponents/PhoneNumberInput';
import ToastComponent from '../../components/CommonComponents/ToastComponent';
import { auth } from '../../firebase';
import { RecaptchaVerifier } from 'firebase/auth';
import { ROUTES } from '../../constants/Routes';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../utilities/ModelTypes';

export default function OTPVerificationPage() {
    const { register,
        handleSubmit,
        setError,
        setValue,
        watch,
        formState: { errors, isSubmitting } } = useForm<mobileVerificationType>({
            resolver: zodResolver(mobileVerificationSchema)
        });


    /**
     * Setting the invisible recaptcha verifier
     */
    const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();

    useEffect(() => {
        const recaptchaVerifier: RecaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", { "size": "invisible", });

        setRecaptchaVerifier(recaptchaVerifier);

        return () => {
            recaptchaVerifier.clear();
        }
    }, [auth]);


    /**
     * States and methods to send the OTP number
     */
    const [showVerification, setShowVerification] = useState<boolean>(false);
    const [verificationId, setVerificationId] = useState<string | null>(null);
    const [show, setShow] = useState<boolean>(true);

    const onSubmit: SubmitHandler<mobileVerificationType> = async (data: mobileVerificationType) => {
        try {
            if (!recaptchaVerifier) return;

            const response = await sendOTP(auth, recaptchaVerifier, data);

            if (!response) return;

            setVerificationId(response);

            setShowVerification(true);

        } catch (error) {
            const err = error as Error;
            setShow(true);
            setError("root", { message: err.message });
        }
    }


    /**
     * Verifying the OTP
     */
    const navigate = useNavigate();
    const currentMobileNumber = watch("mobileNumber");

    const verifyOTPNumber = async () => {
        try {
            if (!verificationId) {
                setError("root", { message: "Verification ID not found " });
                return
            }

            if (!otpNumber) {
                setError("root", { message: "OTP number not found " });
                return
            }

            const response = await verifyOTP(verificationId, otpNumber)

            console.log("verification", response);

            if (!response) {
                setError("root", { message: "Unable to verify the OTP" });
                return
            }

            const firbaseUser = await getUserDetailsFirebaseFunc()
            console.log("firebase", currentMobileNumber, firbaseUser)

            if (!firbaseUser || !firbaseUser.displayName) {
                setError("root", {message:"User not found up in firebase"});
                return;
            }


            localStorage.setItem('Token', firbaseUser.accessToken);

            const user: IUser = {
                firstName: firbaseUser.displayName.split(" ")[0],
                lastName: firbaseUser.displayName.split(" ")[1],
                mobileNumber: currentMobileNumber.replaceAll(/\s/g,''),
                isMFAEnabled: true,
            }

            const updateMFA = updateUser(user);

            if (!updateMFA) {
                setError("root", { message: "Unable to update user" });
                return;
            }

            navigate(ROUTES.ACOUNT_VERIFIED_PAGE_ROUTE)


        } catch (error) {
            const err = error as Error;
            setShow(true);
            setError("root", { message: err.message });
        }
    }


    /**
     * Resending the OTP verification
     * @param data 
     */
    const resendVerification = async (data: mobileVerificationType) => {
        try {
            const recaptchaVerifier: RecaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", { "size": "invisible", });

            const response = await sendOTP(auth, recaptchaVerifier, data);

            if(!response) {
                setError("root", { message: "unable to resend OTP" });
                return;
            }

            setVerificationId(response);

            setShowVerification(true);
        } catch (error) {
            const err = error as Error;
            setShow(true);
            setError("root", { message: err.message });
        }
    }


    /**
     * Others states
     */
    const [otpNumber, setOtpNumber] = useState<string | null>(null);
    const [isTimerComplete, setIsTimerComplete] = useState<boolean>(false);


    return (
        <Container fluid className="signup-container">
            <div className="page-signup">
                <div className="form-signup">
                    {!showVerification &&
                        <>
                            <h2 className="title-2"> Enter you mobile Number</h2>
                            <p>In order to set up 2FA please provide you moble number </p>
                            <Form className="OTP-verification" onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group
                                    className="form-group w-100"
                                    controlId="signupForm.mobileNumber"
                                >
                                    <PhoneNumberInput name="mobileNumber" register={register} errors={errors.mobileNumber} setValue={setValue} />
                                </Form.Group>
                                <Button className="w-100" type="submit" disabled={isSubmitting}> Submit </Button>
                                <div id="recaptcha-container"></div>
                            </Form>
                        </>
                    }
                    {showVerification &&
                        <>
                            <h2 className="title-2">Verify your account</h2>
                            <p>
                                Almost done! We sent you a verification code to your mobile number. Please give it a visit and enter the verification code!
                            </p>
                            <p>Verification code</p>

                            <OTPInput OTPlength={6} setOTPNumber={setOtpNumber} />

                            <div className='timer-component'>
                                {!isTimerComplete && <TimerDisplayComponent timeLimit={2} isTimerComplete={setIsTimerComplete} timerText='Resend OTP' />}

                                {isTimerComplete && <p className='resend-otp-btn' onClick={() => handleSubmit(resendVerification)}>Resend OTP</p>}
                            </div>
                            <Button className="w-100" onClick={() => verifyOTPNumber()}>
                                Submit
                            </Button>
                        </>
                    }
                    {show && errors.root && errors.root.message && <ToastComponent message={errors.root.message} show={show} setShow={setShow} toastType='error' />}
                </div>
                <div className="signup-banner login-banner">
                    <span>
                        <img width="150" className="logo" src={Logo} alt="" />
                    </span>
                    <img
                        src={
                            process.env.REACT_APP_DOMAIN_NAME + "/images/Signup-banner.jpg"
                        }
                        alt="signup_banner"
                    />
                </div>
            </div>
        </Container>
    )
}


