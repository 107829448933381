import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import ButtonComponent from "../CommonComponents/ButtonComponent";
import PhoneNumberInput from "../FormComponents/PhoneNumberInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";
import { IService } from "../../utilities/ModelTypes";
import ReactFlagsSelect from "react-flags-select";
import ErrorMsgDisplayComponent from "../FormComponents/ErrorMsgDisplayComponent";
import { countrySelectionType, mobileVerificationType } from "../../utilities/SchemaTypes";
import { countrySelectionSchema, mobileVerificationSchema } from "../../utilities/ValidationSchemas";
import { useDispatch, useSelector } from "react-redux";
import { addPackage, resetPackage } from "../../redux/packageOrder/packageOrderSlice";
import { parsePhoneNumber } from "react-phone-number-input";
import { Services } from "../../utilities/Enums";
import { RootState } from "../../redux/store";

interface ServiceMobileNumberProps {
  show: boolean;
  handleClose: (value: boolean) => void;
  service: string;
  submitModal?: () => void;
}

export default function ServiceIdentificationModal({ show, handleClose, service, submitModal }: ServiceMobileNumberProps) {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm<mobileVerificationType>({
    resolver: zodResolver(mobileVerificationSchema),
  });

  const {
    register: registerCountry,
    handleSubmit: handleCountrySubmit,
    formState: { errors: errorsCountrySelect },
    setValue: countrySet,
    trigger,
  } = useForm<countrySelectionType>({
    resolver: zodResolver(countrySelectionSchema),
    defaultValues: { selectedCountry: "" },
  });

  /**
   * states and use effect to populate the details to the model based on the identification type
   */
  const packageOrder = useSelector((state: RootState) => state.packageOrder);

  const [isMobileService, setIsMobileService] = useState<boolean | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);

  useEffect(() => {
    if (service == Services.MOBILE) {
      setTitle("Enter your destination number")
      setDescription(" Enter your number to unlock the best network providers and top-up packages tailored just for you.")
      setIsMobileService(true);
      if (packageOrder.mobileNumber) setValue("mobileNumber", packageOrder.mobileNumber);
    } else {
      setTitle("Enter your destination country")
      setDescription("Pick your country to unlock the best network providers and top-up packages tailored just for you. ")
      setIsMobileService(false);
    }
  }, [service])

  /**
   * Handlling the obj clearence in service page
   */
  const [isServicePage, setIsServicePage] = useState<boolean>(false);
  const [isServicesPage, setIsServicesPage] = useState<boolean>(false);
  const routeLocation = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const isService = routeLocation.pathname.includes("/service");
    isService ? setIsServicePage(true) : setIsServicePage(false);

    const isServices = routeLocation.pathname.includes("/services");
    isServices ? setIsServicesPage(true) : setIsServicesPage(false);
  });

  const nextNavigation = () => {
    if (submitModal) submitModal();

    handleClose(false);

    if (!isServicePage || isServicesPage) navigate(ROUTES.SERVICE_PAGE_ROUTE);
  }

  /**
   * Handling the country drop down value change
   */
  const [selectedCountry, setSelectedCountry] = useState<string>("");

  const handleCountrySelect = (code: any) => {
    setSelectedCountry(code);
    countrySet("selectedCountry", code, { shouldValidate: true });
    trigger("selectedCountry");
  };

  /**
   * Handling the form submit functionality
   */
  const navigate = useNavigate();

  //On submit for the phone number field
  const onSubmit: SubmitHandler<mobileVerificationType> = (data: mobileVerificationType) => {
    try {
      dispatch(resetPackage());

      console.log("Test")
      const country = parsePhoneNumber(data.mobileNumber);

      if (!country || !country?.country) return;

      let currentOrder = {
        country: country.country,
        mobileNumber: data.mobileNumber,
        service: service,
        networkProvider: null,
        product: null,
        value: null
      }

      dispatch(addPackage(currentOrder));
      console.log("order", currentOrder);

      nextNavigation()
    } catch (error) {
      const err = error as Error;
      setError("root", { message: err.message });
    }
  };


  //On submit for the country drop down
  const countrySubmit: SubmitHandler<countrySelectionType> = (data: countrySelectionType) => {
    try {
      let currentOrder = {
        country: data.selectedCountry,
        mobileNumber: null,
        service: service,
        networkProvider: null,
        product: null,
        value: null
      }
      dispatch(addPackage(currentOrder));

      nextNavigation()
    } catch (error) {
      const err = error as Error;
      setError("root", { message: err.message });
    }
  }


  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
      keyboard={false}
      className="modal-msg"
    >
      <div className="w-100 d-block text-end">
        {" "}
        <button className="btn btn-modal-close p-0 " onClick={() => handleClose(false)}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="36" height="36" rx="18" fill="#E4E4E7" />
            <path
              d="M24 13.2086L22.7914 12L18 16.7914L13.2086 12L12 13.2086L16.7914 18L12 22.7914L13.2086 24L18 19.2086L22.7914 24L24 22.7914L19.2086 18L24 13.2086Z"
              fill="#000"
            />
          </svg>
        </button>
      </div>

      <Modal.Body>
        {title && <div className="modal-title">{title}</div>}
        {description && <div className="modal-dec"> {description} </div>}

        {isMobileService ?
          <>
            {/* -------------------------------------------- Mobile field form --------------------------------------------   */}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <PhoneNumberInput
                name="mobileNumber"
                register={register}
                errors={errors.mobileNumber}
                setValue={setValue}
              />
              {errors.mobileNumber && errors.mobileNumber.message && <ErrorMsgDisplayComponent message={errors.mobileNumber.message} />}
              <ButtonComponent
                text={"Next"}
                onClick={handleSubmit(onSubmit)}
                variant={"primary"}
              />
            </Form>
          </>
          :
          <>
            {/* -------------------------------------------- Country field form --------------------------------------------   */}
            <Form onSubmit={handleCountrySubmit(countrySubmit)}>
              <ReactFlagsSelect
                selected={selectedCountry}
                onSelect={handleCountrySelect}
                searchable
                className="country-select"
              />
              <input
                type="hidden"
                {...registerCountry("selectedCountry")} // Register the field
              />
              {errorsCountrySelect.selectedCountry && errorsCountrySelect.selectedCountry.message && <ErrorMsgDisplayComponent message={errorsCountrySelect.selectedCountry.message} />}
              <ButtonComponent
                text={"Next"}
                onClick={handleCountrySubmit(countrySubmit)}
                variant={"primary"}
              />
            </Form>
          </>}
        {errors.root && errors.root.message && <ErrorMsgDisplayComponent message={errors.root.message} />}
      </Modal.Body>
    </Modal>
  );
}
