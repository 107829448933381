import React from 'react'
import { Card, Placeholder } from 'react-bootstrap'

export default function HomeServiceLoader() {
    return (
        <Card className="service-card">
            <Card.Body>
                <div className="crd-left">
                    <Placeholder xs={12} />
                    <Placeholder xs={8} />
                    <Placeholder xs={7} />
                    <Placeholder xs={3} />
                    <Placeholder xs={4} />
                </div>
                <div className="crd-right">
                    <div>

                    </div>
                    {/* <img
                        src={
                            process.env.REACT_APP_DOMAIN_NAME +
                            "/images/Services/Service-" +
                            service.serviceName +
                            ".svg"
                        }
                        alt={service.serviceName}
                    /> */}
                </div>
            </Card.Body>
        </Card>
    )
}
