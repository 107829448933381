import { numericFormatter } from "react-number-format";
import { CURRENCY_SYMBOLS } from "../constants/ApplicationData";
import { CurrencyTypeTypes } from "../types/types";

/**
 * Method - format currency values
 * @param currencyType type of the currency
 * @param valueToFormat value to be formatted
 * @param thousandSeparator type to display the thousand separator, by default separated by a space
 * @returns formatted currency value
 */
export const formatCurrencyValues = (
  currencyType: string,
  valueToFormat: string | number | undefined,
  thousandSeparator = " "
) => {
  const formattedValue = numericFormatter(String(valueToFormat ?? 0), {
    thousandSeparator: thousandSeparator,
    decimalScale:2,
    fixedDecimalScale:true
  });

  return `${
    CURRENCY_SYMBOLS[currencyType as keyof CurrencyTypeTypes]
  } ${formattedValue}`;
};
