import chroma from "chroma-js";

export const getShadesForBars = (
  baseColors: string[],
  numberOfBars: number
) => {
  // Create an array to hold the color for each bar
  let colors: string[] = [];

  // Iterate over the base colors and generate shades

    // Generate a color scale with `numberOfBars` shades from the base color
    const scale = chroma
      .scale(baseColors)
      .mode("lch")
      .colors(numberOfBars);
    colors = colors.concat(scale);

  // Slice the colors to match the number of bars needed
  return colors.slice(0, numberOfBars);
};
