import { Col } from "react-bootstrap";

export interface TopProductDetailInterface {
  product: {
    color: string;
    product: string;
    numberOfSales: number;
  };
}

export const TopProductInfo = ({ product }: TopProductDetailInterface) => {
  return (
    <>
      <Col
        sm={10}
        className="d-flex align-items-center gap-2 top-product-info-text"
      >
        <div
          className="top-product-detail-color-dot "
          style={{
            backgroundColor: product.color,
          }}
        ></div>
        <div className="top-product-info-text">{product.product}</div>
      </Col>
      <Col sm={2}>{product.numberOfSales}</Col>
    </>
  );
};
