import { AxiosError } from "axios";
import { INetworkProvider, INetworkProviders, IProduct, IPromotion, IService } from "../utilities/ModelTypes";
import { get } from "../utilities/APIHelper";

interface IServiceResponse {
    data: IService[];
}

export const getServices = async (): Promise<IService[]> => {
    try {
        const response = await get<IServiceResponse>('platform/services')

        if (!response) throw new Error("Error");

        return response.data;

    } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.message)
    }
};


interface INetworkProvidersResponse {
    data: INetworkProvider[]
}

export const getNetworkProviders = async (country: string, service: string, page: number, limit: number): Promise<INetworkProvider[]> => {
    try {
        const response = await get<INetworkProvidersResponse>(`network-providers?countryISO=${country}&service=${service}&page=${page}&limit=${limit}`)

        if (!response) throw new Error("Unable to fetch network providers");

        return response.data;
    } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.message)
    }
}


interface INetworkProviderResponse {
    data: INetworkProvider
}

export const getNetworkProvider = async (id: string): Promise<INetworkProvider> => {
    try {
        const response = await get<INetworkProviderResponse>(`network-providers/${id}`)

        if (!response) throw new Error("Unable to fetch network provider");

        return response.data;
    } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.message)
    }
}


interface IProductsResponse {
    data: IProduct[];
    productCounts: {
        totalProducts: number;
        totalProductsNumberWithRange: number;
    }
}

export const getPackages = async (country: string, networkId: string, rangedOnly: boolean, page: number, limit: number): Promise<IProductsResponse> => {
    try {
        const response = await get<IProductsResponse>(`products?countryISO=${country}&networkId=${networkId}&rangedOnly=${rangedOnly}&page=${page}&limit=${limit}`)

        if (!response) throw new Error("Unable to fetch packages");

        return response;
    } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.message)
    }
}

export const getPromotions = async (): Promise<IPromotion[]> => {
    try {
        const promotions = [{
            promotionId: "001",
            name: "Summer Bonanza Recharge",
            description: "Get 20% extra credit on all top-ups during the summer season.",
            startDate: "08/05/2006 03:05:15 ",
            endDate: "08/05/2006 03:05:15 ",
            networkProvider: "Mobitel",
            networkProviderImg: "",
            countryISO: "US",
            countryName: "United States",
            terms: "",
            availableProducts: []
        }, {
            promotionId: "002",
            name: "Summer Bonanza Recharge",
            description: "Get 20% extra credit on all top-ups during the summer season.",
            startDate: "08/05/2006 03:05:15",
            endDate: "08/05/2006 03:05:15",
            networkProvider: "Mobitel",
            networkProviderImg: "",
            countryISO: "LK",
            countryName: "Sri Lanka",
            terms: "",
            availableProducts: []
        },
        {
            promotionId: "003",
            name: "Summer Bonanza Recharge",
            description: "Get 20% extra credit on all top-ups during the summer season.",
            startDate: "string",
            endDate: "string",
            networkProvider: "Mobitel",
            networkProviderImg: "",
            countryISO: "IN",
            countryName: "India",
            terms: "",
            availableProducts: []
        }]

        return promotions;
    } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.message)

    }
}

export const getPromotion = async (): Promise<IPromotion> => {
    try {
        const promotion = {
            promotionId: "001",
            name: "Summer Bonanza Recharge",
            description: "Get 20% extra credit on all top-ups during the summer season.",
            startDate: "08/05/2006 03:05:15 ",
            endDate: "08/05/2006 03:05:15 ",
            networkProvider: "Mobitel",
            networkProviderImg: "",
            countryISO: "US",
            countryName: "United States",
            terms: "Promotion Terms & Conditions 1. The promotion is valid from June 1 to August 31, 2024. 2. Extra credit is valid for 30 days from the recharge date.   3. Offer applies to Orange network in France only.          4. Non-transferable and non-refundable.",
            availableProducts: [
                {
                    packageId: "10001",
                    packageDescription: "Top up your mobile with 10 USD and receive an additional 2 USD bonus credit.",
                    packageAmount: "10 USD + 2 USD Extra credit"
                },
                {
                    packageId: "10001",
                    packageDescription: "Top up your mobile with 10 USD and receive an additional 2 USD bonus credit.",
                    packageAmount: "10 USD + 2 USD Extra credit"
                },
                {
                    packageId: "10001",
                    packageDescription: "Top up your mobile with 10 USD and receive an additional 2 USD bonus credit.",
                    packageAmount: "10 USD + 2 USD Extra credit"
                }
            ]
        }

        return promotion;
    } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.message)

    }
}
