import React, { useEffect, useState } from 'react'
import Header from '../components/Layout/Header'
import SecondaryHeader from '../components/Layout/SecondaryHeader'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Layout/Footer'

export default function CustomerLayout() {
  const [isHidden, setIsHidden] = useState(false); // Initially visible
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (!isPaused) {
      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        // Scrolling down and past a threshold
        setIsHidden(true);
      } else if (currentScrollY <= 0 || currentScrollY < lastScrollY) {
        // Scrolling up or at the top of the page
        setIsHidden(false);
      }
    }

    setLastScrollY(currentScrollY);
  };

  const handleMouseMove = () => {
    setIsPaused(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [lastScrollY, isPaused]);

  return (
    <>
      <header className={`header ${isHidden ? 'hidden' : ''}`}>
        <Header />
        <SecondaryHeader />
      </header>
      <Outlet />
      <Footer />
    </>
  )
}
