import React from "react";
import { Button } from "react-bootstrap";
import ButtonComponent from "../../CommonComponents/ButtonComponent";

interface IAggregatorApiEditButton {
  handleClickEditButton: () => void;
}

export const AggregatorApiEditButton = ({
  handleClickEditButton,
}: IAggregatorApiEditButton) => {
  return (

    <ButtonComponent
              text="Edit"
              onClick={handleClickEditButton}
              variant="secondary"
              customStyling="api-value-edit-button"
              startIcon="/icons/dashboard/edit-icon.svg"
            />
  );
};
