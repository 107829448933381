import React from "react";
import SalesDataCard from "./SalesDataCard";
import { Col, Container, Row } from "react-bootstrap";
import { ADMIN_DASHBOARD_ICONS } from "../../../constants/ApplicationData";
import { EmptyState } from "./EmptyState";
import { IResourceCreator } from "../../../types/types";

export interface SalesDataInterface {
  salesDataResource: IResourceCreator;
  setIsSalesDataEmpty: any;
}

export const SalesData = ({
  salesDataResource,
  setIsSalesDataEmpty,
}: SalesDataInterface) => {
  const salesData: any = salesDataResource?.read()?.data;

  const salesDataExists =
    salesData?.totalTransactions !== null &&
    salesData?.topProductSales !== null &&
    salesData?.topDestinationSales !== null;

  // if sales data is not exists then update parent component sales data empty state
  if (!salesDataExists) {
    setIsSalesDataEmpty(true);
  }

  return (
    <>
      {/* render if only sales data exists */}
      {salesDataExists && (
        <Row>
          {/* Successful Transactions  */}
          <SalesDataCard
            image={ADMIN_DASHBOARD_ICONS["MONEY-BAG-ICON"]}
            title="Successful Transactions"
            salesDescription="Total transactions"
            salesValue={salesData?.totalTransactions}
            classNames="purple-left-border"
          />

          {/* Top Product by Sales */}
          <SalesDataCard
            image={ADMIN_DASHBOARD_ICONS["SUNGLASS-FACE-ICON"]}
            title="Top Product by Sales"
            subDescription="Product"
            subDescriptionValue={salesData?.topProduct}
            salesDescription="Number of Sales"
            salesValue={salesData?.topProductSales}
            classNames="green-left-border"
          />

          {/* Top Destinations by Sales */}
          <SalesDataCard
            image={ADMIN_DASHBOARD_ICONS["EARTH-ICON"]}
            title="Top Destinations by Sales"
            subDescription="Country"
            subDescriptionValue={salesData?.topSalesDestination}
            salesDescription="Number of Sales"
            salesValue={salesData?.topDestinationSales}
            classNames="yellow-left-border"
          />
        </Row>
      )}
    </>
  );
};
