import { auth, signInWithGooglePopup } from "../firebase";
import { FirebaseError } from "firebase/app";
import { Auth, createUserWithEmailAndPassword, getAuth, getMultiFactorResolver, multiFactor, MultiFactorError, MultiFactorResolver, onAuthStateChanged, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, sendEmailVerification, sendPasswordResetEmail, signInAnonymously, signInWithEmailAndPassword, signOut, updateProfile } from "firebase/auth";
import { loginFormType, mobileVerificationType, signUpFormType } from "../utilities/SchemaTypes";
import { ROUTES } from "../constants/Routes";
import { AxiosError } from "axios";
import { get, post, put } from "../utilities/APIHelper";
import { IUser } from "../utilities/ModelTypes";


/**
 * Sign up user into the system database
 * @param userData 
 */
interface ISignUpUserResponse {
    data: {
        userId: string
    };
    message: string
}
export const signUpUser = async (userData: IUser): Promise<ISignUpUserResponse> => {
    try {
        const response = await post<ISignUpUserResponse>('user', userData)

        if (!response) throw new Error("Unable to sign up user");

        return response;
    } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.message)
    }
}


/**
 * Firebase user name password sign in method
 * @param userData 
 * @returns 
 */
export const userNameSignUp = async (userData: signUpFormType): Promise<any> => {
    try {
        const response = await createUserWithEmailAndPassword(auth, userData.email, userData.password);

        if (!response) throw new Error("Unable to sign up user");

        const currentUser = response.user;

        //Updating the profile with the name
        await updateProfile(currentUser, {
            displayName: `${userData.firstName} ${userData.lastName}`
        })

        console.log("response", response)

        const actionCodeSettings = {
            url: `${process.env.REACT_APP_DOMAIN_NAME}/verify-email?uid=${currentUser.uid}&verify-mobile=${userData.isMFAEnabled}`,
            handleCodeInApp: true,
        };

        //Sending the verification email
        await sendEmailVerification(currentUser, actionCodeSettings);

        return response;

    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error(`${error.code}`)
        } else {
            throw new Error('Sign up failed.')
        }
    }
}


/**
 * Sign in with user name and password
 * @param data 
 * @returns 
 */
export const userNameLogin = async (data: loginFormType): Promise<any> => {
    try {
        const auth = getAuth();

        const response = await signInWithEmailAndPassword(auth, data.email, data.password);

        if (!response) throw new Error("Unable to login user");

        console.log("re", response);
        return response

    } catch (error: any) {
        if (error instanceof FirebaseError) {
            throw error
        } else {
            throw new Error('Sign up failed.')
        }
    }
}


/**
 * 2FA mobile auth functionality
 * @param user 
 * @param mobileNumber 
 * @returns 
 */
export const sendOTP = async (auth: Auth, recaptchaVerifier: RecaptchaVerifier, data: mobileVerificationType): Promise<any> => {
    try {
        const user = auth.currentUser;

        if (!user) throw new Error("Unable to get user");

        console.log("user", user)
        const multiFactorSession = await multiFactor(user).getSession();

        const phoneInfoOptions = {
            phoneNumber: data.mobileNumber,
            session: multiFactorSession
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);

        // Send SMS verification code.
        const verificationID = phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);

        return verificationID;
    } catch (error) {
        recaptchaVerifier.clear();
        throw new Error('Unable to enroll user to 2FA');
    }
};


/**
 * Send user verification code
 */
export const verifyOTP = async (verificationId: string, code: string): Promise<any> => {
    try {
        const user = auth.currentUser;

        if (!user) throw new Error("Unable to get user");

        console.log("user", user)

        const response = await PhoneAuthProvider.credential(verificationId, code);

        console.log("response", response)

        if (!response) throw new Error("Phone auth failed");

        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(response);

        const isEnrolled = await multiFactor(user).enroll(multiFactorAssertion, "Personal phone number");

        console.log("enrolled", isEnrolled);

        return response;
    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Unable to verify user:', error)
        } else {
            throw new Error('Unable to verify user.')
        }
    }
}


/**
 * Firebase Google SSO method
 * @returns 
 */
export const ssoSignInFunc = async (): Promise<any> => {
    try {
        const response = await signInWithGooglePopup();

        if (!response) throw new Error("Unable to sign in user");

        return response;

    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Sign in failed with error code:', error)
        } else {
            throw new Error('Sign in failed.')
        }
    }
}


/**
 * Firebase anonymous sign in method
 */
export const anonymousSignInFunc = async (): Promise<any> => {
    try {
        const response = await signInAnonymously(auth);

        if (!response) throw new Error("Anonymous sign in failed");

        let currentUser = null;

        await onAuthStateChanged(auth, (user) => {
            if (!user) throw new Error("Anonymous sign in failed");
            currentUser = user;
        });

        return currentUser;
    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Anonymous sign in failed with error code:', error)
        } else {
            throw new Error('Anonymous sign in failed.')
        }
    }
}


/**
 * Get user details from firebase
 * @returns 
 */
export const getUserDetailsFirebaseFunc = async (): Promise<any> => {
    try {
        return new Promise((resolve) => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    resolve(user); // User is signed in, resolve with user details
                } else {
                    resolve(null); // User is signed out, resolve with null
                }
            });
        });
    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Unable to fetch user details:', error)
        } else {
            throw new Error('Unable to fetch user details.')
        }
    }
}


/**
 * Get User details from our system
 * @returns 
 */
interface IUserDetailsResponse {
    data: IUser
}

export const getUserDetails = async (): Promise<IUser> => {
    try {
        const response = await get<IUserDetailsResponse>(`user/me`);

        if (!response) throw new Error("Unable to get user details");

        return response.data;
    } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.message)
    }
}

/**
 * Signout user from firebase
 * @returns 
 */
export const signOutFirebaseFunc = async (): Promise<boolean> => {
    try {
        await signOut(auth);
        return true;
    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Unable to signout firebase user:', error)
        } else {
            throw new Error('Unable to signout firebase user.')
        }
    }
}


/**
 * Signout from the system
 * @returns 
 */
export const systemSignOut = async (): Promise<boolean> => {
    try {
        await post<any>(`user/signout`)

        return true;
    } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.message)
    }
}


/**
 * Allowing user to resend verificaiton email
 * @param userData 
 */
export const resendVerificationEmail = async (userData?: signUpFormType): Promise<any> => {
    try {
        const user = auth.currentUser;

        if (!user) throw new Error("Unable to get the current user");

        const isMFAActive = userData?.isMFAEnabled ? true : false;

        const actionCodeSettings = {
            url: `${process.env.REACT_APP_DOMAIN_NAME}/verify-email?uid=${user.uid}&verify-mobile=${isMFAActive}`,
            handleCodeInApp: true,
        };

        //Sending the verification email
        await sendEmailVerification(user, actionCodeSettings);
    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Unable to send verification code:', error)
        } else {
            throw new Error('Unable to send verification code.')
        }
    }
}

/**
 * Reset the password
 * @param userEmail 
 * @returns 
 */
export const resetPassword = async (userEmail: string): Promise<any> => {
    try {
        const auth = getAuth();

        const response = sendPasswordResetEmail(auth, userEmail, {
            url: `${process.env.REACT_APP_DOMAIN_NAME}${ROUTES.RESET_PASSWORD_PAGE_ROUTE}`,
            handleCodeInApp: true,
        });

        return response;
    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Unable to send verification code:', error)
        } else {
            throw new Error('Unable to send verification code.')
        }
    }
}

/**
 * Get the refresh token from firebase
 * @returns 
 */
export const generateRefreshToken = async (): Promise<string> => {
    try {
        const user = auth.currentUser;

        if (!user) throw new Error("Unable to get user, please login");

        const refreshToken: string = await user.getIdToken(true);

        if (!refreshToken) throw new Error("Unable to obtain the refresh token");

        return refreshToken;
    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Unable to send verification code:', error)
        } else {
            throw new Error('Unable to send verification code.')
        }
    }
}


/**
 * Update user details in the backend
 * @param data 
 * @returns 
 */
export const updateUser = async (data: IUser): Promise<any> => {
    try {
        const response = put<any>(`user`, data);

        if (!response) throw new Error("Unable to update user");

        return response
    } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.message)
    }
}


export const handle2FALogin = async (error: MultiFactorError, recaptchaVerifier: RecaptchaVerifier): Promise<any> => {
    try {
        console.log("IOn the method", error)
        const auth = getAuth();
        const resolver = getMultiFactorResolver(auth, error);
        console.log("resolver", resolver);

        if (resolver) {
            if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
                const phoneInfoOptions = {
                    multiFactorHint: resolver.hints[0],
                    session: resolver.session
                }

                const phoneAuthProvider = new PhoneAuthProvider(auth);

                const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)

                return { resolver: resolver, verificationId: verificationId };
            }
        } else {
            throw new Error("Unable to get the resolver");
        }

        return true;
    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Unable to send verification code:', error)
        } else {
            throw new Error('Unable to send verification code.')
        }
    }
}


export const verify2FALogin = async (resolver: MultiFactorResolver, verificationId: string, verificationCode: string): Promise<any> => {
    try {
        const credentials = PhoneAuthProvider.credential(verificationId, verificationCode);

        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credentials);

        const userCredentials = await resolver.resolveSignIn(multiFactorAssertion);

        return userCredentials;
    } catch (error) {
        if (error instanceof FirebaseError) {
            throw new Error('Unable to send verification code:', error)
        } else {
            throw new Error('Unable to send verification code.')
        }
    }
}