export const ROUTES = {
    HOMEPAGE_ROUTE: '/',
    SERVICES_PAGE_ROUTE: '/services',
    SERVICE_PAGE_ROUTE:'/service',
    PROMOTIONS_PAGE:'/promotions',
    PRMOMOTION_PACKAGES_PAGE:'/packages',
    ABOUTUS_PAGE_ROUTE: '/about-us',
    PRIVACY_POLICY_ROUTE: '/privacy-policy',
    TERMS_CONDITION_ROUTE: '/terms-and-condition',
    CONTACT_US_PAGE: '/contact-us',
   
    LOGIN_PAGE_ROUTE: '/login',
    SIGNUP_PAGE_ROUTE: '/sign-up',
    VERIFY_USER_PAGE_ROUTE:'/verify-user',
    EMAIL_VERIFICATION_PAGE_ROUTE:'/verify-email',
    RESET_PASSWORD_PAGE_ROUTE: '/reset-password',
    RESET_PASSWORD_REQUEST_PAGE_ROUTE: '/reset-password-request',
    RESET_PASSWORD_CONFIRMATION_ROUTE:'/reset-password-confirmation',
    ACOUNT_VERIFIED_PAGE_ROUTE: '/account-verified',
    OTP_VERIFICATION_PAGE : "/otp-verification",
    NON_VERIFIED_ACCOUNT_PAGE: '/verify-account',

    ADMIN_LOGIN_PAGE_ROUTE: '/admin/login',
    ADMIN_PAGE_ROUTE:'/admin',
    SUPERADMIN_PAGE_ROUTE: '/super-admin',
    DASHBOARD_ROUTE:'/dashboard',

    ORDER_CONFIRMATION_ROUTE:'/order-confirmation',

    CUSTOMER_INFORMATION: '/user',
    PERSONAL_INFORMATION:'/personal-information',
    SECURITY_INFORMATION:'/security-information'
}