import React, { useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { ROUTES } from "../../constants/Routes";
import { NavLink } from "react-router-dom";
import ServiceIdentificationModal from "../ServiceComponents/ServiceIdentificationModal";
import { Services } from "../../utilities/Enums";

export default function SecondaryHeader() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleLinkClick = () => {
    setMenuOpen(false); // Close the menu when a link is clicked
  };

  /**
  * Handling the service onClick method
  */
  const [showIdentificationModal, setShowIdentificationModal] = useState<boolean>(false);
  const [currentService, setCurrentService] = useState<string | null>(null);

  const openServicePopup = (serviceType: string) => {
    setCurrentService(serviceType)
    setShowIdentificationModal(true);
  }

  const clearService = () => {
    setCurrentService(null);
  }

  return (
    <div className="wrap-bottom-navbar">
      <Navbar className="bottom-navbar" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}>
          {menuOpen ? (
            <span className="close">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none">
                <rect width="36" height="36" rx="18" fill="#30A0B7" />
                <path
                  d="M24 13.2086L22.7914 12L18 16.7914L13.2086 12L12 13.2086L16.7914 18L12 22.7914L13.2086 24L18 19.2086L22.7914 24L24 22.7914L19.2086 18L24 13.2086Z"
                  fill="white"
                />
              </svg>
            </span>
          ) : (
            <span className="hamburger">
              <svg
                width="36"
                height="36"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 12.5H21"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 6.5H21"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 18.5H21"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          )}
        </Navbar.Toggle>
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={`${menuOpen ? "show" : ""}`}
        >
          <Nav className="w-100 d-flex justify-content-around">
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav-link active-link" : "nav-link"
              }
              to={ROUTES.HOMEPAGE_ROUTE}
              onClick={() => clearService()}
            >
              Home
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav-link active-link" : "nav-link"
              }
              to={ROUTES.PROMOTIONS_PAGE}
              onClick={() => clearService()}
            >
              Promotions
            </NavLink>
            <Button
              className={`button-link ${currentService === Services.GIFT_CARDS ? " active-link" : " nav-link"}`}
              onClick={() => openServicePopup(Services.GIFT_CARDS)}
            >
              Gift Cards
            </Button>
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav-link active-link" : "nav-link"
              }
              to={ROUTES.SERVICES_PAGE_ROUTE}
              onClick={() => clearService()}
            >
              Services
            </NavLink>
            <Button
              className={`button-link ${currentService === Services.ESIM ? " active-link" : " nav-link"}`}
              onClick={() => openServicePopup(Services.ESIM)}
            >
              eSIM
            </Button>
            <Button
              className={`button-link ${currentService === Services.MOBILE ? " active-link" : " nav-link"}`}
              onClick={() => openServicePopup(Services.MOBILE)}
            >
              Mobile Top-Up
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {currentService && showIdentificationModal && <ServiceIdentificationModal show={showIdentificationModal} handleClose={() => setShowIdentificationModal(false)} service={currentService} />}
    </div>
  );
}
