import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AggregatorCard } from "./AggregatorCard";
import { IAggregator } from "../../../types/types";
import { logError } from "../../../utilities/logError";
import { getAllAggregators } from "../../../services/DashboardService";
import { AggregationPlaceHolder } from "../DashboardComponents/AggregationPlaceHolder";
import AggregationForm from "./AggregationForm";
import { useLocation } from "react-router-dom";
import { AggregatorStateChangeModal } from "./AggregatorStateChangeModal";

export default function ManageAggregators() {
  const [aggregators, setAggregators] = useState<IAggregator[]>([]);
  const [loadingAggregators, setLoadingAggregators] = useState(false);
  const [selectedAggregator, setSelectedAggregator] = useState<string>("");
  const [stateChangeAggregator, setStateChangeAggregator] =
    useState<IAggregator | null>(null);
  const routeLocation = useLocation();

  /**
   * useEffect - fetch aggregator data after component mounted and when currentPage got changed
   */
  useEffect(() => {
    fetchAggregators();
  }, []);

  /**
   * Method - trigger aggregator data fetching api call
   */
  const fetchAggregators = async () => {
    try {
      setLoadingAggregators(true);
      const response: any = await getAllAggregators();
      setAggregators(response?.data);
    } catch (error: any) {
      logError(error.message, "fetching admin users data");
    } finally {
      setLoadingAggregators(false);
    }
  };

  /**
   * Method - set selected aggregator to ""
   */
  const handleBackToAggregators = () => {
    setSelectedAggregator("");
  };

  // checking if the user is a admin or a super admin by url param
  const isSuperAdmin = routeLocation.pathname.includes(
    "/super-admin/manage-aggregators"
  );

  const loadingComponent = (
    <Container>
      <Row className="mt-3">
        <Col sm={12} md={12} lg={6}>
          <AggregationPlaceHolder />
        </Col>
        <Col sm={12} md={12} lg={6}>
          <AggregationPlaceHolder />
        </Col>
      </Row>
    </Container>
  );

  if (selectedAggregator) {
    return (
      <AggregationForm
        aggregatorId={selectedAggregator}
        handleBackToAggregators={handleBackToAggregators}
      />
    );
  }

  return (
  <>
    <AggregatorStateChangeModal
    open={stateChangeAggregator !== null}
    aggregatorId={stateChangeAggregator?.id}
    aggregatorStatus={stateChangeAggregator?.status}
    onClose={() => setStateChangeAggregator(null)}
  />
    <Container className="dashboard-section-container aggregators-section-container">
      <Row>
        <Col sm={12} md={8} lg={8}>
          <div className="dashboard-section-title">Aggregators</div>
          <div className="mt-2 dashboard-section-description">
            Below is a list of all enabled providers. Click on a provider to
            view and edit their details.
          </div>
        </Col>
      </Row>

      {loadingAggregators && loadingComponent}

      {!loadingAggregators && (
        <Row className="mt-3">
          {aggregators?.map((aggregator: IAggregator) => {
            return (
              <Col sm={12} md={12} lg={6}>
                <AggregatorCard
                  cardImage={aggregator?.logo}
                  status={aggregator?.status}
                  aggregatorName={aggregator?.name}
                  handleClickViewAggregator={() =>
                    setSelectedAggregator(aggregator.id)
                  }
                  isSuperAdmin={isSuperAdmin}
                  handleOnStateChange={() =>
                    setStateChangeAggregator(aggregator)
                  }
                />
              </Col>
            );
          })}
        </Row>
      )}
    </Container>
  </>
  );
}
