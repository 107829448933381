import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { IoMdArrowBack } from "react-icons/io";
import { INetworkProvider } from "../../../types/types";
import {
  getNetworkProvider,
  updateNetworkProviderData,
} from "../../../services/DashboardService";
import { logError } from "../../../utilities/logError";
import { ProviderDetailsEditableField } from "./ProviderDetailsEditableField";
import ButtonComponent from "../../CommonComponents/ButtonComponent";

interface IEditNetworkProvider {
  providerId: string;
  handleBackToProviders: () => void;
}

export const EditNetworkProvider = ({
  providerId,
  handleBackToProviders,
}: IEditNetworkProvider) => {
  const [loadingProviderData, setLoadingProviderData] = useState(false);
  const [operationLoading, setOperatingLoading] = useState(false);
  const [providerData, setProviderData] = useState<INetworkProvider>();
  const [networkOperator, setNetworkOperator] = useState("");
  const [description, setDescription] = useState("");
  const [redeemableMethod, setRedeemableMethod] = useState("");
  const [termsAndConditionUrl, setTermsAndConditionUrl] = useState("");

  useEffect(() => {
    handleFetchAggregatorData();
  }, []);

  const handleFetchAggregatorData = async () => {
    try {
      setLoadingProviderData(true);
      const response: any = await getNetworkProvider(providerId);
      setProviderData(response);
    } catch (error: any) {
      logError(error.message, "Get network provider");
    } finally {
      setLoadingProviderData(false);
    }
  };

  const handleSaveProviderData = async () => {
    try {
      setOperatingLoading(true);
      const response = await updateNetworkProviderData(
        networkOperator,
        description,
        redeemableMethod,
        termsAndConditionUrl
      );
    } catch (error: any) {
      logError(error?.message, "Save network provider data");
    } finally {
      setOperatingLoading(false);
    }
  };

  return (
    <Container className="dashboard-section-container aggregators-section-container">
      <Row>
        <Col>
          <IoMdArrowBack
            className="aggregation-form-back-button"
            size={20}
            onClick={handleBackToProviders}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={12} md={9}>
          <h2 className="aggregator-title">
            Network Provider : {providerData?.name}
          </h2>
          <p className="aggregator-form-section-description">
            Quickly review essential details and update the key information for
            network operators, including their descriptions, redeemable methods,
            and terms and conditions here.
          </p>
        </Col>
      </Row>

      <Row className="aggregator-form-section-container mt-3">
        <Col sm={8}>
          <div>
            <div className="provider-details-form-label">Network Operator</div>
            <div className="mt-2">
              <ProviderDetailsEditableField
                value={networkOperator}
                onChange={setNetworkOperator}
                disabled={operationLoading}
                placeholder="Global Telecom"
                showEditIcon={providerId === "new_network_provider"}
              />
            </div>
          </div>
        </Col>
        <Col sm={8}>
          <div>
            <div className="provider-details-form-label">
              Network Operator Description
            </div>
            <div className="mt-2">
              <ProviderDetailsEditableField
                value={description}
                onChange={setDescription}
                disabled={operationLoading}
                placeholder="Enter a brief description of the network operator"
              />
            </div>
          </div>
        </Col>
        <Col sm={8}>
          <div>
            <div className="provider-details-form-label">Redeemable Method</div>
            <div className="mt-2">
              <ProviderDetailsEditableField
                value={redeemableMethod}
                onChange={setRedeemableMethod}
                disabled={operationLoading}
                placeholder="Describe how the top-up is redeemed"
              />
            </div>
          </div>
        </Col>
        <Col sm={8}>
          <div>
            <div className="provider-details-form-label">
              Terms & Conditions URL
            </div>
            <div className="mt-2">
              <ProviderDetailsEditableField
                value={termsAndConditionUrl}
                onChange={setTermsAndConditionUrl}
                disabled={operationLoading}
                placeholder="Enter the URL for terms and conditions"
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col className="d-flex justify-content-end">
          <ButtonComponent
            text="Save Changes"
            onClick={handleSaveProviderData}
            variant="primary"
            customStyling="save-changes-button align-horizontal-center"
            isDisabled={operationLoading}
          />
        </Col>
      </Row>
    </Container>
  );
};
