import React from "react";
import { INetworkProvider } from "../../utilities/ModelTypes";

interface NetworkProviderDetailProps {
  networkProvider: INetworkProvider;
}

export const NetworkProviderDetails = ({ networkProvider }: NetworkProviderDetailProps) => {
  return (
    <div className="section-provider">
      <div className="img-provider">
        <img src={networkProvider.logoURL} alt="network provider" />
      </div>
      <div className="title-provider">About {networkProvider.networkProviderName}</div>
      <div className="dec">
        <p> {networkProvider.redeemableText}</p>
      </div>
      <div className="link-terms">
        <a href={networkProvider.termsAndConditionsText} target="_blank">Terms and Conditions</a>
      </div>
    </div>
  );
};
