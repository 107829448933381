import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ResponsiveContainer } from "recharts";
import { fetchRevenueByProviderData } from "../../../services/DashboardService";
import { IResourceCreator } from "../../../types/types";
import RevenueByProviderBarChart from "./BarChart";
import { RevenueByProviderPlaceholder } from "./DashboardPlaceholder";
import { EmptyState } from "./EmptyState";
import { HeaderWithCountrySelector } from "./HeaderWithCountrySelector";

interface BarChartDataInterface {
  provider: string;
  revenue: number;
}

interface RevenueByProviderInterface {
  revenueByProvidersResource: IResourceCreator;
  setIsRevenueByProviderEmpty: any;
}

export const RevenueByProvider = ({
  revenueByProvidersResource,
  setIsRevenueByProviderEmpty,
}: RevenueByProviderInterface) => {
  const [revenueByProvider, setResource] = useState(
    revenueByProvidersResource?.read()?.data?.revenueByProviders
  );
  const [country, setCountry] = useState<string | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  /**
   * useEffect - fetch revenue by provider data after component mounted and when date range got changed
   */
  useEffect(() => {
    if (country === null) return;
    handleFetchTopProductsData();
  }, [country]);

  // if revenue by provider data is not exists then update parent component revenue by provider data empty state
  const revenueByProvidersExists = revenueByProvider?.length > 0;

  if (!revenueByProvidersExists) {
    setIsRevenueByProviderEmpty(true);
  }

  const handleFetchTopProductsData = async () => {
    try {
      setLoadingData(true);
      const response: any = await fetchRevenueByProviderData("", "", "");
      setResource(response?.data?.revenueByProviders);
    } catch (error) {
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Method - handle set selected country in to state
   */
  const handleCountryChange = (country: string) => {
    setCountry(country);
  };

  const barChartData = revenueByProvider?.map(
    (provider: BarChartDataInterface) => ({
      name: provider.provider,
      value: provider.revenue,
    })
  );

  if (loadingData) {
    return RevenueByProviderPlaceholder;
  }

  return (
    <Container className="dashboard-section-container revenue-by-provider-container ">
      {/* section header text along with country filter */}
      <HeaderWithCountrySelector
        headerTitle="Revenue by Provider"
        onCountryChange={handleCountryChange}
      />

      {revenueByProvidersExists && (
        <Row className="mt-3">
          <Col className="revenue-by-provider-container">
            {/* render if only revenue by provider data exists  */}
            {revenueByProvidersExists && (
              <ResponsiveContainer width="100%" height="100%">
                <RevenueByProviderBarChart barChartData={barChartData} />
              </ResponsiveContainer>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};
