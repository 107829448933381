import { RefObject, Suspense, useState } from "react";
import { usePDF } from "react-to-pdf";

import { DASHBOARD_REPORT_FILE_NAME } from "../../../constants/ApplicationData";
import {
  fetchIncomeByCurrencyData,
  fetchRevenueByProviderData,
  fetchSalesData,
  fetchTopProductsData,
} from "../../../services/DashboardService";
import { createResource } from "../../../utilities/createResource";
import { getPreviousMonth } from "../../../utilities/dateUtilities";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import { DashboardPlaceholder } from "./DashboardPlaceholder";
import { EmptyState } from "./EmptyState";
import TotalIncomeByCurrency from "./IncomeByCurrency";
import { OptionsBar } from "./OptionsBar";
import { RevenueByProvider } from "./RevenueByProviderChart";
import { SalesData } from "./SalesData";
import { TopProductsChart } from "./TopProductsChart";
import { IResourceCreator } from "../../../types/types";

interface DateRangeInterface {
  startDate: Date;
  endDate: Date;
}

const Dashboard = () => {
  const [dateRange, setDateRange] = useState<DateRangeInterface>({
    startDate: getPreviousMonth(new Date()),
    endDate: new Date(),
  });
  const [isIncomeByCurrencyEmpty, setIsIncomeByCurrencyEmpty] =
    useState<any>(false);
  const [isSalesDataEmpty, setIsSalesDataEmpty] = useState<any>(false);
  const [isTopProductsEmpty, setIsTopProductsEmpty] = useState<any>(false);
  const [isRevenueByProviderEmpty, setIsRevenueByProviderEmpty] =
    useState<any>(false);

  const {
    toPDF,
    targetRef,
  }: {
    toPDF: () => void;
    targetRef:
      | ((instance: HTMLDivElement | null) => void)
      | RefObject<HTMLDivElement>
      | null
      | undefined;
  } = usePDF({ filename: DASHBOARD_REPORT_FILE_NAME });

  //react suspense data loader references for dashboard sections
  const incomeByCurrenciesResource: IResourceCreator = createResource(
    fetchIncomeByCurrencyData("", "")
  );
  const salesDataResource: IResourceCreator = createResource(
    fetchSalesData("", "")
  );
  const topProductsResource: IResourceCreator = createResource(
    fetchTopProductsData("", "", "")
  );
  const revenueByProvidersResource: IResourceCreator = createResource(
    fetchRevenueByProviderData("", "", "")
  );

  let isDashboardDataEmpty =
    isIncomeByCurrencyEmpty &&
    isSalesDataEmpty &&
    isTopProductsEmpty &&
    isRevenueByProviderEmpty;

  return (
    <Suspense fallback={<DashboardPlaceholder />}>
      {/* dashboard empty state component  */}
      {isDashboardDataEmpty && (
        <EmptyState message="Total income data in various currencies will be displayed here once available. Please adjust your filters or try again later." />
      )}

      {/* dashboard with dashboard data  */}
      {!isDashboardDataEmpty && (
        <DashboardLayout
          targetRef={targetRef}
          optionBar={
            <OptionsBar
              handleSavePdf={toPDF}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          }
          totalIncomeByCurrency={
            <TotalIncomeByCurrency
              incomeByCurrenciesResource={incomeByCurrenciesResource}
              setIsIncomeByCurrencyEmpty={setIsIncomeByCurrencyEmpty}
            />
          }
          salesData={
            <SalesData
              salesDataResource={salesDataResource}
              setIsSalesDataEmpty={setIsSalesDataEmpty}
            />
          }
          topProducts={
            <TopProductsChart
              topProductsResource={topProductsResource}
              setIsTopProductsEmpty={setIsTopProductsEmpty}
            />
          }
          revenueByProvider={
            <RevenueByProvider
              revenueByProvidersResource={revenueByProvidersResource}
              setIsRevenueByProviderEmpty={setIsRevenueByProviderEmpty}
            />
          }
        />
      )}
    </Suspense>
  );
};

export default Dashboard;
