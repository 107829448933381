import { Card, Col, Placeholder } from "react-bootstrap";
import { formatCurrencyValues } from "../../../utilities/formatCurrencyValues";

export interface CurrencyProfitCardInterface {
  currencyType: string;
  profitAmount: string;
}

export default function CurrencyProfitCard({
  currencyType,
  profitAmount,
}: CurrencyProfitCardInterface) {
  // format currency value
  const formattedProfit = formatCurrencyValues(currencyType, profitAmount);

  return (
    <Col className="currency-card" md={12} lg={3}>
      <h4 className="currency-type">{currencyType}</h4>

      <p className="currency-amount">{formattedProfit}</p>
    </Col>
  );
}
