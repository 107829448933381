import React from "react";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import { Card, Col, Container, Placeholder, Row } from "react-bootstrap";
import { HeaderWithCountrySelector } from "./HeaderWithCountrySelector";

const IncomeByCurrencyCardPlaceholder = (
  <Col className="currency-card" md={12} lg={3}>
    <Placeholder as={Card.Title} animation="wave">
      <Placeholder xs={3} />
    </Placeholder>
    <Placeholder as={Card.Text} animation="wave">
      <Placeholder xs={12} />
    </Placeholder>
  </Col>
);

const SalesDataCardPlaceholder = (
  <Col className={`sales-data-card mt-3`} sm={12}>
    <Placeholder
      as={Card}
      animation="wave"
      size="lg"
      style={{ width: "50px", height: "50px", borderRadius: "50%" }}
    >
      <Placeholder xs={12} />
    </Placeholder>
    <Placeholder as={Card.Text} animation="wave">
      <Placeholder xs={12} />
    </Placeholder>
    <Placeholder as={Card.Title} animation="wave">
      <Placeholder xs={3} />
    </Placeholder>
  </Col>
);

export const TopProductsChartPlaceholder = (
  <Container className="dashboard-section-container top-products-chart-container">
    <Row>
      <HeaderWithCountrySelector
        headerTitle="Top 5 Products"
        onCountryChange={() => {}}
      />
    </Row>
    <Row className="mt-3">
      <Col>
        <Placeholder
          as={Card}
          animation="wave"
          size="lg"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        >
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="wave">
          <div className="d-flex mt-3 gap-5">
            <Placeholder xs={6} />
            <Placeholder xs={1} />
          </div>
          <div className="d-flex mt-3 gap-5">
            <Placeholder xs={6} />
            <Placeholder xs={1} />
          </div>
          <div className="d-flex mt-3 gap-5">
            <Placeholder xs={6} />
            <Placeholder xs={1} />
          </div>
          <div className="d-flex mt-3 gap-5">
            <Placeholder xs={6} />
            <Placeholder xs={1} />
          </div>
          <div className="d-flex mt-3 gap-5">
            <Placeholder xs={6} />
            <Placeholder xs={1} />
          </div>
        </Placeholder>
      </Col>
    </Row>
  </Container>
);

export const RevenueByProviderPlaceholder = (
  <Container className="dashboard-section-container revenue-by-provider-container ">
    {/* section header text along with country filter */}
    <HeaderWithCountrySelector
      headerTitle="Revenue by Provider"
      onCountryChange={() => {}}
    />

    <Row className="mt-3">
      <Col>
        <Placeholder
          as={Card}
          animation="wave"
          size="lg"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        >
          <Placeholder xs={12} />
        </Placeholder>
      </Col>
    </Row>
  </Container>
);

export const DashboardPlaceholder = () => {
  return (
    <DashboardLayout
      totalIncomeByCurrency={
        <Row className="dashboard-section-container p-4">
          {IncomeByCurrencyCardPlaceholder}
          {IncomeByCurrencyCardPlaceholder}
          {IncomeByCurrencyCardPlaceholder}
        </Row>
      }
      salesData={
        <Row className="dashboard-section-container p-4">
          {SalesDataCardPlaceholder}
          {SalesDataCardPlaceholder}
          {SalesDataCardPlaceholder}
        </Row>
      }
      topProducts={TopProductsChartPlaceholder}
      revenueByProvider={RevenueByProviderPlaceholder}
    />
  );
};
