import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { IProduct } from '../../utilities/ModelTypes'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface IServicePackageComponent {
    product: IProduct;
    selectedPackage: (networkPakcage: IProduct) => void
}
type subServiceType = "MOBILE" | "MINUTES" | "DATA" | "LONG_DISTANCE" | "AIRTIME" | "BUNDLES" | "COMBO";

const subServiceStyle: Record<subServiceType, { colour: string, backgroundColour: string }> = {
    MOBILE: {
        colour: "#1D4ED8",
        backgroundColour: "#DBEAFE"
    },
    MINUTES: {
        colour: "#DC2626",
        backgroundColour: "#FECACA"
    },
    DATA: {
        colour: "#6B33A0",
        backgroundColour: "#D9D6F3"
    },
    LONG_DISTANCE: {
        colour: "#DB851E",
        backgroundColour: "#F9EDC7"
    },
    AIRTIME: {
        colour: "#047857",
        backgroundColour: "#D1FAE5"
    },
    BUNDLES: {
        colour: "#78350F",
        backgroundColour: "#FFEDD5"
    },
    COMBO: {
        colour: "#166534",
        backgroundColour: "#ECFCCB"
    }
}

export default function ProductComponent({ product, selectedPackage }: IServicePackageComponent) {
    const packageOrder = useSelector((state: RootState) => state.packageOrder);
    const [selected, setSelected] = useState<string>("");
    
    useEffect(() => {
        if(packageOrder.product?.id == product.id){
            setSelected("selected")
        } else {
            setSelected("")
        }
    },[packageOrder]);
    
    return (
        <Card className={`data-package ${selected}`}onClick={() => selectedPackage(product)}>
            <Card.Body>
                {product.subServices &&
                    product.subServices.map((service: string, index: number) => {
                        const colour = subServiceStyle[service as subServiceType]?.colour || "#374151";
                        const backgroundColour = subServiceStyle[service as subServiceType]?.backgroundColour || "#F3F4F6";
                        return (
                            <span className="package-label" key={index} style={{ color: colour, backgroundColor: backgroundColour }}>{service}</span>
                        )
                    })
                }
                <Card.Title>{product.packageName}</Card.Title>
                <Card.Subtitle>{product.packageDescription}</Card.Subtitle>
                <Card.Text>{product.packageValue} <span>{product.packageCurrency}</span></Card.Text>
            </Card.Body>
        </Card>
    )
}
