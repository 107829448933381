import React from 'react'
import { useParams } from 'react-router-dom'
import Dashboard from '../../components/AdminComponents/DashboardComponents/Dashboard';
import ManageUsers from '../../components/AdminComponents/AdminUserManagement/ManageUsers';
import ManageContent from '../../components/AdminComponents/ContentManagement/ManageContent';
import ManageAggregators from '../../components/AdminComponents/AggregationManagement/ManageAggregators';
import ManageNetworkProviders from '../../components/AdminComponents/NetworkProviderManagement/ManageNetworkProviders';

export default function ManagementPage() {
    const params = useParams<{ managementType: string }>();

    const managementType = params.managementType;

    return (
        <div>
            {managementType == 'dashboard' && <Dashboard /> }
            {managementType == 'manage-users' && <ManageUsers/>}
            {managementType == 'manage-aggregators' && <ManageAggregators/>}
            {managementType == 'manage-content' && <ManageContent/>}
            {managementType == 'manage-network-providers' && <ManageNetworkProviders/>}
        </div>
    )
}
