import React from "react";
import { Button } from "react-bootstrap";

interface ButtonComponentProps {
  text: string;
  src?: string;
  onClick?: (e:React.MouseEvent) => void;
  variant: "primary" | "secondary";
  isDisabled?: boolean;
  customStyling?: string;
  startIcon?: string;
  type?: "submit" | undefined;
  children?: React.ReactNode;
}

export default function ButtonComponent({
  text,
  src,
  onClick,
  variant,
  isDisabled = false,
  customStyling = "",
  startIcon,
  type = undefined,
  children,
}: ButtonComponentProps) {
  return (
    <Button
      onClick={onClick}
      disabled={isDisabled}
      className={customStyling}
      variant={variant}
      type={type}
    >
      {startIcon && <img src={startIcon} alt={`${text}-icon`} />}
      {children || text}
      {src && (
        <img src={src} alt={`${text}-icon`} style={{ marginLeft: "8px" }} />
      )}{" "}
    </Button>
  );
}
