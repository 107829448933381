import React from "react";

type HeroBannerProps = {
  image: string;
  title: string;
  subtitle?: string | null;
  description: string;
};

export default function HeroBanner({
  image,
  title,
  subtitle,
  description,
}: HeroBannerProps) {
  return (
    <div className="hero-banner" style={{ backgroundImage: `url(${image})` }}>
      <div className="hero-content w-100 m-auto">
        <div className="container">
          <h1 className="hero-title">{title}</h1>
          <h2 className="hero-subtitle">{subtitle}</h2>
          <p className="hero-description">{description}</p>
        </div>
      </div>
    </div>
  );
}
