import { CurrencyTypeTypes } from "../types/types";

/**
 *  Admin dashboard other sections icons paths in public folder
 */
export const ADMIN_DASHBOARD_ICONS = {
  "MONEY-BAG-ICON": "/icons/dashboard/card-transaction-icon.svg",
  "SUNGLASS-FACE-ICON": "/icons/dashboard/product-box-icon.svg",
  "EARTH-ICON": "/icons/dashboard/destination-map-icon.svg",
};

/**
 *  Currency type symbols
 */
export const CURRENCY_SYMBOLS: CurrencyTypeTypes = {
  USD: "$",
  EUR: "€ ",
  GBP: "€ ",
};

/**
 *  Admin dashboard top 5 products pie chart color codes
 */
export const PIE_CHART_COLORS: string[] = [
  "#5570F1",
  "#96CFD2",
  "#FEB052",
  "#FFCC91",
  "#97A5EB",
];

/**
 *  Admin dashboard revenue by providers bar chart bar color codes
 */
export const BAR_CHART_COLORS: string[] = [
  "#9575CD",
  "#5C6BC0",
  "#64B5F6",
  "#8C9EFF",
  "#97A5EB",
];

/**
 *  Admin dashboard report file name
 */
export const DASHBOARD_REPORT_FILE_NAME: string = "Nation Blend Report";

/**
 *  Admin dashboard empty state image file name
 */
export const DASHBOARD_EMPTY_STATE_IMAGE: string = "/icons/dashboard/empty-state-icon.svg";
