import React from "react";
import { IoIosClose } from "react-icons/io";
import { MdOutlineDone } from "react-icons/md";

interface IPasswordCondition {
  condition: string;
  isConditionMet: boolean | null;
}

export const PasswordCondition = ({
  condition,
  isConditionMet,
}: IPasswordCondition) => {
  return (
    <div className="d-flex gap-2">
      {isConditionMet === true && (
        <div className="password-condition-indicator-container password-condition-pass">
          <MdOutlineDone />
        </div>
      )}

      {isConditionMet === false && (
        <div className="password-condition-indicator-container password-condition-fail">
          <IoIosClose />
        </div>
      )}

      {isConditionMet === null && (
        <div className="password-condition-indicator-container password-condition-not-touched"></div>
      )}

      <div>{condition}</div>
    </div>
  );
};
