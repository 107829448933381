import { E164Number } from "libphonenumber-js/types.cjs";
import {
    FieldError,
    FieldValues,
    Path,
    UseFormRegister,
} from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ErrorMsgDisplayComponent from "./ErrorMsgDisplayComponent";

interface PhoneNumberInputProps<T extends FieldValues> {
  name: Path<T>;
  register: UseFormRegister<T>;
  errors?: FieldError;
  setValue: (name: Path<T>, value: any) => void;
  placeholder?: string;
}

export default function PhoneNumberInput<T extends FieldValues>({
  name,
  register,
  errors,
  setValue,
  placeholder = "Enter number",
}: PhoneNumberInputProps<T>) {

  const handleChange = (value: E164Number | undefined) => {
    setValue(name, value?.toString());
  };

  return (
    <>
      <PhoneInput
        placeholder={placeholder}
        {...register(name)}
        onChange={handleChange}
      />
      {errors && errors.message && (
        <ErrorMsgDisplayComponent message={errors.message} />
      )}
    </>
  );
}
