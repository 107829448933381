import React, { RefObject } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { OptionsBar } from "../AdminComponents/DashboardComponents/OptionsBar";
import { SalesData } from "../AdminComponents/DashboardComponents/SalesData";

interface IDashboardLayout {
  targetRef?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
  totalIncomeByCurrency: React.ReactNode;
  salesData: React.ReactNode;
  topProducts: React.ReactNode;
  revenueByProvider: React.ReactNode;
  optionBar?: React.ReactNode | null;
}
export const DashboardLayout = ({
  targetRef = null,
  totalIncomeByCurrency,
  salesData,
  topProducts,
  revenueByProvider,
  optionBar,
}: IDashboardLayout) => {
  return (
    <div>
      <Container className="main-content">
        <Row className="mt-3">
          <Col>
            {optionBar || (
              <OptionsBar
                handleSavePdf={() => {}}
                dateRange={{ startDate: new Date(), endDate: new Date() }}
                setDateRange={() => {}}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Container ref={targetRef}>
            <Row className="mt-3">
              <Col>
                <div className="dashboard-section-container total-income-container ">
                  {totalIncomeByCurrency}
                </div>
              </Col>
            </Row>

            <Row className="mt-3 sales-and-top-products-section-container">
              <Col sm={12} md={12} lg={6}>
                <Container className="dashboard-section-container sales-data-container">
                  {salesData}
                </Container>
              </Col>

              <Col sm={12} md={12} lg={6}>
                {topProducts}
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={12}>{revenueByProvider}</Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </div>
  );
};
