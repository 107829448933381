import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import BackButton from '../components/CommonComponents/BackButton'
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import ReactCountryFlag from 'react-country-flag';

export default function OrderConfirmationPage() {
    const packageOrder = useSelector((state: RootState) => state.packageOrder);

    return (
        <div className="page-confirmation">
            <Container>
                <BackButton />
                <div className="section-title">
                    <h2 className="title-2">Confirm your package</h2>
                    <p>
                        Please review and verify your current selected package details.
                    </p>
                </div>
                <Row>
                    <Col md={6} >
                        <div className="card section-card-container">
                            <div className="card-title">
                                Receiver's Details
                            </div>
                            <div className='divider-line'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="513" height="2" viewBox="0 0 513 2" fill="none">
                                    <path d="M0 1H513" stroke="#D4D4D8" />
                                </svg>
                            </div>

                            {packageOrder.mobileNumber &&
                                <>
                                    <p className="field-txt"> Phone Number</p>
                                    <div className='mobile-txt'>
                                        {packageOrder.country !== null &&
                                            <ReactCountryFlag style={{ width: "2em", height: '1.5em' }} countryCode={packageOrder.country} svg />
                                        }
                                        <span>{packageOrder.mobileNumber}</span>
                                    </div>
                                </>
                            }
                            <Form>
                                <Form.Group
                                    className="form-group"
                                    controlId="orderConfirmation.emailAddress"
                                >
                                    <Form.Label>Your Email </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="name@example.come"
                                    />
                                    {/* {errors.email && errors.email.message && (
                                        <ErrorMsgDisplayComponent message={errors.email.message} />
                                    )} */}
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col md={6} >
                        <div className="card section-card-container">
                            <div className="card-title">
                                Order Details
                            </div>
                            <div className='divider-line'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="513" height="2" viewBox="0 0 513 2" fill="none">
                                    <path d="M0 1H513" stroke="#D4D4D8" />
                                </svg>
                            </div>
                            <p className="order-txt">This section shows the details of the package you are purchasing, including the cost, any service charges or taxes, and the total amount to be paid. </p>
                            <Row>
                                <Col md={6} ><p className='section-title'>Package</p> </Col>
                                <Col md={6} ><p className='section-data'>{packageOrder.product?.packageName}</p> </Col>
                            </Row>
                            <Row>
                                <Col md={6} ><p className='section-title'>Cost</p> </Col>
                                <Col md={6} ><p className='section-data'>{packageOrder.value} {packageOrder.product?.packageCurrency}</p> </Col>
                            </Row>
                            <div className='divider-line'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="513" height="2" viewBox="0 0 513 2" fill="none">
                                    <path d="M0 1H513" stroke="#D4D4D8" />
                                </svg>
                            </div>
                            <Row>
                                <Col md={6} ><p className='total-txt'>Total</p> </Col>
                                <Col md={6} ><p className='total-value'>{packageOrder.value} {packageOrder.product?.packageCurrency}</p> </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
