import React from 'react'
import { Container } from 'react-bootstrap'
import Logo from "../../assets/logo.png";
import InfoDisplayComponent from '../../components/UserLogin/InfoDisplayComponent';

export default function NonVerifiedPage() {
    return (
        <Container fluid className="signup-container">
            <InfoDisplayComponent title={'Your account is not verified!'} description={`Please verify your account to continue. We've sent a verification email to the address you provided. Please check your inbox to complete the process.`} />
        </Container>
    )
}
