import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ErrorComponent from '../../components/CommonComponents/ErrorComponent';
import Loader from '../../components/CommonComponents/Loader';
import HeroBanner from '../../components/Layout/HeroBanner';
import ServicePageComponent from '../../components/ServiceComponents/ServicePageComponent';
import { getServices } from '../../services/AggregatorService';
import { IService } from '../../utilities/ModelTypes';
import ServiceIdentificationModal from '../../components/ServiceComponents/ServiceIdentificationModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { resetPackage } from '../../redux/packageOrder/packageOrderSlice';


export default function ServicesPage() {
  /**
   * States and methods to get the services
   */
  const [services, setServices] = useState<IService[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  //Get the aggregator services
  useEffect(() => {
    const getAggregatorServices = async () => {
      try {
        const results = await getServices();

        if (!results) return;

        setServices(results);
      } catch (error) {
        setError("Unable to load the services");
      } finally {
        setLoading(false);
      }
    }
    if (services == null) getAggregatorServices();
  });

  /**
   * Handling the service onClick method
   */
  const [showIdentificationModal, setShowIdentificationModal] = useState<boolean>(false);
  const [currentService, setCurrentService] = useState<IService | null>(null);

  const selectService = (service: IService) => {
    setCurrentService(service);
    setShowIdentificationModal(true);
  }

  /**
   * Clearing the service from the store if it prevails. 
   */
  const packageOrder = useSelector((state: RootState) => state.packageOrder);
  const dispatch = useDispatch();

  useEffect(() => {
    if (packageOrder && packageOrder.country !== null) {
      dispatch(resetPackage());
    }
  }, [packageOrder])

  return (
    <div>
      <HeroBanner image={process.env.REACT_APP_DOMAIN_NAME + '/images/Home-hero-banner.png'} title={'Explore Our Mobile Services ; Find the Best Options for Your Needs'} subtitle={'Fast, Easy, and Secure!'} description={'Select your country and explore various services offered by network providers. Choose a provider and continue with your purchase journey.'} />

      <Container className="card section-card-container">
        <div className="section-container section-container-home">
          <>
            <div className="section-title">
              <h2 className="title-2">Choose Your Service</h2>
              <h4 className="title-4">
                Choosing your service type helps us customize your experience,
                whether it’s a quick top-up or a full recharge. Get exactly what
                you need, effortlessly and on the go!
              </h4>
            </div>
          </>
          {loading &&
            <Container className="service-row">
              <div className="card-services">
                <Loader />
              </div>
            </Container>}

          {error && <ErrorComponent errorMessage={error} />}

          <div className="service-row">
            {services &&
              services.map((service: IService, index: number) => (
                <div className="services-cards-row" key={index}>
                  <ServicePageComponent service={service} showModal={() => selectService(service)} />
                </div>
              ))}
            {currentService && showIdentificationModal && <ServiceIdentificationModal show={showIdentificationModal} handleClose={() => setShowIdentificationModal(false)} service={currentService.serviceName} />}
          </div>
        </div>
      </Container>
    </div>
  )
}
