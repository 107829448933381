import React, { useContext, useEffect, useState } from "react";
import LoginHeader from "../UserLogin/LoginHeader";
import { Navbar } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";
import Logo from "../../assets/logo.png";
import { ROUTES } from "../../constants/Routes";
import ReactCountryFlag from "react-country-flag";
import { useLocation } from "react-router-dom";

export default function Header() {
  const routeLocation = useLocation();
  const [hideFlag, setHideFlag] = useState<boolean>(false);

  const { anonymousSignIn } = useContext(AuthContext);

  useEffect(() => {
    anonymousSignIn();

    const isAdmin = routeLocation.pathname.includes("/admin");
    isAdmin ? setHideFlag(true) : setHideFlag(false);
  }, [anonymousSignIn, routeLocation]);

  return (
    <div className="wrap-top-navbar">
      <Navbar className="top-navbar">
        <div className="d-flex w-100 justify-content-between">
          <div className="left-nav">
            {" "}
            <Navbar.Brand href={ROUTES.HOMEPAGE_ROUTE}>
              <img src={Logo} width={190} alt="logo" />
            </Navbar.Brand>
          </div>
          <div className="right-nav">
            {!hideFlag && <Navbar.Text>
              <ReactCountryFlag
                style={{ width: "2em" }}
                countryCode={`US`}
                svg
              />{" "}USD
            </Navbar.Text>}
            <Navbar.Toggle aria-controls="main-navbar-nav" />
            <Navbar.Collapse id="main-navbar-nav" className="login-nav">
              <LoginHeader />
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    </div>
  );
}
