import React from 'react'
import { Container } from 'react-bootstrap'
import { ROUTES } from '../../constants/Routes';
import InfoDisplayComponent from '../../components/UserLogin/InfoDisplayComponent';

export default function AccountVerifiedPage() {
    return (
        <Container fluid className="signup-container">
            <InfoDisplayComponent title={'Account verified!'} description={'Perfect! Your account with NotionBlend was verified successfully. You can now continue with your purchasing, which makes your life easier.'} navigateUser={ROUTES.HOMEPAGE_ROUTE} navText={'Continue'} />
        </Container>
    )
}
