import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

interface SearchComponentProps {
    onSearch: (searchQuery: string) => void
}

export default function SearchComponent({ onSearch }: SearchComponentProps) {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleSearch = (e:React.FormEvent) => {
        e.preventDefault();
        onSearch(searchQuery);
    }
    return (
        <Form className='frm-search' onSubmit={handleSearch}>
            <InputGroup>
                <Form.Control type="text" placeholder="test" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                <InputGroup.Text id="basic-addon1">
                    <FaSearch />
                </InputGroup.Text>
            </InputGroup>
        </Form>
    )
}
