import { zodResolver } from "@hookform/resolvers/zod";
import { Col, Container, Form, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";
import { saveSecurityInfo } from "../../services/UserService";
import { securityInformationSchemeType } from "../../utilities/SchemaTypes";
import { securityInformationSchema } from "../../utilities/ValidationSchemas";
import ButtonComponent from "../CommonComponents/ButtonComponent";
import ErrorMsgDisplayComponent from "../FormComponents/ErrorMsgDisplayComponent";
import { PasswordCondition } from "./SecurityInfomationComponents/PasswordCondition";

export const SecurityInformation = () => {
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<securityInformationSchemeType>({
    resolver: zodResolver(securityInformationSchema),
  });

  const navigate = useNavigate();

  /**
   * Method - submit security info data
   */
  const handleSubmitPersonalInfo: SubmitHandler<
    securityInformationSchemeType
  > = async (data: securityInformationSchemeType) => {
    try {
      const response = await saveSecurityInfo({ ...data, userId: "testid" });

      // const response = await sendOTPFunc(data);
      // console.log("test",response)
    } catch (error) {
      const err = error as Error;
      console.log("ERROR", err);
      setError("root", { message: err.message });
    }
  };

  /**
   * Method - navigate back to home page
   */
  const handleNavigateBack = () => {
    navigate(ROUTES.HOMEPAGE_ROUTE);
  };

  //component controller variables
  const newPassword = watch("newPassword");

  let passwordLengthCondition: null | boolean = null;
  let passwordLawCaseCondition: null | boolean = null;
  let passwordUpperCaseCondition: null | boolean = null;
  let passwordNumberCondition: null | boolean = null;
  let passwordSpCharacterCondition: null | boolean = null;
  let passwordStrengthStateClass: string = "";
  let passwordStrengthText: string = "";
  let passwordStrengthStateLabelClass: string = "";

  if (newPassword) {
    passwordLengthCondition = newPassword.length >= 8;
    passwordLawCaseCondition = /[a-z]/.test(newPassword);
    passwordUpperCaseCondition = /[A-Z]/.test(newPassword);
    passwordNumberCondition = /\d/.test(newPassword);
    passwordSpCharacterCondition = /[^a-zA-Z0-9\s]/.test(newPassword);

    if (newPassword.length > 0) {
      passwordStrengthStateClass = "password-strength-state-week-container";
      passwordStrengthStateLabelClass = "password-strength-state-week-label";
      passwordStrengthText = "Weak";
    }

    if (newPassword.length >= 5) {
      passwordStrengthStateClass = "password-strength-state-medium-container";
      passwordStrengthStateLabelClass = "password-strength-state-medium-label";
      passwordStrengthText = "Medium";
    }

    if (newPassword?.length >= 8) {
      passwordStrengthStateClass = "password-strength-state-good-container";
      passwordStrengthStateLabelClass = "password-strength-state-good-label";
      passwordStrengthText = "Good";
    }

    if (newPassword?.length >= 10) {
      passwordStrengthStateClass = "password-strength-state-strong-container";
      passwordStrengthStateLabelClass = "password-strength-state-strong-label";
      passwordStrengthText = "Strong";
    }
  }

  return (
    <Container className="dashboard-section-container aggregators-section-container">
      <Row>
        <Col>
          <IoMdArrowBack
            className="aggregation-form-back-button"
            size={20}
            onClick={handleNavigateBack}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm={12} md={9}>
          <h2 className="edit-profile-title">Security Settings</h2>
          <p className="edit-profile-description">
            View and manage your Security Settings. Keep your account up-to-date
            and secure.
          </p>
        </Col>
      </Row>

      <Form onSubmit={handleSubmit(handleSubmitPersonalInfo)}>
        <Row className="aggregator-form-section-container">
          <Col sm={12} md={9}>
            <h2 className="field-container-title">Security Settings</h2>
            <p className="field-container-description">
              View and manage your Security Settings. Keep your account
              up-to-date and secure.
            </p>
          </Col>

          <Col className="form-field-container col-12">
            <div className="form-group-container">
              <Form.Group controlId="signupForm.firstName">
                <Form.Label className="edit-api-label">
                  Your Current Password{" "}
                </Form.Label>

                <Form.Control
                  {...register("currentPassword")}
                  type="password"
                />

                {errors.currentPassword && errors.currentPassword.message && (
                  <ErrorMsgDisplayComponent
                    message={errors.currentPassword.message}
                  />
                )}
              </Form.Group>
            </div>
          </Col>

          <div className="edit-api-label">Change Password </div>
          <Col sm={12} className="form-field-container">
            <div className="form-group-container">
              <Form.Group>
                <Form.Label className="edit-api-label">New Password</Form.Label>
                <Form.Control {...register("newPassword")} type="password" />

                {errors.newPassword && errors.newPassword.message && (
                  <ErrorMsgDisplayComponent
                    message={errors.newPassword.message}
                  />
                )}

                <div className="d-flex mt-2 mb-2">
                  <div
                    className={`password-strength-indicators ${
                      newPassword?.length > 0 ? passwordStrengthStateClass : ""
                    }`}
                  ></div>
                  <div
                    className={`password-strength-indicators ${
                      newPassword?.length >= 5 ? passwordStrengthStateClass : ""
                    }`}
                  ></div>
                  <div
                    className={`password-strength-indicators ${
                      newPassword?.length >= 8 ? passwordStrengthStateClass : ""
                    }`}
                  ></div>
                  <div
                    className={`password-strength-indicators ${
                      newPassword?.length >= 10
                        ? passwordStrengthStateClass
                        : ""
                    }`}
                  ></div>
                </div>

                <div className="d-flex">
                  <div className="password-strength-label edit-api-label">
                    Password strength
                  </div>
                  <div
                    className={` password-strength-label d-flex justify-content-end edit-api-label ${passwordStrengthStateLabelClass}`}
                  >
                    {passwordStrengthText}
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className="form-group-container">
              <div className="d-flex align-items-center gap-1">
                <Form.Group>
                  <Form.Label className="edit-api-label">
                    Confirm New Password
                  </Form.Label>
                  <Form.Control
                    {...register("confirmPassword")}
                    type="password"
                  />

                  {errors.confirmPassword && errors.confirmPassword.message && (
                    <ErrorMsgDisplayComponent
                      message={errors.confirmPassword.message}
                    />
                  )}
                </Form.Group>
              </div>
            </div>
          </Col>

          <Col sm={9} className="d-flex gap-4">
            <div className="d-flex flex-column gap-3">
              <PasswordCondition
                condition="One lower case character"
                isConditionMet={passwordLawCaseCondition}
              />
              <PasswordCondition
                condition="One upper case character"
                isConditionMet={passwordUpperCaseCondition}
              />
              <PasswordCondition
                condition="One number"
                isConditionMet={passwordNumberCondition}
              />
            </div>
            <div className="d-flex flex-column gap-3">
              <PasswordCondition
                condition="8 Characters or above"
                isConditionMet={passwordLengthCondition}
              />
              <PasswordCondition
                condition="One special character (@,$,%..etc)"
                isConditionMet={passwordSpCharacterCondition}
              />
            </div>
          </Col>

          <Col className="d-flex justify-content-end mt-2 gap-3">
            <ButtonComponent
              text="Cancel"
              onClick={handleNavigateBack}
              variant="secondary"
              customStyling="edit-api-cancel-button align-horizontal-center align-vertical-center"
              isDisabled={isSubmitting}
            />

            <ButtonComponent
              text="Save Changes"
              type="submit"
              variant="primary"
              customStyling="save-changes-button align-horizontal-center align-vertical-center"
              isDisabled={isSubmitting}
            />
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
