import { configureStore } from "@reduxjs/toolkit";
import packageOrderReducer from './packageOrder/packageOrderSlice';
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";

const persistConfig = {
    key: "root",
    storage
}

const persistedReducer = persistReducer(persistConfig, packageOrderReducer);

export const store = configureStore({
    reducer: {
        packageOrder: persistedReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"]
        }
    })
})

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;