import React, { useContext, useState } from "react";
import { ListGroup, Popover, Image, OverlayTrigger } from "react-bootstrap";
import { IUser } from "../../utilities/ModelTypes";
import ButtonComponent from "../CommonComponents/ButtonComponent";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";

interface UserProfileDropdownProps {
  user: IUser;
}

export default function UserProfileDropdown({
  user,
}: UserProfileDropdownProps) {
  const { signOut } = useContext(AuthContext);
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);

  const handleShowPopover = (event: React.MouseEvent<HTMLElement>) => {
    setTarget(event.currentTarget);
    setShowPopover(!showPopover);
  };

  const handleClosePopover = () => {
    setShowPopover(false);
  };

  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  //User signout functionality
  const signout = async () => {
    try {
      const response = await signOut();

      if (!response) setError("Unable to sign out the user");

      //Page refresh
      navigate(0);
    } catch (error) {
      const err = error as Error;
      setError(err.message);
    }
  };

  const popover = (
    <Popover className="">
      <Popover.Header className="popover-header">
        <ButtonComponent
          onClick={() => handleClosePopover()}
          variant="primary"
          isDisabled={false}
          customStyling="btn-profile"
          text=""
        >
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="36" height="36" rx="18" fill="#30A0B7" />
            <path
              d="M24 13.2086L22.7914 12L18 16.7914L13.2086 12L12 13.2086L16.7914 18L12 22.7914L13.2086 24L18 19.2086L22.7914 24L24 22.7914L19.2086 18L24 13.2086Z"
              fill="white"
            />
          </svg>
        </ButtonComponent>{" "}
        <Image
          src={
            process.env.REACT_APP_DOMAIN_NAME +
            "/images/Icons/UserProfileModal.svg"
          }
          alt="Profile"
          roundedCircle
          className="mb-3"
          width={80}
          height={80}
        />
      </Popover.Header>
      <Popover.Body className="popover-body">
        <h5>
          {user.firstName} {user.lastName}
        </h5>
        <p>{user.email}</p>
        <ListGroup>
          <ListGroup.Item action onClick={() => navigate(`${ROUTES.CUSTOMER_INFORMATION}/personal-information`)}> Personal Information </ListGroup.Item>
          <ListGroup.Item action onClick={() => navigate(`${ROUTES.CUSTOMER_INFORMATION}/security-settings`)}> Security Settings </ListGroup.Item>
          <ListGroup.Item action onClick={() => navigate(`${ROUTES.CUSTOMER_INFORMATION}/payment-methods`)}> Payment Methods </ListGroup.Item>
          <ListGroup.Item action onClick={() => navigate(`${ROUTES.CUSTOMER_INFORMATION}/transaction-history`)}>Transaction History</ListGroup.Item>
          <ListGroup.Item action onClick={() => signout()}>
            Log out
          </ListGroup.Item>
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom-end"
      overlay={popover}
      rootClose
      show={showPopover}
    >
      <div
        onClick={handleShowPopover}
        style={{ cursor: "pointer", display: "inline-block", paddingLeft: "25px" }}
      >
        <Image
          src={
            process.env.REACT_APP_DOMAIN_NAME +
            "/images/Icons/UserProfileModal.svg"
          }
          alt="Profile"
          roundedCircle
          className="me-2"
          width={40}
          height={40}
        />
        {user.firstName} {user.lastName}
      </div>
    </OverlayTrigger>
  );
}
