import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap';
import { FaArrowRight } from "react-icons/fa6";
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

interface GoogleButtonComponentProps {
    actionType:string;
    text: string;
    isDisabled: boolean;
}

export default function GoogleButton({ actionType, text, isDisabled }: GoogleButtonComponentProps) {
    const { ssoSignIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const [error, setError] = useState<string>("");

    const googleLogin = async () => {
        try {
            const response = await ssoSignIn(actionType);

            if (!response) setError("Error in Signing in user");

            navigate(-1);

        } catch (error) {
            setError("Error in Signing in users");
        }
    }
    return (
        <>
            <Button onClick={googleLogin} disabled={isDisabled} className='btn-google'>
                <img src={process.env.REACT_APP_DOMAIN_NAME + '/images/Icons/GoogleLogo.svg'} className='google-icon' alt="google-icon"/> {text} <FaArrowRight />
            </Button>
            {error && <p>{error}</p>}
        </>
    )
}
