import React, { useMemo } from "react";
import { Form } from "react-bootstrap";

interface IAggregatorServiceCard {
  status: string;
  serviceName: string;
  id: string;
  handleOnStateChange: (serviceId: string, status: string) => void;
  disabled: boolean;
  isSuperAdmin: boolean;
}

export const AggregatorServiceCard = ({
  serviceName,
  status,
  id,
  handleOnStateChange,
  disabled,
  isSuperAdmin,
}: IAggregatorServiceCard) => {
  //service status card header
  const ServiceState = useMemo(
    () => (
      <div className="d-flex align-items-center gap-1">
        <div
          className={`aggregator-service-status ${
            status === "active" ? "active" : "inactive"
          }`}
        ></div>
        <div className="aggregator-service-status-text">
          {status === "active" ? "Active" : "Inactive"}
        </div>
      </div>
    ),
    [status]
  );

  if (!isSuperAdmin) {
    return (
      <div className="aggregator-service-card">
        <div className="aggregator-service-card-body mt-3">
          <div className="aggregator-service-card-service-name">
            {serviceName}
          </div>
          <div className="aggregator-service-card-switch-container">
            {ServiceState}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="aggregator-service-card">
      {ServiceState}
      <div className="aggregator-service-card-body mt-3">
        <div className="aggregator-service-card-service-name">
          {serviceName}
        </div>
        <div className="aggregator-service-card-switch-container">
          <Form.Check
            type="switch"
            name="ratingCheckbox"
            // className="aggregator-enable-switch"
            checked={status === "active" ? true : false}
            onChange={() =>
              handleOnStateChange(
                id,
                status === "active" ? "inactive" : "active"
              )
            }
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
