import { useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { MdOutlineSaveAlt } from "react-icons/md";
import { CiCalendarDate } from "react-icons/ci";

import { formatDate } from "../../../utilities/dateUtilities";
import DateRangePicker, {
  DateRangeInterface,
} from "../../Common//DateRangePicker";
import ButtonComponent from "../../CommonComponents/ButtonComponent";

interface OptionsBarInterface {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };

  handleSavePdf: () => void;
  setDateRange: ({ startDate, endDate }: DateRangeInterface) => void;
}

export const OptionsBar = ({
  handleSavePdf,
  dateRange,
  setDateRange,
}: OptionsBarInterface) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);

  // formatting start date - end date to display as the date picker button text
  const buttonDateLabel = useMemo(
    () =>
      `${formatDate(dateRange.startDate, "MM.dd.yyyy")} - ${formatDate(
        dateRange.endDate,
        "MM.dd.yyyy"
      )}`,
    [dateRange]
  );

  /**
   * Method - open date picker modal
   */
  const handleOpenDatePicker = () => {
    setOpenDatePicker(true);
  };

  return (
    <Container>
      <Row>
        <Col
          sm={11}
          lg={10}
          className="d-flex align-items-center gap-2 justify-content-end date-range-picker-container"
        >
          <div className="date-text-label">Date:</div>
          <div>
            {/* date picker open button  */}
            <ButtonComponent
              text={buttonDateLabel}
              onClick={handleOpenDatePicker}
              variant="secondary"
              customStyling="select-date-range-button date-text-label"
              startIcon="/icons/dashboard/calender-icon.svg"
            />

            <DateRangePicker
              defaultStartDate={dateRange.startDate}
              defaultEndDate={dateRange.endDate}
              isOpen={openDatePicker}
              onClose={() => setOpenDatePicker(false)}
              onApply={setDateRange}
            />
          </div>
        </Col>

        <Col sm={11} lg={1} className="export-pdf-button">
          <div>
            {/* dashboard report generation button  */}

            <ButtonComponent
              text=" Export as PDF"
              onClick={handleSavePdf}
              variant="secondary"
              customStyling="save-pdf-button"
              src="/icons/dashboard/download-icon.svg"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
