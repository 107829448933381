import React, { useContext, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { signUpSchema } from "../../utilities/ValidationSchemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { signUpFormType } from "../../utilities/SchemaTypes";
import ErrorMsgDisplayComponent from "../../components/FormComponents/ErrorMsgDisplayComponent";
import { AuthContext } from "../../context/AuthContext";
import { ROUTES } from "../../constants/Routes";
import GoogleButton from "../../components/CommonComponents/GoogleButton";
import DividerText from "../../components/CommonComponents/DividerText";
import PasswordStrengthComponent from "../../components/UserLogin/PasswordStrengthComponent";
import BackButton from "../../components/CommonComponents/BackButton";
import TimerDisplayComponent from "../../components/FormComponents/TimerDisplayComponent";
import { resendVerificationEmail } from "../../services/AuthService";
import Logo from "../../assets/logo.png";
import ToastComponent from "../../components/CommonComponents/ToastComponent";
import { FaEye, FaEyeSlash, FaArrowRight } from "react-icons/fa";
import { ActionTypes } from "../../utilities/Enums";

export default function SignUpPage() {
  /**
   * States and methods to handle the form
   */
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<signUpFormType>({
    defaultValues: {
      isMFAEnabled: false,
    },
    resolver: zodResolver(signUpSchema),
  });

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const currentPassword = watch("password");
  const { userNamePWSignIn } = useContext(AuthContext);

  //Function triggered when the form is submitted
  const onSubmit: SubmitHandler<signUpFormType> = async (data: signUpFormType) => {
    try {
      //Clearing the anonyous user token
      localStorage.removeItem("Token");

      const response = await userNamePWSignIn(data);

      if (!response) setError("root", { message: "Unable to sign up user" });

      setIsSubmitted(true);

      setIsTimerComplete(false);
    } catch (error) {
      setIsSubmitted(false);
      setIsTimerComplete(true);
      const err = error as Error;
      setError("root", { message: err.message });
    }
  };

  /**
   * States and methods for the resend emaiil verification functionality
   */
  const [isTimerComplete, setIsTimerComplete] = useState<boolean>(true);

  //function to resend email verificaiton
  const resendEmailVerification: SubmitHandler<signUpFormType> = async (data: signUpFormType) => {
    try {
      setIsTimerComplete(false);

      await resendVerificationEmail(data);
    } catch (error) {
      setIsTimerComplete(true);
      const err = error as Error;
      setError("root", { message: err.message });
    }
  };

  /**
   * State to handle the error toast 
   */
  const [show, setShow] = useState<boolean>(true);

  /**
   * State to handle the show/ hide password
   */
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfPassword, setShowConfPassword] = useState<boolean>(false);

  return (
    <Container fluid className="signup-container">
      <div className="page-signup">
        {!isSubmitted ?
          <div className="form-signup">
            <Row>
              <BackButton />
            </Row>
            <h2 className="title-2"> Sign up</h2>
            <p>
              You are only a few steps away from setting up your account. Go ahead
              and fill up the basic information to sign up.
            </p>
            <GoogleButton actionType={ActionTypes.SIGNUP} text={"Sign up with Google"} isDisabled={isSubmitted} />
            <DividerText text="OR" />
            <Form className="signup" onSubmit={handleSubmit(onSubmit)}>
              <div className="d-lg-flex gap-3">
                <Form.Group
                  className="form-group w-100"
                  controlId="signupForm.firstName"
                >
                  <Form.Label>First Name </Form.Label>
                  <Form.Control
                    {...register("firstName")}
                    type="text"
                    placeholder="John"
                  />
                  {errors.firstName && errors.firstName.message && (
                    <ErrorMsgDisplayComponent
                      message={errors.firstName.message}
                    />
                  )}
                </Form.Group>
                <Form.Group
                  className="form-group w-100"
                  controlId="signupForm.lastName"
                >
                  <Form.Label>Last Name </Form.Label>
                  <Form.Control
                    {...register("lastName")}
                    type="text"
                    placeholder="Doe"
                  />
                  {errors.lastName && errors.lastName.message && (
                    <ErrorMsgDisplayComponent message={errors.lastName.message} />
                  )}
                </Form.Group>
              </div>
              <Form.Group
                className="form-group"
                controlId="signupForm.emailAddress"
              >
                <Form.Label>Your email </Form.Label>
                <Form.Control
                  {...register("email")}
                  type="text"
                  placeholder="name@example.come"
                />
                {errors.email && errors.email.message && (
                  <ErrorMsgDisplayComponent message={errors.email.message} />
                )}
              </Form.Group>
              <Form.Group className="form-group form-group-pword" controlId="signupForm.password">
                <Form.Label>Password </Form.Label>
                <span className="wrap-pword-eye">
                  <Form.Control {...register("password")} type={showPassword ? "text" : "password"} />
                  <span className="pword-eye" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </span>
                {errors.password && errors.password.message && (
                  <ErrorMsgDisplayComponent message={errors.password.message} />
                )}
                {currentPassword && (
                  <PasswordStrengthComponent password={watch("password")} />
                )}
              </Form.Group>
              <Form.Group
                className="form-group form-group-pword "
                controlId="signupForm.confirmPassword"
              >
                <Form.Label>Confirm Password </Form.Label>
                <span className="wrap-pword-eye">
                  <Form.Control {...register("confirmPassword")} type={showConfPassword ? "text" : "password"} />
                  <span className="pword-eye" onClick={() => setShowConfPassword(!showConfPassword)}>
                    {showConfPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </span>
                {errors.confirmPassword && errors.confirmPassword.message && (
                  <ErrorMsgDisplayComponent
                    message={errors.confirmPassword.message}
                  />
                )}
              </Form.Group>
              <Form.Check
                className="multi-fact"
                {...register("isMFAEnabled")}
                type="checkbox"
                label="Enable Multi-Factor Authentication (MFA) via SMS"
              />
              <Button className="w-100" type="submit" disabled={isSubmitting}>
                Sign up
                {" "}
                <FaArrowRight />
              </Button>


              {errors.root && errors.root.message && (
                <ToastComponent message={errors.root.message} show={show} setShow={setShow} toastType='error' />
              )}

              <p className="login-text">
                Already have an account? <a href={ROUTES.LOGIN_PAGE_ROUTE}>Log in</a>{" "}
              </p>
            </Form>
          </div>
          :
          <div className="form-signup">
            <Row>
              <BackButton />
            </Row>
            <h2 className="title-2">Verify Account</h2>
            <p>
              Please verify your account to continue. We've sent a verification email to the address you provided. Please check your inbox to complete the process.
            </p>
            <Button
              className="w-100"
              disabled={!isTimerComplete}
              onClick={handleSubmit(resendEmailVerification)}
            >
              Resend Verification Email
              {" "}
              <FaArrowRight />
            </Button>
            {!isTimerComplete && (
              <TimerDisplayComponent
                timeLimit={2}
                isTimerComplete={setIsTimerComplete}
                timerText="Resend verification email"
              />
            )}
          </div>
        }
        <div className="signup-banner">
          <span>
            <img width="150" className="logo" src={Logo} alt="" />
          </span>
          <img
            src={
              process.env.REACT_APP_DOMAIN_NAME + "/images/Signup-banner.jpg"
            }
            alt="signup_banner"
          />
        </div>
      </div>
    </Container>
  );
}
