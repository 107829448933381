// styles for date range picker modal

const styles = {
  title: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "40px",
    letterSpacing: "0.01em",
    textAlign: "left" as "left", // Explicitly specify the type as 'left'
  },

  label: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "left" as "left",
    minWidth: "100px",
  },

  applyButton: {
    width: "231px",
    height: "37px",
    backgroundColor: "#d2ebf0",
    padding: "8px 12px",
    gap: "8px",
    borderRadius: "64px",
    border: "1px solid var(-selected-state-stroke, #a1cdd6)",
    fontWeight: 400,
    lineHeight: "21px",
    textAlign: "center" as "center",
    color: "#1f1f1f",
  },

  countrySelector: {
    maxWidth: "160.65px",
    height: "30.53px",
    gap: "5.95px",
    borderRadius: "26.05px",
    border: "0.74px 0px 0px 0px",
    opacity: "0px",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left" as "left",
    backgroundImage: `url('/icons/dashboard/filter-icon.png')`,
  },

  aggregationCardBodyPlaceholder: {
    height: "140px",
    background:
      "linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%)",
  },

  aggregationCardButtonPlaceholder: {
    height: "33px",
    marginTop: "10px",
    borderRadius: "25.69px",
    background:
      "linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%)",
  },

  contentCardBodyPlaceholder: {
    height: "100px",
    background:
      "linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%)",
  },

  countrySelectorBlueBackground: {
    background: "var(--Selected-state, #D2EBF0)",
  },
};

export default styles;
