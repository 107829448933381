import { Card, Placeholder } from "react-bootstrap";
import styles from "../../../styles/external-styles";

export const ContentCardPlaceHolder = () => {
  return (
    <Card>
        <Placeholder size="xs" animation="wave">
          <Placeholder xs={12}  size="xs" style={styles.contentCardBodyPlaceholder} />
        </Placeholder>
    </Card>
  );
};
