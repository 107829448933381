import React, { PureComponent } from "react";
import {
  BarChart as BarChartComponent,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
} from "recharts";
import { BAR_CHART_COLORS } from "../../../constants/ApplicationData";
import { getShadesForBars } from "../../../utilities/colorShadeUtill";

interface BarChartDataInterface {
  name: string;
  value: number;
}

interface BarChartInterface {
  barChartData: BarChartDataInterface[] | [];
  loadingData?: boolean;
}

export default function BarChart({
  barChartData = [],
  loadingData,
}: BarChartInterface) {
  const numberOfBars = barChartData?.length; // Dynamically change this based on your data
  const barColors = getShadesForBars(BAR_CHART_COLORS, numberOfBars);

  if (loadingData) {
    const barChartData = [
      { name: "Provider A", value: 2000 },
      { name: "Provider A", value: 2000 },
      { name: "Provider A", value: 2000 },
      { name: "Provider A", value: 2000 },
    ];


    // loading component 
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChartComponent
          data={barChartData}
          layout="vertical"
          width={100}
          margin={{
            top: 5,
            right: 50,
            left: 10,
            bottom: 5,
          }}
        >
          <CartesianGrid />
          <XAxis
            type="number"
            padding={{ left: 10, right: 10 }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            type="category"
            width={80}
            dataKey="name"
            axisLine={false}
            tickLine={false}
            wordSpacing={50}
          />
          {/* <Tooltip /> */}
          <Bar dataKey="value" barSize={42}>
            {barChartData.map((_, index: number) => (
              <Cell key={`cell-${index}`} fill={barColors[index]} radius={4} />
            ))}
          </Bar>
        </BarChartComponent>
      </ResponsiveContainer>
    );
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChartComponent
        data={barChartData}
        layout="vertical"
        width={100}
        margin={{
          top: 5,
          right: 50,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          padding={{ left: 10, right: 10 }}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          type="category"
          width={80}
          dataKey="name"
          axisLine={false}
          tickLine={false}
          wordSpacing={50}
        />
        {/* <Tooltip /> */}
        <Bar dataKey="value" barSize={42}>
          {barChartData.map((_, index: number) => (
            <Cell key={`cell-${index}`} fill={barColors[index]} radius={4} />
          ))}
        </Bar>
      </BarChartComponent>
    </ResponsiveContainer>
  );
}
