import Quill from "quill";
import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";

interface IRichTextEditor {
  defaultValue: any;
  onTextChange: Dispatch<SetStateAction<any>>;
  disabled: boolean;
}

const RichTextEditor = forwardRef(
  ({ defaultValue, onTextChange, disabled }: IRichTextEditor, ref: any) => {
    const containerRef = useRef(null);
    const defaultValueRef: any = useRef(defaultValue);
    const onTextChangeRef: any = useRef(onTextChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
    });

    useEffect(() => {
      if (!containerRef.current) return;

      const container: any = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement("div")
      );
      const quill = new Quill(editorContainer, {
        theme: "snow",
        readOnly: disabled,
      });

      ref.current = quill;

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      return () => {
        ref.current = null;
        container.innerHTML = "";
      };
    }, [ref,disabled]);

    return <div ref={containerRef}></div>;
  }
);

RichTextEditor.displayName = "RichTextEditor";

export default RichTextEditor;
