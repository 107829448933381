import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { PIE_CHART_COLORS } from "../../../constants/ApplicationData";


interface PieChartDataInterface {
  name: string;
  value: number;
}

interface PieChartInterface {
  pieChartData: PieChartDataInterface[];
}

export default function TopProductsPieChart({
  pieChartData,
}: PieChartInterface) {
  return (
    <ResponsiveContainer>
      <PieChart width={800} height={400}>
        <Pie
          data={pieChartData}
          // cx={100}
          cy={120}
          innerRadius={60}
          outerRadius={80}
          dataKey="value"
        >
          {pieChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={PIE_CHART_COLORS[index]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
