import React, { createContext, useEffect, useMemo, useState } from 'react'
import { anonymousSignInFunc, getUserDetails, getUserDetailsFirebaseFunc, resendVerificationEmail, signOutFirebaseFunc, signUpUser, ssoSignInFunc, systemSignOut, userNameLogin, userNameSignUp } from '../services/AuthService';
import { AxiosError } from 'axios';
import { loginFormType, signUpFormType } from '../utilities/SchemaTypes';
import { ILoggedInUserResponse, IUser } from '../utilities/ModelTypes';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ActionTypes } from '../utilities/Enums';

//Interface for the auth context type
export interface AuthContextType {
    ssoSignIn: (actionType: string) => Promise<IUser | null>;
    userNamePWSignIn: (data: signUpFormType) => Promise<boolean>;
    anonymousSignIn: () => void;
    signOut: () => Promise<boolean>;
    userNamePWLogin: (data: loginFormType) => Promise<ILoggedInUserResponse>;
    getCurrentUser: () => void;
    user: IUser | null;
    isLoading: boolean;
    isAnonymous: boolean;
}

//Interface for the react layout type
interface LayoutType {
    children?: React.ReactNode;
}

export const AuthContext = createContext<AuthContextType>({
    ssoSignIn: async (actionType) => null,
    userNamePWSignIn: async (data) => false,
    anonymousSignIn: () => { },
    signOut: async () => false,
    userNamePWLogin: async (data) => { return { status: "isComplete", user: null } },
    getCurrentUser: () => { },
    user: null,
    isLoading: true,
    isAnonymous: false
});

export const AuthContextProvider: React.FC<LayoutType> = ({ children }) => {
    const [user, setUser] = useState<IUser | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
    /**
     * Get the current user from the system and firebase
     * @returns 
     */
    const getCurrentUser = async (): Promise<IUser> => {
        try {
            const user = await getUserDetailsFirebaseFunc();

            if (!user) throw new Error("Unable to get user details");

            if (user != null && !user.isAnonymous) {
                const signedInUser: IUser = await getUserDetails();

                const currentUser: IUser = {
                    firstName: signedInUser.firstName,
                    lastName: signedInUser.lastName,
                    mobileNumber: signedInUser.mobileNumber,
                    email: signedInUser.email,
                    isMFAEnabled: signedInUser.isMFAEnabled,
                    userRoles: signedInUser.userRoles,
                    address: signedInUser.address
                };

                setUser(currentUser)

                return signedInUser;
            } else {
                setIsAnonymous(true);
                setUser(null);
                return user;
            }

        } catch (error) {
            const err = error as AxiosError;
            throw new Error(err.message);
        } finally {
            setIsLoading(false);
        }
    }


    /**
     * Sign up with Google SSO into the system
     * @returns 
     */
    const ssoSignIn = async (actionType: string): Promise<IUser> => {
        try {
            const response = await ssoSignInFunc();

            if (!response) throw new Error("Unable to complete SSO login");

            localStorage.setItem('Token', response.user.accessToken);

            if (actionType == ActionTypes.SIGNUP) {
                const firebaseUser = response.user;

                const user: IUser = {
                    firstName: firebaseUser.displayName.split(" ")[0],
                    lastName: firebaseUser.displayName.split(" ")[1],
                    mobileNumber: firebaseUser.phoneNumber,
                    email: firebaseUser.email,
                    isMFAEnabled: false,
                }

                const signUpResponse = await signUpUser(user)

                if (!signUpResponse) throw new Error("Unable to sign up user");
            }

            const user = await getCurrentUser();

            return user;

        } catch (error) {
            const err = error as AxiosError;
            throw new Error(err.message);
        } finally {
            setIsLoading(false);
        }
    };


    /**
     * Sign up with user name and password into the system
     * @param data 
     * @returns 
     */
    const userNamePWSignIn = async (data: signUpFormType): Promise<boolean> => {
        try {
            const response = await userNameSignUp(data);

            if (!response) throw new Error("Unable to complete user sign up");

            localStorage.setItem('Token', response.user.accessToken);

            return true;

        } catch (error) {
            const err = error as AxiosError;
            throw new Error(err.message);
        } finally {
            setIsLoading(false);
        }
    }


    /**
     * User name password Login functionality
     * @param data 
     * @returns 
     */
    const userNamePWLogin = async (data: loginFormType): Promise<ILoggedInUserResponse> => {
        try {
            const response = await userNameLogin(data);

            if (!response) throw new Error("Unable to login user");

            if (response.user.auth.emailVerified === false) {

                await resendVerificationEmail();

                return { status: "isVerify", user: null };
            }
            localStorage.setItem('Token', response.user.accessToken);

            const isUser = await getCurrentUser();

            if (!isUser) throw new Error("Unable to resend verification email")

            return { status: "isComplete", user: isUser };

        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    }


    /**
     * Anonymous user sign in funciton used to sign in a user anonymously when they visit the site, this is to keep the API end points always protected with a token.
     * @returns 
     */
    const anonymousSignIn = async () => {
        const currentToken = localStorage.getItem('Token');

        try {
            //Preventing the token generation if a token is prevailing
            if (currentToken) {
                return;
            }

            const response = await anonymousSignInFunc();

            if (!response) throw new Error("Unable to complete anonymous sign-in");

            setUser(null);

            localStorage.setItem('Token', response.accessToken);
        } catch (error) {
            const err = error as AxiosError;
            throw new Error(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Sign out user form the system and firebase
     * @returns 
     */
    const signOut = async (): Promise<boolean> => {
        try {
            const response = await signOutFirebaseFunc();

            const systemResponse = await systemSignOut();

            if (!response) throw new Error("Unable to signout user");

            localStorage.removeItem('Token');

            await anonymousSignIn();

            return true;
        } catch (error) {
            const err = error as AxiosError;
            throw new Error(err.message);
        }
    }

    const contextValue: AuthContextType = useMemo(() => {
        return {
            ssoSignIn,
            userNamePWSignIn,
            anonymousSignIn,
            signOut,
            userNamePWLogin,
            getCurrentUser,
            user,
            isLoading,
            isAnonymous
        }
    }, [ssoSignIn, userNamePWSignIn, anonymousSignIn, signOut, getCurrentUser, user, isLoading, isAnonymous]);


    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}