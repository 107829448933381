import { Suspense, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { PIE_CHART_COLORS } from "../../../constants/ApplicationData";
import { fetchTopProductsData } from "../../../services/DashboardService";
import { TopProductsChartPlaceholder } from "./DashboardPlaceholder";
import { EmptyState } from "./EmptyState";
import { HeaderWithCountrySelector } from "./HeaderWithCountrySelector";
import TopProductsPieChart from "./PieChart";
import { TopProductInfo } from "./TopProductInfo";
import { IResourceCreator } from "../../../types/types";

interface TopProductDetailsInterface {
  product: string;
  numberOfSales: number;
}

interface TopProductsChartInterface {
  topProductsResource: IResourceCreator;
  setIsTopProductsEmpty: any;
}

export const TopProductsChart = ({
  topProductsResource,
  setIsTopProductsEmpty,
}: TopProductsChartInterface) => {
  const [topProducts, setResource] = useState(
    topProductsResource?.read()?.data?.topProducts
  );
  const [country, setCountry] = useState<string | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  /**
   * useEffect - fetch top products data after component mounted and when date range got changed
   */
  useEffect(() => {
    if (country === null) return;
    handleFetchTopProductsData();
  }, [country]);

  const topProductDataExists = topProducts?.length > 0;

  // if top products data is not exists then update parent component top products data empty state
  if (!topProductDataExists) {
    setIsTopProductsEmpty(true);
  }

  const handleFetchTopProductsData = async () => {
    try {
      setLoadingData(true);
      const response: any = await fetchTopProductsData("", "", "");
      setResource(response?.data?.topProducts);
    } catch (error) {
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Method - handle set selected country in to state
   */
  const handleCountryChange = (country: string) => {
    setCountry(country);
  };

  const pieChartData = topProducts?.map(
    (product: TopProductDetailsInterface) => ({
      name: product.product,
      value: product.numberOfSales,
    })
  );

  if (loadingData) {
    return TopProductsChartPlaceholder;
  }

  return (
    <Suspense fallback={<>loading chart</>}>
      <Container className="dashboard-section-container top-products-chart-container">
        {/* section header text along with country filter */}
        <HeaderWithCountrySelector
          headerTitle="Top 5 Products"
          onCountryChange={handleCountryChange}
        />

        {topProductDataExists && (
          <Row>
            <Col className="top-products-pie-chart-container">
              <TopProductsPieChart pieChartData={pieChartData} />
            </Col>
          </Row>
        )}

        {/* render if only top products data exists */}
        {topProductDataExists && (
          <Row>
            <>
              {topProducts?.map(
                (product: TopProductDetailsInterface, index: number) => {
                  return (
                    <Row className="mt-4" key={product.product}>
                      <TopProductInfo
                        product={{
                          ...product,
                          color: PIE_CHART_COLORS[index],
                        }}
                      />
                    </Row>
                  );
                }
              )}
            </>
          </Row>
        )}
      </Container>
    </Suspense>
  );
};
