import React, { useState } from "react";
import { IPromotion } from "../../utilities/ModelTypes";
import { Card } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";
import Arrow from "../../assets/arrow_forward.svg";
import ButtonComponent from "../CommonComponents/ButtonComponent";

interface PromotionComponentProps {
  promotion: IPromotion;
}

export default function PromotionComponent({
  promotion,
}: PromotionComponentProps) {
  const navigate = useNavigate(); // Moved to top of the component
  const [isSelected, setIsSelected] = useState(false);

  const toggleSelection = () => {
    setIsSelected(!isSelected);
  };

  const handleButtonClick = () => {
    navigate(`${ROUTES.PRMOMOTION_PACKAGES_PAGE}?id=${promotion.promotionId}`);
  };

  return (
    <Card
      className={`promotion-card ${isSelected ? "selected-card" : ""}`}
      onClick={toggleSelection} // Toggle selected state on click
    >
      <Card.Body className="card-details">
        <div className="country-row">
          <ReactCountryFlag
            style={{ width: "2em" }}
            countryCode={`${promotion.countryISO}`}
            svg
          />{" "}
          <span className="country-txt">{promotion.countryName}</span>
        </div>
        <div>
          <Card.Title className="title-promotion">{promotion.name}</Card.Title>
          <Card.Text className="dec-promotion">
            {promotion.description}
          </Card.Text>

          <div className="group-pill">
            <Card.Text className="txt-pill">from June 1 to Aug 1</Card.Text>
            <Card.Text className="txt-pill">
              Network Provider: {promotion.networkProvider}
              {promotion.networkProviderImg}
            </Card.Text>
          </div>

          <hr />
          <div className="promotion-card-footer">
            <Card.Text className="txt-available">
              3 products are available
            </Card.Text>
            <ButtonComponent
              text="View More & Buy Now"
              onClick={handleButtonClick}
              variant="primary"
              isDisabled={false}
              src={Arrow}
              customStyling="w-100 btn-card-promo"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
