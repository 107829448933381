import React, { useRef, useState } from "react";
import ModalComponent from "../../Common/ModalComponent";
import { Button, Col, Container, Row } from "react-bootstrap";
import RichTextEditor from "../../Common/RichTextEditor";
import {
  IContactUsData,
  IContentData,
  IDataSaveMethods,
} from "../../../types/types";
import { updateContactUs } from "../../../services/DashboardService";
import { logError } from "../../../utilities/logError";
import ButtonComponent from "../../CommonComponents/ButtonComponent";

interface IEditContactUsModal {
  open: boolean;
  onClose: () => void;
  defaultValue: IContactUsData | undefined;
}

export const EditContactUsModal = ({
  open,
  onClose,
  defaultValue,
}: IEditContactUsModal) => {
  const [contactData, setContactData] = useState();
  const [emailData, setEmailData] = useState();
  const [addressData, setAddressData] = useState();
  const [submittingData, setSubmittingData] = useState(false);

  const callQuillRef = useRef<any>();
  const emailQuillRef = useRef<any>();
  const addressQuillRef = useRef<any>();

  /**
   * Method - trigger appropriate data save api call for each type of content
   */
  const handleSaveContactData = async () => {
    try {
      setSubmittingData(true);
      const response = await updateContactUs(
        callQuillRef.current.getContents().ops,
        emailQuillRef.current.getContents().ops,
        addressQuillRef.current.getContents().ops
      );
    } catch (error: any) {
      logError(error?.message, "submitting contact us data");
    } finally {
      setSubmittingData(false);
    }
  };

  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      title={""}
      className={""}
      disableCloseButton={submittingData}
    >
      <Container>
        <Row className="gap-2">
          <Col className="col-12">Call Us</Col>
          <Col>
            <RichTextEditor
              ref={callQuillRef}
              defaultValue={defaultValue?.call}
              onTextChange={setContactData}
              disabled={submittingData}
            />
          </Col>
        </Row>
        <Row className="mt-5 gap-2">
          <Col className="col-12">Email Us</Col>
          <Col>
            <RichTextEditor
              ref={emailQuillRef}
              defaultValue={defaultValue?.email}
              onTextChange={setEmailData}
              disabled={submittingData}
            />
          </Col>
        </Row>
        <Row className="mt-5 gap-2">
          <Col className="col-12">Visit Us</Col>
          <Col>
            <RichTextEditor
              ref={addressQuillRef}
              defaultValue={defaultValue?.address}
              onTextChange={setAddressData}
              disabled={submittingData}
            />
          </Col>
        </Row>
        <Row className="mt-5 gap-2">
          <Col className="d-flex justify-content-end">
            <ButtonComponent
              text="Save Details"
              onClick={handleSaveContactData}
              variant="primary"
              isDisabled={submittingData}
            />
          </Col>
        </Row>
      </Container>
    </ModalComponent>
  );
};
