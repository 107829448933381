import React from 'react'
import { Button, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Logo from "../../assets/logo.png";
import BackButton from '../CommonComponents/BackButton';

interface InfoDisplayComponentProps {
    title: string;
    description: string;
    navigateUser?: string;
    navText?: string;
}

export default function InfoDisplayComponent({ title, description, navigateUser, navText }: InfoDisplayComponentProps) {
    const navigate = useNavigate();

    return (
        <div className="page-signup">
            <div className="form-signup">
                <Row>
                    <BackButton />
                </Row>
                <h2 className="title-2"> {title}</h2>
                <p>
                    {description}
                </p>
                {navText && navigateUser && <Button className="w-100 verification-btn" onClick={() => navigate(navigateUser)}>{navText}</Button>}
            </div>
            <div className="signup-banner login-banner">
                <span>
                    <img width="150" className="logo" src={Logo} alt="" />
                </span>
                <img
                    src={
                        process.env.REACT_APP_DOMAIN_NAME + "/images/Signup-banner.jpg"
                    }
                    alt="signup_banner"
                />
            </div>
        </div>
    )
}
