import { useState } from "react"

interface PagenationProps {
    totalItems: number;
    itemsPerPage: number;
    currentPage?: number;
}

export const usePagination = ({ totalItems, itemsPerPage, currentPage = 1 }: PagenationProps) => {
    const [activePage, setActivePage] = useState<number>(currentPage);

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const goToPage = (page: number) => {
        if (page > 0 && page <= totalPages) {
            setActivePage(page);
        }
    };

    return { activePage, totalPages, goToPage };
}