import { Card, Placeholder } from "react-bootstrap";
import styles from "../../../styles/external-styles";

export const AggregationPlaceHolder = () => {
  return (
    <Card>
      <Card.Body>
        <Placeholder size="lg" animation="wave">
          <Placeholder
            xs={12}
            style={styles.aggregationCardBodyPlaceholder}
          />
        </Placeholder>
        <Placeholder
          xs={12}
          style={styles.aggregationCardButtonPlaceholder}
        />
      </Card.Body>
    </Card>
  );
};
