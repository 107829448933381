import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { getTermsCondition } from '../../services/EnterpriseService';

export default function TermsConditionPage() {
  const [conditionData, setConditionData] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0)
    const getData = async () => {
      try {
        const response = await getTermsCondition();

        if (!response) return;

        setConditionData(response);
      } catch {
        setError("Unable to obtain enterprise data");
      } finally {
        setLoading(false)
      }
    }

    if (conditionData == null) getData();
  },[conditionData])


  return (
    <>
      {conditionData !== null && !loading &&
        <div className="transparent-section-card-container">
          <Container>
            <div className="section-title">
              <h2 className="title-2">Terms and conditions</h2>
            </div>
          </Container>
          <div className="dynamic-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(conditionData) }} />
        </div>
      }
    </>
  )
}
