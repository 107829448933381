import React from "react";
import { Form, Row, Col, ProgressBar } from "react-bootstrap";
import { BsCircle, BsCheckCircle } from "react-icons/bs";

interface PasswordStrengthProps {
  password: string;
}

export default function PasswordStrengthComponent({
  password,
}: PasswordStrengthProps) {
  // Password validation checks
  const validations = {
    hasLowerCase: /[a-z]/.test(password),
    hasUpperCase: /[A-Z]/.test(password),
    hasNumber: /\d/.test(password),
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    isLongEnough: password.length >= 8,
  };

  // Calculate strength
  const strength = Object.values(validations).filter(Boolean).length;

  // Strength level and text
  const strengthText =
    strength < 2 ? "Weak" : strength < 4 ? "Medium" : "Strong";
  const strengthColor =
    strength < 2 ? "danger" : strength < 4 ? "warning" : "success";

  // Helper to render validation item
  const renderValidationItem = (isValid: boolean, label: string) => (
    <div className="mb-1">
      {isValid ? (
        <BsCheckCircle className="text-success me-2" />
      ) : (
        <BsCircle className="text-muted me-2" />
      )}
      <span className={isValid ? "text-success" : "text-muted"}>{label}</span>
    </div>
  );

  return (
    <>
      <Form.Group>
        {/* <Form.Label>Password</Form.Label> */}
        <ProgressBar
          now={(strength / 5) * 100}
          variant={strengthColor}
          className="mt-2"
        />
        <div className="text-end text-muted mt-1">
          Password strength:{" "}
          <strong className={`text-${strengthColor}`}>{strengthText}</strong>
        </div>
      </Form.Group>
      <div className="chk-pwrd">
        <Row>
          <Col xl={6} lg={12}>
            {renderValidationItem(
              validations.hasLowerCase,
              "One lower case character"
            )}
            {renderValidationItem(
              validations.hasUpperCase,
              "One upper case character"
            )}
            {renderValidationItem(validations.hasNumber, "One number")}
          </Col>
          <Col xl={6} lg={12}>
            {renderValidationItem(
              validations.isLongEnough,
              "8 Characters or above"
            )}
            {renderValidationItem(
              validations.hasSpecialChar,
              "One special character (@,$,%, etc.)"
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
