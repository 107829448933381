import React from "react";
import { DASHBOARD_EMPTY_STATE_IMAGE } from "../../../constants/ApplicationData";
import { Col, Container, Row } from "react-bootstrap";

interface EmptyStateInterface {
  message: string;
}

export const EmptyState = ({ message }: EmptyStateInterface) => {
  return (
    <Container className="dashboard-section-container empty-state-container">
      <Row>
        <Col sm={12} className="d-flex justify-content-center">
          <img
            src={DASHBOARD_EMPTY_STATE_IMAGE}
            alt="Dashboard empty state image"
          />
        </Col>
        <Col sm={12} className="text-center mt-3 empty-state-text">
          {message}
        </Col>
      </Row>
    </Container>
  );
};
