import React, { useState } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { boolean } from 'zod';
import { AiOutlineClose, AiOutlineExclamationCircle } from "react-icons/ai";

type ToastType = "success" | "error" | "warning" | "information";
interface ToastComponentProps {
    message: string;
    show: boolean;
    setShow: (value: boolean) => void;
    toastType: ToastType;
}

const toastStyles: Record<ToastType, { className: string, color: string | null, svgPath: string | null }> = {
    success: {
        className: "custom-toast success-toast",
        color: "#62B774",
        svgPath: "M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
    },
    error: {
        className: "custom-toast error-toast",
        color: "#EF4444",
        svgPath: "M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
    },
    warning: {
        className: "custom-toast warning-toast",
        color: "#EEA92E",
        svgPath: "M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
    },
    information: {
        className: "custom-toast info-toast",
        color: null,
        svgPath: null
    },
};

export default function ToastComponent({ message, show, setShow, toastType }: ToastComponentProps) {

    const { className, color, svgPath } = toastStyles[toastType as keyof typeof toastStyles];
    return (
        <ToastContainer position="top-center" className="p-3 custom-toast-container" style={{ zIndex: 1 }}>
            {/* <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide  className="custom-toast"> */}
            <Toast show={show} className={className}>
                <Toast.Body>
                    <div className="toast-content">
                        {svgPath && color && <svg xmlns="http://www.w3.org/2000/svg" width="4" height="52" viewBox="0 0 4 52" fill="none">
                            <path d={svgPath} stroke={color} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>}
                        <AiOutlineExclamationCircle className="icon" />
                        <div className="text-content">
                            <strong>Error!</strong>
                            {message}
                        </div>
                        <button className="close-button" onClick={() => setShow(false)}>
                            <AiOutlineClose />
                        </button>
                    </div>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}
