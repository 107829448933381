import React, { useEffect, useState } from "react";
import BackButton from "../../components/CommonComponents/BackButton";
import { Col, Container, Row } from "react-bootstrap";
import { IPromotion, IPromotionPackage } from "../../utilities/ModelTypes";
import { getPromotion } from "../../services/AggregatorService";
import ReactCountryFlag from "react-country-flag";
import Loader from "../../components/CommonComponents/Loader";
import ErrorComponent from "../../components/CommonComponents/ErrorComponent";
import PackageComponent from "../../components/PromotionComponents/PackageComponent";

export default function PackagesPage() {
  const [promotion, setPromotion] = useState<IPromotion | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  //Get the aggregator promotions
  useEffect(() => {
    const getAggregatorPromotions = async () => {
      try {
        const results = await getPromotion();

        console.log("Results", results);

        if (!results) return;

        setPromotion(results);
      } catch (error) {
        setError("Unable to load the promotions");
      } finally {
        setLoading(false);
      }
    };

    if (promotion == null) getAggregatorPromotions();
  });

  return (
    <div className="page-package">
      <Container>
        <Row>
          <BackButton />
        </Row>
        <Row className="page-package-section-top">
          <h2 className="title-2 text-center">
            {" "}
            Choose Your Perfect Promotional Package!
          </h2>
          <p className="text-center">
            Discover amazing deals on top-up packages that give you more value
            for your money. Select the perfect promotion to boost your mobile
            balance and enjoy extra benefits.
          </p>
        </Row>
      </Container>
      {loading && <Loader />}
      {error && <ErrorComponent errorMessage={error} />}
      {promotion && (
        <Container className="card section-card-container">
          <div className="section-container section-container-home">
            <div>
              <div className="country-row">
                <ReactCountryFlag
                  style={{ width: "2em" }}
                  countryCode={`${promotion.countryISO}`}
                  svg
                />{" "}
                <span>{promotion.countryName}</span>
              </div>
              <p className="pr-name">{promotion.name}</p>
              <p className="pr-dec">{promotion.description}</p>
              <div className="wrap-network-pr">
                <div className="network-pr">
                  <p className="pr-net">
                    Network Provider : {promotion.networkProvider}
                  </p>
                  <img
                    src={`${process.env.REACT_APP_DOMAIN_NAME}/images/NetworkProvider.png`}
                    alt="network-provider"
                    className="network-provider"
                  />
                </div>
              </div>
            </div>
            <div className="service-row">
              {promotion.availableProducts &&
                promotion.availableProducts.map(
                  (promotionPackage: IPromotionPackage, index: number) => (
                    <div className="card-services" key={index}>
                      <PackageComponent promotionPackage={promotionPackage} />
                    </div>
                  )
                )}
            </div>
            <div>
              <div className="footer-section">
                <p>Promotion Terms & Conditions**</p>
                <ol>
                  <li>
                    The promotion is valid from June 1 to August 31, 2024.
                  </li>
                  <li>
                    Extra credit is valid for 30 days from the recharge date.
                  </li>
                  <li>Offer applies to Orange network in France only.</li>
                  <li>Non-transferable and non-refundable.</li>
                </ol>
              </div>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}
