import { Col, Container, Row } from "react-bootstrap";
import ButtonComponent from "../../CommonComponents/ButtonComponent";

interface IAddNetworkProvider {
  handleAddNewNetworkProvider: () => void;
}

export const AddNetworkProvider = ({
  handleAddNewNetworkProvider,
}: IAddNetworkProvider) => {
  return (
    <Container className="dashboard-section-container add-user-section-container">
      <Row>
        <Col className="add-admin-user-title">Network Provide</Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col className="add-user-description" sm={10}>
          Quickly review essential details and update the key information for
          network operators, including their descriptions, redeemable methods,
          and terms and conditions here.
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-center justify-content-center">
          <ButtonComponent
            text="Add New Network Provider"
            onClick={handleAddNewNetworkProvider}
            variant="primary"
            customStyling="add-admin-user-btn"
          />
        </Col>
      </Row>
    </Container>
  );
};
