import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ButtonComponent from "../../CommonComponents/ButtonComponent";

interface IAggregatorCard {
  cardImage: string;
  aggregatorName: string;
  status: string;
  handleClickViewAggregator: () => void;
  isSuperAdmin: boolean;
  handleOnStateChange: () => void;
}

export const AggregatorCard = ({
  cardImage,
  aggregatorName,
  status,
  handleClickViewAggregator,
  isSuperAdmin,
  handleOnStateChange,
}: IAggregatorCard) => {
  return (
    <Card className="aggregator-card-container mt-md-3">
      <Card.Body className="aggregator-card-body">
        {isSuperAdmin && (
          <div className="d-flex justify-content-end">
            <Form.Check
              type="switch"
              name="ratingCheckbox"
              // className="aggregator-enable-switch"
              checked={status === "active" ? true : false}
              onChange={handleOnStateChange}
              disabled={false}
            />
          </div>
        )}
        <Card.Img
          variant="top"
          src={cardImage}
          alt={aggregatorName}
          className="aggregator-card-image img-fluid"
        />

        <ButtonComponent
          text="View and Manage"
          onClick={handleClickViewAggregator}
          variant="secondary"
          customStyling="aggregator-card-button"
        />
      </Card.Body>
    </Card>
  );
};
