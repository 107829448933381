import { NavLink } from "react-router-dom";

interface DashboardTabOptionProps {
  option: string;
  isAdmin: boolean;
}

const displayNames: Record<string, string> = {
  "dashboard": "Dashboard",
  "manage-users": "Managing Admin Users",
  "manage-aggregators": "Aggregator Management",
  "manage-content": "Content Management",
  "manage-network-providers": "Network Provider Details",
}

const DashboardTabOption = ({ option, isAdmin }: DashboardTabOptionProps) => {
  const displayName = displayNames[option] || option
    .split("-") // Split by hyphen
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join words with spaces

  return (
    <NavLink
      key={option}
      to={`${option}`}
      className={({ isActive }) => ` left-panel-tab ${isActive ? "active" : ""} :`}
    >
      <div className="dashboard-tab-name-container">
        {isAdmin && <img
          src={process.env.REACT_APP_DOMAIN_NAME + "/images/Icons/Admin/" + option + ".svg"}
          className="dashboard-tab-icon"
          alt={option}
        />}
        {!isAdmin && <img src={process.env.REACT_APP_DOMAIN_NAME + "/images/Icons/CustomerDashboard/" + option + ".svg"} className="dashboard-tab-icon" alt={option} />}
        <span className="tab-name">{displayName}</span>
      </div>
    </NavLink>
  );
};

export default DashboardTabOption;
