import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { parse, stringify } from 'qs';
import { generateRefreshToken } from "../services/AuthService";

const api: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
})

/**
 * Method to add the request headers to the API
 * @param options
 * @param authenticationRequired
 * @returns
 */
const addRequestHeaders = async (options: any, authenticationRequired: boolean) => {
    const token = localStorage.getItem('Token');
    options.headers = {};
    if (authenticationRequired && token) {
        options.headers.Authorization = `Bearer ${token}`;
    }
    return options;
};


/**
 * Makes a GET request to the specified URL and returns the response data.
 * @param url - The URL to make the GET request to.
 * @param params - Optional Axios request configuration.
 * @returns A Promise that resolves to the response data.
 */
export const get = async<TResponse>(url: string, params?: AxiosRequestConfig): Promise<TResponse> => {
    let options = {
        params,
        url,
        method: 'get',
        headers: {}
    };

    api.interceptors.request.use((config) => {
        config.paramsSerializer = {
            encode: (encodeparams) => parse(encodeparams),
            serialize: (serializeparams) => stringify(serializeparams, { arrayFormat: 'repeat' })
        };
        return config;
    });

    options = await addRequestHeaders(options, true);

    if (!options || !options.headers) throw new Error("Unable to fetch token");

    return api.request(options);
};


/**
 * Makes a POST request to the specified URL and with the given data and returns the response data.
 * @param url 
 * @param data 
 * @returns 
 */
export const post = async<TResponse>(url: string, data?: unknown): Promise<TResponse> => {
    let options = {
        data,
        url,
        method: 'post',
        headers: {}
    };

    api.interceptors.request.use((config) => {
        config.paramsSerializer = {
            encode: (encodeparams) => parse(encodeparams),
            serialize: (serializeparams) => stringify(serializeparams, { arrayFormat: 'repeat' })
        };
        return config;
    });

    options = await addRequestHeaders(options, true);

    if (!options || !options.headers) throw new Error("Unable to fetch token");

    return api.request(options);
};


/**
 * Makes a PUT request to the specified URL and with the given data and returns the response data.
 * @param url 
 * @param data 
 * @returns 
 */
export const put = async<TResponse>(url: string, data: unknown): Promise<TResponse> => {
    let options = {
        data,
        url,
        method: 'put',
        headers:{}
    };

    api.interceptors.request.use((config) => {
        config.paramsSerializer = {
            encode: (encodeparams) => parse(encodeparams),
            serialize: (serializeparams) => stringify(serializeparams, { arrayFormat: 'repeat' })
        };
        return config;
    });

    options = await addRequestHeaders(options, true);

    if (!options || !options.headers) throw new Error("Unable to fetch token");
    
    return api.request(options);
}

api.interceptors.response.use(
    (response: AxiosResponse) => {
        return response.data;
    },
    async (error: AxiosError) => {

        //Handlign unauthorized user
        if (error.response?.status == 401) {
            console.log("AXIOS", error.response.status)
            try {
                const original_request = error.config;
                const newToken = await generateRefreshToken();

                //Reruning the failed request again
                if (original_request && newToken) {
                    localStorage.setItem('Token', newToken)
                    original_request.headers.Authorization = `Bearer ${newToken}`;
                    return api.request(original_request)
                }
            } catch (error) {
                throw new Error("Unable to fetch token")
            }
        }

        return Promise.reject(error);
    }
)


