import { isMobile } from "react-device-detect";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/Layout/Header";
import { useState } from "react";
import DashboardTabOption from "../components/Common/DashboardTabOption";

const ADMIN_DASHBOARD_TABS = [
  "dashboard",
  "manage-aggregators",
  "manage-content",
  "manage-users",
];

export default function AdminPage() {
  const routeLocation = useLocation();

  // checking if the user is a admin or a super admin by url param
  const isSuperAdmin = routeLocation.pathname.includes("/super-admin");

  if (
    isSuperAdmin &&
    !ADMIN_DASHBOARD_TABS.includes("manage-network-providers")
  ) {
    ADMIN_DASHBOARD_TABS.push("manage-network-providers");
  }


  const [isHidden, setIsHidden] = useState(false); // Initially visible

  return (
    <>
      <header className={`header ${isHidden ? 'hidden' : ''}`}>
        <Header />
      </header>
      <div className="dashboard-container">

        <div
          className={` ${isMobile ? "left-panel-mobile" : "left-panel-browser"
            } left-panel`}
        >
          {ADMIN_DASHBOARD_TABS.map((option: string) => {
            return <DashboardTabOption option={option} isAdmin={true} />;
          })}
        </div>
        <div className="main-content">
          {/* Rending the child component */}
          <Outlet />
        </div>
      </div>
    </>
  );
}
