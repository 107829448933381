import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { IoMdArrowBack } from "react-icons/io";
import { AggregatorApiEditButton } from "./AggregatorApiEditButton";
import { AggregatorServiceCard } from "./AggregatorServiceCard";
import {
  changeAggregatorServiceStatus,
  getAggregator,
  updateAggregatorApiData,
} from "../../../services/DashboardService";
import { logError } from "../../../utilities/logError";
import {
  IAggregator,
  IAggregatorData,
  IAggregatorServiceInterface,
} from "../../../types/types";
import { formatCurrencyValues } from "../../../utilities/formatCurrencyValues";
import { useLocation } from "react-router-dom";
import { AggregatorStateChangeModal } from "./AggregatorStateChangeModal";
import ButtonComponent from "../../CommonComponents/ButtonComponent";

interface IAggregationForm {
  aggregatorId: string;
  handleBackToAggregators: () => void;
}

const AggregationForm = ({
  aggregatorId,
  handleBackToAggregators,
}: IAggregationForm) => {
  const [aggregatorData, setAggregatorData] = useState<IAggregatorData>();
  const [operationLoading, setOperatingLoading] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [loadingAggregatorData, setLoadingAggregatorData] = useState(false);
  const [disableClientIdField, setDisableClientIdField] = useState(true);
  const [disableClientSecretField, setDisableClientSecretField] =
    useState(true);
  const [stateChangeAggregator, setStateChangeAggregator] = useState<
    any | null
  >(null);
  const routeLocation = useLocation();

  /**
   * useEffect - fetch single aggregator data
   */
  useEffect(() => {
    handleFetchAggregatorData();
  }, []);

  /**
   * Method - trigger aggregator data fetching api call
   */
  const handleFetchAggregatorData = async () => {
    try {
      setLoadingAggregatorData(true);
      const response: any = await getAggregator(aggregatorId);
      setAggregatorData(response?.data);
      setClientId(response?.data?.apiClientId);
      setClientSecret(response?.data?.apiClientSecret);
    } catch (error: any) {
      logError(error.message, "Get aggregator");
    } finally {
      setLoadingAggregatorData(false);
    }
  };

  /**
   * Method - trigger aggregator api key data save api call
   */
  const handleSaveApiKeyChanges = async () => {
    try {
      setOperatingLoading(true);
      const response = await updateAggregatorApiData(clientId, clientSecret);
    } catch (error: any) {
      logError(error?.message, "Save api client id/ client secret changes");
    } finally {
      setOperatingLoading(false);
    }
  };

  /**
   * Method - trigger aggregator enable disable status change api call
   */
  const handleServiceStatusChange = async (
    serviceId: string,
    status: string
  ) => {
    try {
      setOperatingLoading(true);
      const response = await changeAggregatorServiceStatus(serviceId, status);
    } catch (error: any) {
      logError(error?.message, "Update aggregator service status");
    } finally {
      setOperatingLoading(false);
    }
  };

  
  /**
   * Method - reset aggregator api keys to default values
   */
  const handleResetApiKeyChange = () => {
    setClientId(aggregatorData?.apiClientId ?? "");
    setClientSecret(aggregatorData?.apiClientSecret ?? "");
  };

  if (loadingAggregatorData) {
    return <>Loading aggregator data</>;
  }

  // checking if the user is a admin or a super admin by url param
  const isSuperAdmin = routeLocation.pathname.includes(
    "/super-admin/manage-aggregators"
  );

  return (
    <>
      <AggregatorStateChangeModal
        open={stateChangeAggregator !== null}
        aggregatorId={stateChangeAggregator?.id}
        aggregatorStatus={stateChangeAggregator?.status}
        onClose={() => setStateChangeAggregator(null)}
      />

      <Container className="aggregation-form-section-mobile-container">
        <Row>
          <Col className="p-4">
            <IoMdArrowBack
              className="aggregation-form-back-button"
              size={20}
              onClick={handleBackToAggregators}
            />
          </Col>
        </Row>
      </Container>

      <Container className="dashboard-section-container aggregators-section-container">
        <Row>
          <Col>
            <IoMdArrowBack
              className="aggregation-form-back-button"
              size={20}
              onClick={handleBackToAggregators}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col className="d-flex justify-content-start">
            <img
              src={aggregatorData?.logo}
              alt={aggregatorData?.name}
              className="img-fluid aggregation-form-image"
            />
          </Col>
          {isSuperAdmin && (
            <Col className="d-flex justify-content-end">
              <Form.Check
                type="switch"
                name="ratingCheckbox"
                // className="aggregator-enable-switch"
                checked={aggregatorData?.status === "active" ? true : false}
                onChange={() => setStateChangeAggregator(aggregatorData)}
              />
            </Col>
          )}
        </Row>

        <Row>
          <Col sm={12} md={9}>
            <h2 className="aggregator-title">{aggregatorData?.name}</h2>
            <p className="aggregator-form-section-description">
              Manage the API keys, services, and wallet balances for Mobitel.
              Use the sections below to update and view the relevant
              information.
            </p>
          </Col>
        </Row>

        <Row className="aggregator-form-section-container">
          <Col md={12} xl={9}>
            <h3 title="api-keys-title">API Keys</h3>
            <p className="aggregator-form-section-description">
              {` Enter or update the API keys required for ${aggregatorData?.name} to integrate
            with the system.`}
            </p>

            <div className="d-flex align-items-center">
              <div className="api-key-value-container">
                <Form.Label className="edit-api-label">Client ID</Form.Label>

                <div className="d-flex align-items-center gap-1">
                  <Form.Control
                    type="password"
                    value={clientId}
                    onChange={(e) => setClientId(e.target.value)}
                    className="edit-api-input"
                    disabled={disableClientIdField}
                  />

                  <AggregatorApiEditButton
                    handleClickEditButton={() => setDisableClientIdField(false)}
                  />
                </div>
              </div>
              <div></div>
            </div>

            <div className="d-flex align-items-center gap-1 mt-2">
              <div className="api-key-value-container">
                <Form.Label className="edit-api-label">
                  Client Secret
                </Form.Label>

                <div className="d-flex align-items-center">
                  <Form.Control
                    type="password"
                    value={clientSecret}
                    onChange={(e) => setClientSecret(e.target.value)}
                    className="edit-api-input"
                    disabled={disableClientSecretField}
                  />

                  <AggregatorApiEditButton
                    handleClickEditButton={() =>
                      setDisableClientSecretField(false)
                    }
                  />
                </div>
              </div>
            </div>
          </Col>

          <Col className="d-flex justify-content-end mt-2 gap-3 api-key-action-buttons-container">
            <ButtonComponent
              text="Cancel"
              onClick={handleResetApiKeyChange}
              variant="secondary"
              customStyling="edit-api-cancel-button align-horizontal-center align-vertical-center"
              isDisabled={operationLoading}
            />

            <ButtonComponent
              text="Save Changes"
              onClick={handleSaveApiKeyChanges}
              variant="primary"
              customStyling="save-changes-button align-horizontal-center align-vertical-center"
              isDisabled={operationLoading}
            />
          </Col>
        </Row>

        <Row className="aggregator-form-section-container mt-3">
          <Col>
            <h3 title="api-keys-title">Available Services</h3>
            <p className="aggregator-form-section-description">
              View the services offered by Mobitel. Each service can be managed
              individually.
            </p>

            <Row>
              {aggregatorData?.services?.map(
                (service: IAggregatorServiceInterface) => {
                  return (
                    <Col sm={12} md={12} lg={4}>
                      <AggregatorServiceCard
                        status={service?.status}
                        serviceName={service?.serviceName}
                        id={service?.id}
                        handleOnStateChange={handleServiceStatusChange}
                        disabled={operationLoading}
                        isSuperAdmin={isSuperAdmin}
                      />
                    </Col>
                  );
                }
              )}
            </Row>

            {isSuperAdmin && (
              <div className="available-services-disclaimer mt-3">
                Disclaimer: Any services enabled or disabled from this page will
                be reflected on admin pages and will also be visible to users.
                **
              </div>
            )}
          </Col>
        </Row>

        <Row className="aggregator-form-section-container mt-3">
          <Col sm={12}>
            <h3 title="api-keys-title">Wallet Balances</h3>
            <p className="aggregator-form-section-description">
              Check the current wallet balances for Mobitel. Ensure there are
              sufficient funds for transactions.
            </p>
          </Col>

          <Col sm={4}>
            <div className="aggregator-service-card d-flex flex-column align-items-center">
              <div className="aggregator-wallet-balance-currency">USD</div>
              <div className="aggregator-wallet-balance-currency">
                {formatCurrencyValues(
                  "USD",
                  aggregatorData?.walletBallance,
                  ","
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AggregationForm;
