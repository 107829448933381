import { enUS } from "date-fns/locale/en-US";
import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { CiCalendarDate } from "react-icons/ci";

import styles from "../../styles/external-styles";

import "react-datepicker/dist/react-datepicker.css";
import ButtonComponent from "../CommonComponents/ButtonComponent";

registerLocale("enUS", enUS);

export interface DateRangeInterface {
  startDate: Date;
  endDate: Date;
}

interface DateRangePickerProps {
  defaultStartDate: Date;
  defaultEndDate: Date;
  isOpen: boolean;
  onClose: () => void;
  onApply: ({ startDate, endDate }: DateRangeInterface) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  defaultStartDate,
  defaultEndDate,
  isOpen,
  onClose,
  onApply,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);

  /**
   * Method - setting parent component's - (Dashboard) dateRange state
   */
  const handleApply = () => {
    if (startDate && endDate) {
      onApply({ startDate, endDate });
      onClose();
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={styles.title}>Select Date Range</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Row>
            <Col xs={12} lg={6} className="mt-3">
              <Form.Group>
                <Form.Label style={styles.label}>
                  <div className="d-flex gap-1 align-items-center">
                    <CiCalendarDate />
                    From
                  </div>
                </Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control"
                  locale="enUS"
                  dateFormat="dd MMM yyyy"
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={6} className="mt-3">
              <Form.Group>
                <Form.Label style={styles.label}>
                  <div className="d-flex gap-1 align-items-center">
                    <CiCalendarDate />
                    To
                  </div>
                </Form.Label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="form-control"
                  locale="enUS"
                  dateFormat="dd MMM yyyy"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <ButtonComponent
          text="Apply Dates"
          onClick={handleApply}
          variant="primary"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DateRangePicker;
