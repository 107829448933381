//Service available in the system
export enum Services {
    MOBILE = "MOBILE",
    UTILITIES = "UTILITIES",
    BALANCE = "BALANCE",
    GIFT_CARDS = "GIFT_CARDS",
    INSURANCE = "INSURANCE",
    FINANCE = "FINANCE",
    GOVERNMENT = "GOVERNMENT",
    EDUCATION = "EDUCATION",
    ESIM = "ESIM",
}

//Different types of services in the system
export enum ServiceTypes {
    TYPE_1 = "FIXED_VALUE_RECHARGE",
    TYPE_2 = "RANGED_VALUE_RECHARGE",
    TYPE_3 = "FIXED_VALUE_PIN_PURCHASE",
    TYPE_4 = "RANGED_VALUE_PIN_PURCHASE",
    TYPE_5 = "RANGED_VALUE_PAYMENT",
}

//action types for the Google SSO function
export enum ActionTypes {
    LOGIN = "LOGIN",
    SIGNUP = "SIGNUP"
}


//user roles in the system
export enum RoleTypes {
    PLATFORMADMIN = "Platform Admin",
    ADMIN = "Admin"
}