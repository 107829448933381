import { AddNewAdminInterface } from "../types/types";

/**
 * Method - trigger fetch api call to fetch income by currency data from the backend
 * @returns income by currency data payload
 */
export const fetchIncomeByCurrencyData = async (
  startDate: string,
  endDate: string
): Promise<unknown> => {
  try {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve({
          data: {
            incomeByCurrencies: [
              {
                currencyType: "USD",
                profitAmount: 50000,
              },
              // {
              //   currencyType: "EUR",
              //   profitAmount: 40000,
              // },
              // {
              //   currencyType: "GBP",
              //   profitAmount: 65000,
              // },
            ],
          },
        });
      }, 2000)
    );
  } catch (error) {
    return error;
  }
};

/**
 * Method - trigger fetch api call to fetch sales data from the backend
 * @returns sales data payload
 */
export const fetchSalesData = async (
  startDate: string,
  endDate: string
): Promise<unknown> => {
  try {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve({
          data: {
            totalTransactions: 1500,
            topProduct: "Springtime Bonus Boost",
            topProductSales: 500,
            topSalesDestination: "Sri Lanka",
            topDestinationSales: 600,
          },
        });
      }, 2000)
    );
  } catch (error) {
    return error;
  }
};

/**
 * Method - trigger fetch api call to fetch top 5 products data from the backend
 * @returns top 5 products data payload
 */
export const fetchTopProductsData = async (
  startDate: string,
  endDate: string,
  country: string = ""
): Promise<unknown> => {
  try {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve({
          data: {
            topProducts: [
              {
                product: "Super Recharge 100",
                numberOfSales: 500,
              },
              {
                product: "Gift Card 50",
                numberOfSales: 400,
              },
              {
                product: "eSIM Activation",
                numberOfSales: 300,
              },
              {
                product: "Utility Recharge",
                numberOfSales: 200,
              },
              {
                product: "Education Pin",
                numberOfSales: 100,
              },
            ],
          },
        });
      }, 2000)
    );
  } catch (error) {
    return error;
  }
};

/**
 * Method - trigger fetch api call to fetch revenue by provider data from the backend
 * @returns revenue by provider data payload
 */
export const fetchRevenueByProviderData = async (
  startDate: string,
  endDate: string,
  country: string = ""
): Promise<unknown> => {
  try {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve({
          data: {
            revenueByProviders: [
              {
                provider: "Dialog",
                revenue: 2400,
              },
              {
                provider: "Hutch",
                revenue: 2200,
              },
              {
                provider: "Airtel",
                revenue: 2100,
              },
              {
                provider: "Mobitel",
                revenue: 1400,
              },
            ],
          },
        });
      }, 2000)
    );
  } catch (error) {
    return error;
  }
};

/**
 * Method - trigger fetch api call to fetch admin data from the backend
 * @returns admin data payload
 */
export const getAllAdmins = (lastIndex: number): unknown => {
  try {
    return {
      data: {
        count:15,
        results: [
          {
            id: 20,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 21,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 22,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 23,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 24,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 25,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 26,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 26,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 26,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 26,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 26,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 26,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 26,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
          {
            id: 26,
            name: "obert Johnson",
            email: "robert.j@example.com",
          },
        ].slice(lastIndex * 5, lastIndex * 5 + 5),
      },
    };
  } catch (error) {}
};

/**
 * Method - trigger post api call to create a new admin
 * @returns admin data payload
 */
export const addNewAdmin = async ({
  firstName,
  lastName,
  email,
}: AddNewAdminInterface) => {
  try {
    // const response = await api.
  } catch (error) {}
};

/**
 * Method - trigger delete api call to delete an admin
 * @returns admin data payload
 */
export const deleteAdmin = async (adminId: string) => {
  try {
    // const response = await api.
  } catch (error) {}
};

/**
 * Method - trigger fetch api call to fetch aggregators from the backend
 * @returns aggregator data payload
 */
export const getAllAggregators = async (): Promise<unknown> => {
  try {
    return {
      data: [
        {
          id: 1,
          name: "DTone",
          logo: `${process.env.REACT_APP_DOMAIN_NAME}/images/dtone.png`,
          status: "active",
        },
        {
          id: 2,
          name: "Reloadly",
          logo:`${process.env.REACT_APP_DOMAIN_NAME}/images/reloadly.png`,
          status: "active",
        },
      ],
    };
  } catch (error) {}
};

/**
 * Method - trigger fetch api call to fetch aggregator data from the backend
 * @returns aggregator data payload
 */
export const getAggregator = async (aggregatorId: string): Promise<unknown> => {
  try {
    return {
      data: {
        id: "1",
        status: "active",
        name: "Reloadly",
        logo:`${process.env.REACT_APP_DOMAIN_NAME}/images/reloadly.png`,
        apiClientId: "adnffnkjd213kjn",
        apiClientSecret: "nierfeiwnn",
        services: [
          { serviceName: "Mobile Top-Up", id: "1", status: "active" },
          { serviceName: "Gift Cards", id: "1", status: "inactive" },
          { serviceName: "Utilities", id: "1", status: "active" }
        ],
        walletBallance: 1200.0,
      },
    };
  } catch (error) {}
};

/**
 * Method - trigger post api call to update aggregator api key data
 */
export const updateAggregatorApiData = async (
  clientId: string,
  clientSecret: string
) => {
  try {
  } catch (error) {}
};

/**
 * Method - trigger fetch api call to fetch content data from the backend
 * @returns content data payload
 */
export const getContentData = async (): Promise<unknown> => {
  try {
    return {
      data: {
        aboutUs: [
          {
            insert: "A ",
          },
          {
            attributes: {
              bold: true,
            },
            insert: "robot",
          },
          {
            insert:
              " who has developed sentience, and is the only robot of his kind shown to be still ",
          },
          {
            attributes: {
              underline: true,
            },
            insert: "functioning",
          },
          {
            insert: " on Earth.\n",
          },
        ],
        contactUs: {
          email: [
            {
              attributes: {
                underline: true,
              },
              insert: "support@companyname.com",
            },
          ],
          call: [
            {
              attributes: {
                underline: true,
              },
              insert: "+123-456-7890",
            },
          ],
          address: [
            {
              attributes: {
                underline: true,
              },
              insert: "123 Company Street, City, Country",
            },
          ],
        },
        termsAndCondition: [
          {
            attributes: {
              underline: true,
              bold: true,
            },
            insert: "terms and condition",
          },
        ],
        privacyPolicy: [
          {
            attributes: {
              underline: true,
              bold: true,
            },
            insert: "privacy policy",
          },
        ],
      },
    };
  } catch (error) {}
};

/**
 * Method - trigger update aggregator service status api call
 */
export const changeAggregatorServiceStatus = async (
  aggregatorId: string,
  status: string
): Promise<void> => {
  try {
  } catch (error) {}
};

/**
 * Method - trigger update aggregator service status api call
 */
export const saveApiKeyChanges = async (
  aggregatorId: string,
  apiClientId: string,
  clientSecret: string
): Promise<void> => {
  try {
  } catch (error) {}
};

/**
 * Method - trigger insert/update about us content data
 */
export const updateAboutUs = async (content: any[]) => {
  try {
  } catch (error) {}
};

/**
 * Method - trigger insert/update terms and conditions data
 */
export const updateTermsCondition = async (content: any[]) => {
  try {
  } catch (error) {}
};

/**
 * Method - trigger insert/update privacy and policy content data
 */
export const updatePrivacyPolicy = async (content: any[]) => {
  try {
  } catch (error) {}
};

/**
 * Method - trigger insert/update contact us content data
 */
export const updateContactUs = async (
  callContent: any[],
  emailContent: any[],
  addressContent: any[]
) => {
  try {
  } catch (error) {}
};

/* Method - trigger fetch api call to fetch network providers from the backend
 * @returns network providers payload
 */
export const getAllNetworkProviders = async (
  lastIndex: number
): Promise<unknown> => {
  try {
    return {
      data: [
        {
          id: 1,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 2,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 3,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 4,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 5,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 6,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 7,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 8,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 9,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 10,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 11,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 12,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 13,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 14,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 15,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 16,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 17,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 18,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 19,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 20,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 21,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 22,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 23,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 24,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 25,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
        {
          id: 26,
          name: "Global Telecom",
          description: "Leading provider of international mobil...",
          radeemableMethod: "QR code scanned via mobile app",
          tandc: "something",
        },
      ].slice(lastIndex * 5, lastIndex * 5 + 5),
    };
  } catch (error) {}
};

/* Method - trigger fetch api call to fetch network providers based in the searched context
 * @returns network providers payload
 */
export const searchNetworkProvider = async (
  searchContent: string
): Promise<unknown> => {
  console.log(searchContent);

  try {
    return {
      data: {},
    };
  } catch (error) {}
};

/**
 * Method - trigger fetch api call to fetch aggregator data from the backend
 * @returns aggregator data payload
 */
export const getNetworkProvider = async (
  providerId: string
): Promise<unknown> => {
  try {
    return {
      data: {
        name: "Global Mobitel",
        networkProviderDescription: "description",
        redeemableMethod: "mobile top up",
        termsAndConditionsUrl: "https:www.t&c.com//url",
      },
    };
  } catch (error) {}
};

/**
 * Method - trigger post api call to update network provider data
 */
export const updateNetworkProviderData = async (
  name: string,
  networkProviderDescription: string,
  redeemableMethod: string,
  termsAndConditionsUrl: string
) => {
  try {
  } catch (error) {}
};
