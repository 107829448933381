import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getContentData } from "../../../services/DashboardService";
import { IContentData, IDataSaveMethods } from "../../../types/types";
import { logError } from "../../../utilities/logError";
import { EditContentModal } from "./EditContentModal";
import { ManageContentCard } from "./ManageContentCard";
import { EditContactUsModal } from "./EditContactUsModal";
import { ContentCardPlaceHolder } from "./ContentCardPlaceHolder";

export default function ManageContent() {
  const [contentDataModalType, setOpenModalType] = useState<
    keyof IDataSaveMethods | null
  >(null);
  const [openContactUsModal, setOpenContactUsModal] = useState(false);
  const [contentData, setContentData] = useState<IContentData | null>(null);
  const [loadingContent, setLoadingContent] = useState(false);

  /**
   * useEffect - fetch content data after component mounted
   */
  useEffect(() => {
    fetchContentData();
  }, []);

  /**
   * Method - trigger content data fetching api call
   */
  const fetchContentData = async () => {
    try {
      setLoadingContent(true);
      const response: any = await getContentData();
      setContentData(response?.data);
    } catch (error: any) {
      logError(error?.message, "fetching content data");
    } finally {
      setLoadingContent(false);
    }
  };

  const loadingComponent = (
    <Container>
      <Row className="mt-3 gap-3">
        <Col sm={12}>
          <ContentCardPlaceHolder />
        </Col>
        <Col sm={12}>
          <ContentCardPlaceHolder />
        </Col>
        <Col sm={12}>
          <ContentCardPlaceHolder />
        </Col>
      </Row>
    </Container>
  );

  // modal type to be open 
  const modalType: keyof IContentData | null =
    contentDataModalType ?? "aboutUs";

  if (loadingContent) {
    return loadingComponent;
  }

  return (
    <Container>
      {/* about us, terms and condition and privacy policy content edit modal   */}
      {contentDataModalType !== null && (
        <EditContentModal
          defaultValue={contentData?.[modalType] || []}
          type={contentDataModalType}
          onClose={() => setOpenModalType(null)}
        />
      )}

      {/* contact us content edit modal  */}
      {openContactUsModal && (
        <EditContactUsModal
          open={openContactUsModal}
          defaultValue={contentData?.["contactUs"]}
          onClose={() => setOpenContactUsModal(false)}
        />
      )}

      <Row className="content-management-container">
        <ManageContentCard
          sectionTitle="About us"
          onClickEdit={() => setOpenModalType("aboutUs")}
        />
      </Row>

      <Row className="content-management-container mt-3">
        <ManageContentCard
          sectionTitle="Contact us"
          onClickEdit={() => setOpenContactUsModal(true)}
        />
      </Row>

      <Row className="content-management-container mt-3">
        <ManageContentCard
          sectionTitle="Terms and condition"
          onClickEdit={() => setOpenModalType("termsAndCondition")}
        />
      </Row>

      <Row className="content-management-container mt-3">
        <ManageContentCard
          sectionTitle="Privacy policy"
          onClickEdit={() => setOpenModalType("privacyPolicy")}
        />
      </Row>
    </Container>
  );
}
