import React, { useState } from 'react'
import { Button, Container, Form, Row } from 'react-bootstrap'
import BackButton from '../../components/CommonComponents/BackButton'
import Logo from "../../assets/logo.png";
import { pwResetType } from '../../utilities/SchemaTypes';
import { pwResetSchema } from '../../utilities/ValidationSchemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FaArrowRight, FaEye, FaEyeSlash } from 'react-icons/fa';
import ErrorMsgDisplayComponent from '../../components/FormComponents/ErrorMsgDisplayComponent';
import PasswordStrengthComponent from '../../components/UserLogin/PasswordStrengthComponent';


export default function ResetPasswordPage() {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<pwResetType>({
    resolver: zodResolver(pwResetSchema),
  });

  const currentPassword = watch("password");
  
  /**
   * State to handle the show/ hide password
   */
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfPassword, setShowConfPassword] = useState<boolean>(false);
  

  return (
    <Container fluid className="signup-container">
      <div className="page-signup">
        <div className="form-signup">
          <Row>
            <BackButton />
          </Row>
          <h2 className="title-2">Reset password</h2>
          <p>Please choose your new password</p>
          <Form>
            <Form.Group className="form-group form-group-pword" controlId="signupForm.password">
              <Form.Label>Password </Form.Label>
              <Form.Control {...register("password")} type={showPassword ? "text" : "password"} />
              <span className="pword-eye" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
              {errors.password && errors.password.message && (
                <ErrorMsgDisplayComponent message={errors.password.message} />
              )}
              {currentPassword && (
                <PasswordStrengthComponent password={watch("password")} />
              )}
            </Form.Group>
            <Form.Group
              className="form-group form-group-pword "
              controlId="signupForm.confirmPassword"
            >
              <Form.Label>Confirm Password </Form.Label>
              <Form.Control {...register("confirmPassword")} type={showConfPassword ? "text" : "password"} />
              <span className="pword-eye" onClick={() => setShowConfPassword(!showConfPassword)}>
                {showConfPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
              {errors.confirmPassword && errors.confirmPassword.message && (
                <ErrorMsgDisplayComponent
                  message={errors.confirmPassword.message}
                />
              )}
            </Form.Group>


            <Button className="w-100" type="submit" disabled={isSubmitting}>
              Reset Password
              {" "}
              <FaArrowRight />
            </Button>

          </Form>
        </div>
        <div className="signup-banner login-banner">
          <span>
            <img width="150" className="logo" src={Logo} alt="" />
          </span>
          <img
            src={
              process.env.REACT_APP_DOMAIN_NAME + "/images/Signup-banner.jpg"
            }
            alt="signup_banner"
          />
        </div>
      </div>
    </Container>
  )
}
