import { IResourceCreator } from "../types/types";

export const createResource = (promise: Promise<any>): IResourceCreator => {
  let status = "pending";
  let result: any;
  let suspender = promise.then(
    (data: any) => {
      status = "success";
      result = data;
    },
    (error: any) => {
      status = "error";
      result = error;
    }
  );

  return {
    read() {
      if (status === "pending") throw suspender;
      if (status === "error") throw result;
      return result;
    },
  };
};
