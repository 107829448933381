import React, { useState } from 'react'
import { isMobile } from "react-device-detect";
import Header from '../components/Layout/Header';
import { Outlet } from 'react-router-dom';
import DashboardTabOption from '../components/Common/DashboardTabOption';

const CUSTOMER_INFO_TABS = [
  "personal-information",
  "security-settings",
  "payment-methods",
  "transaction-history",
];

export default function CustomerInfoLayout() {
  const [isHidden, setIsHidden] = useState(false); // Initially visible

  return (
    <>
      <header className={`header ${isHidden ? 'hidden' : ''}`}>
        <Header />
      </header>
      <div className="dashboard-container">

        <div
          className={` ${isMobile ? "left-panel-mobile" : "left-panel-browser"
            } left-panel`}
        >
          {CUSTOMER_INFO_TABS.map((option: string) => {
            return <DashboardTabOption option={option} isAdmin={false} />;
          })}
        </div>
        <div className="main-content">
          {/* Rending the child component */}
          <Outlet />
        </div>
      </div>
    </>
  );
}
