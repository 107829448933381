import React from 'react'

interface ErrorComponentProps  {
    errorMessage:string
}

export default function ErrorComponent({errorMessage}:ErrorComponentProps) {
  return (
    <div>{errorMessage}</div>
  )
}
