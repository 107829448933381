import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import styles from "../../../styles/external-styles";

interface IHeaderWithCountrySelectors {
  headerTitle: string;
  onCountryChange: (value: string) => void;
  containerStyles?: string;
  selectElementStyles?: any;
}

export const HeaderWithCountrySelector = ({
  headerTitle,
  onCountryChange,
  containerStyles,
  selectElementStyles,
}: IHeaderWithCountrySelectors) => {
  return (
    <Row>
      <Col
        sm={12}
        md={12}
        lg={6}
        containerStyles="country-selector-header-title"
      >
        {headerTitle}
      </Col>
      <Col
        sm={12}
        md={12}
        lg={6}
        className={`country-selector-container ${
          containerStyles ? containerStyles : ""
        }`}
      >
        <Form.Select
          aria-label="Default select example"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            onCountryChange(e.target.value)
          }
          style={{ ...styles.countrySelector, ...selectElementStyles }}
        >
          <option>Filter by country</option>
          <option value="1">Country 1</option>
          <option value="2">Country 2</option>
          <option value="3">Country 3</option>
        </Form.Select>
      </Col>
    </Row>
  );
};
