import React, { useEffect, useState } from "react";
import HeroBanner from "../../components/Layout/HeroBanner";
import { Col, Container, Row } from "react-bootstrap";
import PromotionComponent from "../../components/PromotionComponents/PromotionComponent";
import ErrorComponent from "../../components/CommonComponents/ErrorComponent";
import Loader from "../../components/CommonComponents/Loader";
import SearchComponent from "../../components/CommonComponents/SearchComponent";
import { getPromotions } from "../../services/AggregatorService";
import { IPromotion } from "../../utilities/ModelTypes";

export default function PromotionsPage() {
  const [promotions, setPromotions] = useState<IPromotion[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  //Get the aggregator promotions
  useEffect(() => {
    const getAggregatorPromotions = async () => {
      try {
        const results = await getPromotions();

        console.log("Results", results);

        if (!results) return;

        setPromotions(results);
      } catch (error) {
        setError("Unable to load the promotions");
      } finally {
        setLoading(false);
      }
    };

    if (promotions == null) getAggregatorPromotions();
  });

  //Search promotions
  const searchPromotions = async (searchQuery: string) => {
    console.log("Search");
  };

  return (
    <div className="page-promo">
      <HeroBanner
        image={
          process.env.REACT_APP_DOMAIN_NAME +
          "/images/Promotions-hero-banner.jpeg "
        }
        title={"Discover Exclusive Mobile Promotions for Your Country"}
        description={
          "Find the best deals on mobile recharges and top-ups. Search, select, and save with our tailored promotions."
        }
      />
      <Container className="card section-card-container">
        <div className="section-container section-container-home">
          <div className="section-container">
            <h2 className="title-2"> Choose Your Perfect Promotion </h2>
            <h4 className="title-4">
              Search by promotion name or provider to find the best mobile deals
              tailored just for you.
            </h4>
            <SearchComponent onSearch={searchPromotions} />
            {loading && <Loader />}
          </div>
          {error && <ErrorComponent errorMessage={error} />}
          <div className="service-row">
            {promotions &&
              promotions.map((promotion: IPromotion, index: number) => (
                <div className="card-services" key={index}>
                  <PromotionComponent promotion={promotion} />
                </div>
              ))}
          </div>
        </div>
      </Container>
    </div>
  );
}
