import React from "react";
import { Col, Container, Row } from "react-bootstrap";

interface DividerTextProps {
  text: string;
}

export default function DividerText({ text }: DividerTextProps) {
  return (
    <div className="divider-text-container">
      <div className="divider-row">
        <div className="divider-line"></div>
        <span className="divider-text">{text}</span>
        <div className="divider-line"></div>
      </div>
    </div>
  );
}
