import React, { useEffect, useState } from 'react'
import HeroBanner from '../../components/Layout/HeroBanner'
import { getAboutUsDetails } from '../../services/EnterpriseService';
import DOMPurify from 'dompurify';
import { Container } from 'react-bootstrap';

export default function AboutUsPage() {
  const [aboutUsData, setAboutUsData] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getAboutUsDetails();

        if (!response) return;

        setAboutUsData(response);
      } catch {
        setError("Unable to obtain enterprise data");
      } finally {
        setLoading(false)
      }
    }

    if (aboutUsData == null) getData();
  })


  return (
    <div>
      <HeroBanner image={process.env.REACT_APP_DOMAIN_NAME + '/images/Promotions-hero-banner.jpeg '} title={'Connecting the World, One Recharge at a Time'} description={'Empowering your connectivity with fast, secure, and reliable mobile services'} />
      {aboutUsData !== null && !loading &&
        <div className="card section-card-container">
          <Container>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(aboutUsData) }} />
          </Container>
        </div> 
      }
    </div>
  )
}
