import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import ModalComponent from "../../Common/ModalComponent";
import { addNewAdmin } from "../../../services/DashboardService";
import ButtonComponent from "../../CommonComponents/ButtonComponent";
// import sendInviteIcon from '/icons/dashboard/send-invite.svg'

interface AddAdminModalProps {
  open: boolean;
  onClose: () => void;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
}

export default function AddAdminModal({ open, onClose }: AddAdminModalProps) {
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here

    try {
      setSubmitting(true);
      const response = await addNewAdmin(formData);
      console.log(response);
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ModalComponent
      title="Add New Admin User"
      open={open}
      onClose={onClose}
      className="admin-user-modal"
    >
      <div className="admin-user-modal-body-container">
        <div className="admin-modal-description">
          Please ensure that all the details are correct before sending the
          invitation. If the email address is incorrect, the new user will not
          receive the invite.
        </div>

        <div className="admin-modal-form-container">
          <Form onSubmit={handleSubmit} className="mt-4">
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <ButtonComponent
              type="submit"
              text="  Send Invite"
              variant="primary"
              customStyling="submit-button"
              src="/icons/dashboard/send-invite.svg"
            />
          </Form>
        </div>
      </div>
    </ModalComponent>
  );
}
