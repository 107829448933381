import React from "react";
import { Form, InputGroup } from "react-bootstrap";

interface IProviderDetailsEditableField {
  value: string | number;
  placeholder: string;
  onChange: (e: any) => void;
  disabled: boolean;
  showEditIcon?: boolean;
}
export const ProviderDetailsEditableField = ({
  value,
  placeholder,
  onChange,
  disabled,
  showEditIcon = true,
}: IProviderDetailsEditableField) => {
  return (
    <InputGroup>
      <Form.Control
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="edit-api-input"
        disabled={disabled}
        placeholder={placeholder}
      />
      {showEditIcon && (
        <InputGroup.Text>
          <img
            src="/icons/dashboard/edit-icon.svg"
            alt="edit-icon"
            className="api-value-edit-icon"
          />
        </InputGroup.Text>
      )}
    </InputGroup>
  );
};
