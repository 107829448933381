import React, { useState } from "react";
import ModalComponent from "../../Common/ModalComponent";
import { Button } from "react-bootstrap";
import { logError } from "../../../utilities/logError";

interface IAggregatorStateChangeModal {
  open: boolean;
  aggregatorId: string | undefined;
  aggregatorStatus: string | undefined;
  onClose: () => void;
}

export const AggregatorStateChangeModal = ({
  open,
  aggregatorId,
  aggregatorStatus,
  onClose,
}: IAggregatorStateChangeModal) => {
  const [operationLoading, setOperationLoading] = useState(false);

  const handleChangeAggregatorStatus = async () => {
    try {
      setOperationLoading(true);
    } catch (error: any) {
      logError(error?.message, "Change aggregator statues");
    } finally {
      setOperationLoading(true);
    }
  };

  return (
    <ModalComponent
      title=""
      open={open}
      onClose={onClose}
      className="aggregator-state-change-confirmation-modal"
    >
      <div className="p-4 pt-0">
        <div className="aggregator-state-change-modal-title">
          Confirm Provider Disable
        </div>

        <div className="aggregator-state-change-modal-description">
          <hr />
          Are you sure you want to disable this provider? This change will be
          reflected in the admin system, and users will not be able to see this
          provider until you enable it again.
        </div>

        <div className="d-flex justify-content-center mt-4 gap-2">
          <button
            onClick={onClose}
            className="edit-api-cancel-button align-horizontal-center align-vertical-center"
            disabled={operationLoading}
          >
            Cancel
          </button>

          <Button
            variant="primary"
            onClick={handleChangeAggregatorStatus}
            className="save-changes-button align-horizontal-center align-vertical-center"
            disabled={operationLoading}
          >
            Confirm
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};
