import React, { useRef, useState } from "react";
import ModalComponent from "../../Common/ModalComponent";
import { Button, Col, Container, Row } from "react-bootstrap";
import RichTextEditor from "../../Common/RichTextEditor";
import { IDataSaveMethods } from "../../../types/types";
import {
  updateAboutUs,
  updatePrivacyPolicy,
  updateTermsCondition,
} from "../../../services/DashboardService";
import { logError } from "../../../utilities/logError";
import ButtonComponent from "../../CommonComponents/ButtonComponent";

interface IEditContentModal {
  type: keyof IDataSaveMethods;
  onClose: () => void;
  defaultValue: any[];
}

export const EditContentModal = ({
  type,
  onClose,
  defaultValue,
}: IEditContentModal) => {
  const [content, setContent] = useState();
  const [submittingData, setSubmittingData] = useState(false);

  const quillRef = useRef<any>();

  /**
   * Method - trigger about us data content add/edit api call
   */
  const handleSaveAboutUs = async () => {
    try {
      setSubmittingData(true);
      const response = await updateAboutUs(quillRef.current.getContents().ops);
    } catch (error: any) {
      logError(error?.message, "submitting about us data");
    } finally {
      setSubmittingData(false);
    }
  };

  /**
   * Method - trigger terms and condition data content add/edit api call
   */
  const handleTermsConditions = async () => {
    try {
      setSubmittingData(true);
      const response = await updateTermsCondition(
        quillRef.current.getContents().ops
      );
    } catch (error: any) {
      logError(error?.message, "submitting terms and condition data");
    } finally {
      setSubmittingData(false);
    }
  };

  /**
   * Method - trigger privacy and policy data content add/edit api call
   */
  const handleSavePrivacyPolicy = async () => {
    try {
      setSubmittingData(true);
      const response = await updatePrivacyPolicy(
        quillRef.current.getContents().ops
      );
    } catch (error: any) {
      logError(error?.message, "submitting privacy policy data");
    } finally {
      setSubmittingData(false);
    }
  };

  /**
   * content data save api call methods mapping
   */
  const dataSaveMethods: IDataSaveMethods = {
    aboutUs: handleSaveAboutUs,
    termsAndCondition: handleTermsConditions,
    privacyPolicy: handleSavePrivacyPolicy,
  };

  /**
   * Method - trigger appropriate data save api call for each type of content
   */
  const handleSaveContentData = async () => {
    const methodName: keyof IDataSaveMethods = type;
    const methodToInvoke = dataSaveMethods[methodName];
    methodToInvoke();
  };

  return (
    <ModalComponent
      open={type !== null}
      onClose={onClose}
      title={""}
      className={""}
      disableCloseButton={submittingData}
    >
      <Container>
        <Row>
          <Col>
            <RichTextEditor
              ref={quillRef}
              defaultValue={defaultValue}
              onTextChange={setContent}
              disabled={submittingData}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="d-flex justify-content-end">
            <ButtonComponent
              text="Save Details"
              onClick={handleSaveContentData}
              variant="primary"
              isDisabled={submittingData}
            />
          </Col>
        </Row>
      </Container>
    </ModalComponent>
  );
};
