import React from 'react'
import { useParams } from 'react-router-dom';
import { PersonalInformation } from '../../components/UserProfileComponents/PersonalInformation';
import { SecurityInformation } from '../../components/UserProfileComponents/SecurityInformation';
import PaymenthMethods from '../../components/UserProfileComponents/PaymenthMethods';
import TransactionHistory from '../../components/UserProfileComponents/TransactionHistory';

export default function InformationManagement() {
    const params = useParams<{ informationType: string }>();

    const managementType = params.informationType;

    return (
        <div>
            {managementType == 'personal-information' && <PersonalInformation />}
            {managementType == 'security-settings' && <SecurityInformation />}
            {managementType == 'payment-methods' && <PaymenthMethods />}
            {managementType == 'transaction-history' && <TransactionHistory />}
        </div>
    )
}
