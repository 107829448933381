import React from 'react'
import { resetPWEmailType } from '../../utilities/SchemaTypes';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { resetPWEmailSchema } from '../../utilities/ValidationSchemas';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/Routes';
import { Form, Modal } from 'react-bootstrap';
import ButtonComponent from '../CommonComponents/ButtonComponent';
import ErrorMsgDisplayComponent from '../FormComponents/ErrorMsgDisplayComponent';
import { resetPassword } from '../../services/AuthService';

interface ForgotPasswordModalProps {
    show: boolean;
    handleClose: (value: boolean) => void;
}

export default function ForgotPasswordModal({ show, handleClose }: ForgotPasswordModalProps) {
    const { register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting } } = useForm<resetPWEmailType>({
            resolver: zodResolver(resetPWEmailSchema)
        });

    const navigate = useNavigate();

    const onSubmit: SubmitHandler<resetPWEmailType> = async (data: resetPWEmailType) => {
        try {
            const response = await resetPassword(data.email)
            console.log("rest", response);
            navigate(ROUTES.RESET_PASSWORD_REQUEST_PAGE_ROUTE)
        } catch (error) {
            const err = error as Error;
            setError("root", { message: err.message });
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => handleClose(false)}
            backdrop="static"
            keyboard={false}
            className='pwd-modal'
        >
            <div className="w-100 d-block text-end">
                {" "}
                <button className="btn btn-modal-close p-0 " onClick={() => handleClose(false)}>
                    <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="36" height="36" rx="18" fill="#E4E4E7" />
                        <path
                            d="M24 13.2086L22.7914 12L18 16.7914L13.2086 12L12 13.2086L16.7914 18L12 22.7914L13.2086 24L18 19.2086L22.7914 24L24 22.7914L19.2086 18L24 13.2086Z"
                            fill="#000"
                        />
                    </svg>
                </button>
            </div>
            <Modal.Body>
                <div className="modal-title">Forgot password</div>
                <hr className='horizontal-line'/>
                <div className="modal-dec"> Enter your Email and we’ll send you a verification code to reset your password </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                        className="form-group"
                        controlId="forgotPasswordForm.email"
                    >
                        <Form.Label className='form-lbl'>Email </Form.Label>
                        <Form.Control
                            {...register("email")}
                            type="text"
                            placeholder="name@example.come"
                        />
                        {errors.email && errors.email.message && (
                            <ErrorMsgDisplayComponent message={errors.email.message} />
                        )}
                    </Form.Group>
                    <ButtonComponent text={'Send Verification Code'} onClick={handleSubmit(onSubmit)} variant={'primary'} isDisabled={false} />
                    {errors.root && <p>{errors.root.message}</p>}
                </Form>
            </Modal.Body>
        </Modal>
    )
}
