/**
 * All the validation schemas of the system will be maintained in this file
 */

import { isValidPhoneNumber } from "react-phone-number-input";
import { z } from "zod";

//Sign up form schema
export const signUpSchema = z
  .object({
    firstName: z.string().min(1, "Please enter the first name"),
    lastName: z.string().min(1, "Please enter the last name"),
    email: z.string().email("Please enter a valid email address"),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long")
      .regex(/[a-z]/, "Password must contain at least one lowercase letter")
      .regex(/[A-Z]/, "Password must contain at least one upper case letter")
      .regex(/[0-9]/, "Password must contain at least one number")
      .regex(
        /[^a-zA-Z0-9]/,
        "Password must contain at least one specal character"
      ),
    confirmPassword: z
      .string()
      .min(8, "Password must be at least 8 characters long")
      .regex(/[a-z]/, "Password must contain at least one lowercase letter")
      .regex(/[A-Z]/, "Password must contain at least one upper case letter")
      .regex(/[0-9]/, "Password must contain at least one number")
      .regex(
        /[^a-zA-Z0-9]/,
        "Password must contain at least one specal character"
      ),
    isMFAEnabled: z.boolean(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });


//Mobile verification schema - Sign up flow and package purchase flow
export const mobileVerificationSchema = z.object({
  mobileNumber: z
    .string()
    .min(1, "Please enter a valid mobile number")
    .refine((value) => !value || isValidPhoneNumber(value), {
      message: "Please enter a valid mobile number",
    }),
});


//Login form schema
export const loginSchema = z.object({
  email: z.string().email("Please enter a valid email address"),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters long")
    .regex(/[a-z]/, "Password must contain at least one lowercase letter")
    .regex(/[A-Z]/, "Password must contain at least one upper case letter")
    .regex(/[0-9]/, "Password must contain at least one number")
    .regex(
      /[^a-zA-Z0-9]/,
      "Password must contain at least one specal character"
    ),
});

//Email to send Reset password schema
export const resetPWEmailSchema = z.object({
  email: z.string().email("Please enter a valid email address")
})


//Reset password schema
export const pwResetSchema = z.object({
  password: z
    .string()
    .min(8, "Password must be at least 8 characters long")
    .regex(/[a-z]/, "Password must contain at least one lowercase letter")
    .regex(/[A-Z]/, "Password must contain at least one upper case letter")
    .regex(/[0-9]/, "Password must contain at least one number")
    .regex(
      /[^a-zA-Z0-9]/,
      "Password must contain at least one specal character"
    ),
  confirmPassword: z
    .string()
    .min(8, "Password must be at least 8 characters long")
    .regex(/[a-z]/, "Password must contain at least one lowercase letter")
    .regex(/[A-Z]/, "Password must contain at least one upper case letter")
    .regex(/[0-9]/, "Password must contain at least one number")
    .regex(
      /[^a-zA-Z0-9]/,
      "Password must contain at least one specal character"
    )
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords do not match",
  path: ["confirmPassword"],
});


//Country selection schema
export const countrySelectionSchema = z.object({
  selectedCountry: z.string().min(1, "Please select a country"),
})


//Custom amount entering form schema
export const packageAmountSchema = z.object({
  amount: z
    .number({ invalid_type_error: "Amount must be a number" })
})




/**
 * Admin section sechmas
 */

//edit personal information form validation schema
export const personalInformationSchema = z.object({
  firstName: z.string().min(1, "Please enter the first name"),
  lastName: z.string().min(1, "Please enter the last name"),
  email: z.string().email("Please enter a valid email address"),
  mobileNumber: z
    .string()
    .min(1, "Please enter a valid mobile number")
    .refine((value) => !value || isValidPhoneNumber(value), {
      message: "Please enter a valid mobile number",
    }),
  multiFactorAuthenticationActivated: z.boolean(),
});

//edit security information form validation schema
export const securityInformationSchema = z
  .object({
    currentPassword: z.string().min(1, "Please enter the current password"),
    newPassword: z.string().min(1, "Please enter the new password"),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords must match",
    path: ["confirmPassword"], // Specifies the field where the error should be displayed
  });
