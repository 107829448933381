import React from "react";
import { usePagination } from "../../hooks/usePagination";
import { Pagination } from "react-bootstrap";

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

export const PaginationComponent = ({ totalItems, itemsPerPage, onPageChange }: PaginationProps) => {

  const { activePage, totalPages, goToPage } = usePagination({ totalItems, itemsPerPage });

  const handlePageChange = (page: number) => {
    goToPage(page);
    onPageChange(page);
  };

  const renderPaginationItems = () => {
    const items = [];

    for (let page = 1; page <= totalPages; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === activePage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    return items;
  };
  return (
    <Pagination>
      <Pagination.First
        disabled={activePage === 1}
        onClick={() => handlePageChange(1)}
        hidden={totalPages == 1} />
      <Pagination.Prev
        disabled={activePage === 1}
        onClick={() => handlePageChange(activePage - 1)}
        hidden={totalPages == 1}
      />
      {renderPaginationItems()}
      <Pagination.Next
        disabled={activePage === totalPages}
        onClick={() => handlePageChange(activePage + 1)}
        hidden={totalPages == 1}
      />
      <Pagination.Last
        disabled={activePage === totalPages}
        onClick={() => handlePageChange(totalPages)}
        hidden={totalPages == 1} />
    </Pagination>
  );
};
